import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Modal, Row, Col } from 'antd';

import CalculationResult from '../../../Calculation/components/CalculationResult/CalculationResult';
import ProfileAddLiquidity from '../ProfileAddLiquidity/ProfileAddLiquidity';
import ProfileSnipingTrade from '../ProfileSnipingTrade/ProfileSnipingTrade';
import './ProfileInfo.less';

const ProfileInfo = props => {
  const { profile, networks } = props;
  const [showMore, setShowMore] = useState(false);

  const history = useHistory();
  const currentNetwork = networks ? networks.find(network => network.chainId === profile.calculation.chainId) : undefined;

  const onShowMore = async () => {
    setShowMore(true)
  }

  const onCloseShowMoreModal = async () => {
    setShowMore(false)
  }

  const onRecalculate = async () => {
    history.push('/calculation?profile=' + profile.uuid);
  }

  return (
    <div className={"profile-main-info"}>
      <Row gutter={8}>
        <Col span={16}>
          <ProfileAddLiquidity
            calculation={profile.calculation}
            currentNetwork={currentNetwork}
          />
        </Col>
        <Col span={8}>
          <ProfileSnipingTrade
            profile={profile}
          />
        </Col>
      </Row>
      <div className={"btn-group"}>
        <Button type={"link"} onClick={onShowMore}>Show more</Button>
        <Button type={"default"} onClick={onRecalculate}>Recalculate</Button>
        <Button type={"primary"} onClick={() => history.push(`/profiles/${profile.uuid}/multiExecutor`)}>Continue</Button>
      </div>
      <Modal
        className={"calculation-result-modal"}
        centered
        visible={showMore}
        footer={false}
        onCancel={onCloseShowMoreModal}
        closable={false}
        width={"1000px"}
      >
        <CalculationResult
          calculation={profile.calculation}
          networks={networks}
        />
      </Modal>
    </div>
  );
};

export default ProfileInfo;