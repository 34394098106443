import React from 'react';
import { Button, Modal, Empty, Space, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import './HashesModal.less';

const HashesModal = ({ lpTransaction, executeTransactions, visible, onCancel }) => {
  return (
    <Modal
      className={"hashes-modal"}
      visible={visible}
      title={<h2>Hashes</h2>}
      width={"800px"}
      onCancel={onCancel}
      footer={false}
    >
      <Space
        align="baseline"
      >
        <h3>Add liquidity transaction</h3>
        <CopyToClipboard
          key="copy"
          text={lpTransaction}
          onCopy={() => message.success("copied")}
        >
          <Button
            disabled={!lpTransaction}
            type={"primary"}
            size={"small"}
          >
            <CopyOutlined/> Copy
          </Button>
        </CopyToClipboard>
      </Space>

      <div className={'hash'}>
        {
          !!lpTransaction
            ? lpTransaction
            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
      </div>
      <Space
        align="baseline"
      >
        <h3>Execute transactions</h3>
        <CopyToClipboard
          key="copy"
          text={JSON.stringify(executeTransactions)}
          onCopy={() => message.success("copied")}
        >
          <Button
            disabled={!executeTransactions || executeTransactions.length === 0}
            type={"primary"}
            size={"small"}
          >
            <CopyOutlined/> Copy
          </Button>
        </CopyToClipboard>
      </Space>
      <div className={'hash'}>
        {
          !!executeTransactions && executeTransactions.length > 0
            ? JSON.stringify(executeTransactions)
            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
      </div>
    </Modal>
  );
};

export default HashesModal;