import React from 'react';
import { useHistory } from "react-router-dom";
import { Form, Input, Button } from 'antd';
import './SignLP.less';

const SignLP = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const onFinish = async (values) => {
    return history.push(`/signLp/calculation/${values.calculationId}`)
  };

  return (
    <div className={"sign-lp"}>
      <h1>Sign LP</h1>
      <Form
        className={"sign-lp-form"}
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="calculationId"
          label="Calculation ID"
          rules={[{ required: true, message: "Calculation ID is required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit">
            Find
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SignLP;