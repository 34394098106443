import React from 'react';
import { Table, Statistic } from 'antd';
import './ProfileWalletTransactionInfo.less';

const { Countdown } = Statistic;

const ProfileWalletTransactionInfo = ({ parsedTransaction, currentNetwork }) => {
  const columns = [
    {
      key: '1',
      dataIndex: '1',
    },
    {
      dataIndex: '2',
      key: '2',
    },
  ];

  let data = []
  if (parsedTransaction) {
    const parsedResult = typeof parsedTransaction === "string" ? JSON.parse(parsedTransaction) : parsedTransaction;
    data = [
      {
        key: 'from',
        "1": "From",
        "2": <><b><a href={`${currentNetwork.explorer}address/${parsedResult.transactionData.from}`} target={"_blank"}>{parsedResult.transactionData.from}</a></b></>
      },
      {
        key: 'method',
        "1": "Method",
        "2": <><b>{parsedResult.contractData.method}</b></>
      },
      {
        key: 'deadline',
        "1": "Deadline",
        "2": <><b><Countdown style={{ fontSize: "10px" }} value={parsedResult.swapData.deadLine * 1000} /></b></>
      },
      {
        key: 'gas-price',
        "1": "Gas price",
        "2": <><b>{parsedResult.transactionData.gasPriceGwei} gwei</b></>
      },
      {
        key: 'limit',
        "1": "Gas limit",
        "2": <><b>{parsedResult.transactionData.gasLimit}</b></>
      },
      {
        key: 'nonce',
        "1": "Nonce",
        "2": <><b>{parsedResult.transactionData.nonce}</b></>
      },
      {
        key: 'qt',
        "1": <b>Quote token</b>,
        colSpan: 2,
      },
      {
        key: 'qt-address',
        "1": "Contract address",
        "2": <><b><a href={`${currentNetwork.explorer}address/${parsedResult.swapData.quoteCurrencyAddress}`} target={"_blank"}>{parsedResult.swapData.quoteCurrencyAddress}</a></b></>
      },
      {
        key: 'qt-amount',
        "1": "Token amount",
        "2": <><b>{+parsedResult.swapData.quoteCurrencyAmount.toFixed(8)}</b></>
      },
      {
        key: 'bt',
        "1": <b>Base token</b>,
        colSpan: 2,
      },
      {
        key: 'bt-address',
        "1": "Contract address",
        "2": <><b><a href={`${currentNetwork.explorer}address/${parsedResult.swapData.baseCurrencyAddress}`} target={"_blank"}>{parsedResult.swapData.baseCurrencyAddress}</a></b></>,
      },
      {
        key: 'bt-amount',
        "1": "Token min amount",
        "2": <><b>{+parsedResult.swapData.baseCurrencyMinAmount.toFixed(8)}</b></>,
      },
    ]
  }

  return (
    <div className={"profile-wallet-tx-info"}>
      <Table className={"profile-wallet-tx-info-table"} pagination={false} showHeader={false} size={"small"} columns={columns} dataSource={data} />
    </div>
  );
};

export default ProfileWalletTransactionInfo;