import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Alert, Modal, Spin, Form, Input, Result, Button, Row, Col, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FileSearchOutlined, CopyOutlined, SaveOutlined } from '@ant-design/icons';
import { getCalculationByIDAsync, saveLpTxAsync, updateLowGasLpTxConfigAsync, validateLpTxAsync } from '../../services/apiRequests';
import { observer } from 'mobx-react';
import { useStores } from '../../store/RootStore';
import './SharedLpTransaction.less';

import CalculationResult from '../Calculation/components/CalculationResult/CalculationResult';
import ProfileLpValidation from '../ProfileCard/components/ProfileLpValidation/ProfileLpValidation';
import ProfileLpTxInfo from '../ProfileCard/components/ProfileLpTxInfo/ProfileLpTxInfo';
import SignLpTxByMetamask from '../SignLpTxByMetamask/SignLpTxByMetamask';

const SharedLpTransaction = observer(() => {
  const { AppStore: { networks } } = useStores();
  const { calculationId, lpTokenReceiver } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [lpTokenReceiverAddress, setLpTokenReceiverAddress] = useState(lpTokenReceiver);
  const [hexTransaction, setHexTransaction] = useState();
  const [validation, setValidation] = useState();
  const [showModal, setShowModal] = useState(false);
  const [calculation, setCalculation] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLowGasTransaction, setIsLowGasTransaction] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(async () => {
    if (!!calculation) {
      return;
    }
    let response = await getCalculationByIDAsync(calculationId);
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
      setCalculation({});
      return;
    }
    return setCalculation(response);
  });

  if (!calculation && !!calculationId) {
    return <div className="profile-card"><Spin className={"loader"} size="large"></Spin></div>
  }

  if (!calculation || !calculation.profileId || Object.keys(calculation).length === 0) {
    return <div className="profile-card">
      <Result
        icon={<FileSearchOutlined />}
        title="404"
        subTitle={<>Sorry, calculation <b>{calculationId}</b> not found</>}
      />
    </div>
  }

  const currentNetwork = networks ? networks.find(network => network.chainId === calculation.chainId) : undefined;
  const currentExchange = !!currentNetwork ? currentNetwork.exchanges.find(exchange => exchange.exchangeId === calculation.exchangeId) : undefined;

  const validate = async (hexTransaction) => {
    let validation = await validateLpTxAsync(calculation.profileId, { hexTransaction });
    return setValidation(validation);
  }

  const onSave = async () => {
    setLoading(true);

    if (isLowGasTransaction) {
      const result = await updateLowGasLpTxConfigAsync(calculation.profileId, { hexTransaction });
      if (result && result.hasOwnProperty('parsedResult')) {
        message.success("Transaction saved successfully");
      } else {
        message.error("ERROR: Transaction not saved");
      }
    } else {
      const result = await saveLpTxAsync(calculation.profileId, { hexTransaction });
      if (result && result.hasOwnProperty('parsedResult')) {
        message.success("Transaction saved successfully");
      } else {
        message.error("ERROR: Transaction not saved");
      }
    }

    setShowModal(false);
    setLoading(false)
  }

  const onInputChange = ({ target: { value } }) => {
    setLpTokenReceiverAddress(value);
  }

  const onSaveLpTransaction = async (values) => {
    setIsLowGasTransaction(false);
    if (values.hexTransaction) {
      setHexTransaction(values.hexTransaction);
      await validate(values.hexTransaction)
      setShowModal(true);
      return;
    } if (!!values.lowGasHexTransaction) {
      setIsLowGasTransaction(true);
      setHexTransaction(values.lowGasHexTransaction);
      await validate(values.lowGasHexTransaction)
      setShowModal(true);
      return;
    }
  }

  return (
    <div className="profile-shared-lp-transaction">
      <h1>Sign LP</h1>
      {
        !!errorMessage &&
        <><Alert message={errorMessage} type="error" showIcon /><br /></>
      }
      {
        !!successMessage &&
        <><Alert message={successMessage} type="success" /><br /></>
      }

      <CalculationResult
        calculation={calculation}
        networks={networks}
        hideSniperTxs={true}
      />
      <div>
        <Form
          form={form}
          layout="vertical"
        >
          <div style={{ textAlign: "center" }}>LP token receiver</div>
          <Form.Item
            name="lpTokenReceiver"
            initialValue={lpTokenReceiverAddress}
          >
            <Input
              onChange={onInputChange}
            />
          </Form.Item>
          <SignLpTxByMetamask
            calculation={calculation}
            lowGasLpTransaction={calculation.lpTransactionWithLowGas}
            currentNetwork={currentNetwork}
            currentExchange={currentExchange}
            lpTokenReceiver={lpTokenReceiverAddress}
            onSaveLpTransaction={onSaveLpTransaction}
            customRouter={calculation.customRouter}
          />
        </Form>
        <Modal
          className={"lp-modal"}
          style={{ top: 20 }}
          visible={showModal}
          width={"900px"}
          onCancel={() => setShowModal(false)}
          footer={[
            <Button
              key="back"
              onClick={() => setShowModal(false)}
              loading={loading}
            >
              Cancel
            </Button>,
            <CopyToClipboard key="copy" text={hexTransaction} onCopy={() => message.success("copied")}>
              <Button
                type="primary"
                loading={loading}
              >
                <CopyOutlined /> Copy HEX data
              </Button>
            </CopyToClipboard>,
            <Button
              key="save"
              type="primary"
              loading={loading}
              onClick={onSave}
            >
              <SaveOutlined /> Save to profile
            </Button>,
          ]}
        >
          <Row>
            <Col span={!!isLowGasTransaction ? 24 : 16}>
              {
                !!isLowGasTransaction
                  ? <h3>LP transaction <b>WITH LOW GAS PRICE</b></h3>
                  : <h3>LP transaction</h3>
              }
              <ProfileLpTxInfo
                parsedTransaction={validation && validation.info ? validation.info.parsedResult : null}
                currentNetwork={currentNetwork}
              />
              <h3>HEX data</h3>
              <p style={{ fontSize: "10px" }}>{hexTransaction}</p>
            </Col>
            {
              !isLowGasTransaction &&
              <Col span={8}>
                <ProfileLpValidation
                  validation={validation}
                />
              </Col>
            }
          </Row>
        </Modal>
      </div>
    </div>
  );
});

export default SharedLpTransaction;