import React, { useEffect } from 'react';
import { Result, Spin, Layout } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { Switch, Route, Redirect } from "react-router-dom";
import { FrownOutlined } from '@ant-design/icons';
import { getNetworksAsync } from "./services/apiRequests";
import { observer } from 'mobx-react';
import { useStores } from './store/RootStore';
import "antd/dist/antd.css";
import './App.less';

import NavBar from './components/NavBar/NavBar';
import Calculation from './components/Calculation/Calculation';
import ProfileList from './components/ProfileList/ProfileList';
import ProfileCard from './components/ProfileCard/ProfileCard';
import SignLP from './components/SignLP/SignLP';
import SharedLpTransaction from './components/SharedLpTransaction/SharedLpTransaction';
import SharedOpenTradingTransaction from './components/SharedOpenTradingTransaction/SharedOpenTradingTransaction';

const { Header, Footer, Content } = Layout;

const App = observer(() => {
  const { AppStore, AuthStore } = useStores();
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(async () => {
    let networks = await getNetworksAsync();
    if (!!networks && !networks.errorMessage) {
      AppStore.setNetworks(networks);
    } else {
      AppStore.setNetworks([]);
    }

    return () => {
      AppStore.setNetworks(null);
    }
  }, []);

  useEffect(async () => {
    if (!isLoading) {
      if (isAuthenticated) {
        let accessToken = await getAccessTokenSilently();
        if (accessToken) {
          AuthStore.setAccessToken(accessToken);
          return;
        }
      }

      AuthStore.setAccessToken(false);
    }

    return () => {
      AuthStore.setAccessToken(null);
    }
  });

  if (isLoading || AppStore.networks === null || AuthStore.accessToken === null) {
    return <Spin className={"main-loader"} size="large"></Spin>
  }

  return (
    <div className="App">
      <Layout>
        <Header className={"header"}>
          <NavBar />
        </Header>
        <Content className={"main-content"}>
          {
            !AppStore.networks || AppStore.networks.length === 0
              ? <Result
                icon={<FrownOutlined />}
                title="500"
                subTitle={"Service currently unavailable"}
              />
              : <Switch>
                <Route path="/calculation">
                  <h1>Calculation</h1>
                  <Calculation />
                </Route>
                <Route path="/signLp/calculation/:calculationId/openTrading">
                  <SharedOpenTradingTransaction />
                </Route>
                <Route path="/signLp/calculation/:calculationId/receiver/:lpTokenReceiver">
                  <SharedLpTransaction />
                </Route>
                <Route path="/signLp/calculation/:calculationId">
                  <SharedLpTransaction />
                </Route>
                <Route path="/signLp">
                  <SignLP />
                </Route>
                <Route path="/profiles/:profileId">
                  <ProfileCard />
                </Route>
                <Route path="/profiles">
                  <h1>Profiles</h1>
                  <ProfileList />
                </Route>
                <Route path="/">
                  <Redirect to="/calculation" />
                </Route>
              </Switch>
          }
        </Content>
        <Footer className={"footer"}>
          <div>Fair Launch ©{new Date().getFullYear()}<br />Designed by Peanut Team</div>
        </Footer>
      </Layout>
    </div>
  );
})

export default App;
