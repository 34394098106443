import React from 'react';
import { Table } from 'antd';
import './PriceChangeSimulation.less';

const columns = [
  {
    key: '1',
    dataIndex: '1',
  },
  {
    dataIndex: '2',
    key: '2',
  },
];

const PriceChangeSimulation = ({ parsedLogs }) => {

  if (!parsedLogs || !parsedLogs.length || !parsedLogs.every((log) => log.isOk === true)) {
    return null
  }

  const data = [
    {
      key: "1",
      "1": 'Add liquidity token price, $',
      "2": +parsedLogs[0].syncLog.basePriceInUsd.toFixed(8)
    },
    {
      key: "2",
      "1": 'Add liquidity token price, QT',
      "2": +parsedLogs[0].syncLog.baseToQuotePrice.toFixed(8)
    },
    {
      key: "3",
      "1": 'Token price after sniping, $',
      "2": +parsedLogs[parsedLogs.length - 1].syncLog.basePriceInUsd.toFixed(8)
    },
    {
      key: "4",
      "1": 'Token price after sniping, QT',
      "2": +parsedLogs[parsedLogs.length - 1].syncLog.baseToQuotePrice.toFixed(8)
    },
    {
      key: "5",
      "1": 'Price change',
      "2": Number((parsedLogs[parsedLogs.length - 1].syncLog.basePriceInUsd / parsedLogs[0].syncLog.basePriceInUsd).toFixed(6)) + "x"
    }
  ]

  return (
    <div className={"price-change-simulation"}>
      <h3>Price change simulation</h3>
      <Table
        className={"price-change-simulation-table"}
        pagination={false}
        showHeader={false}
        size={"small"}
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};

export default PriceChangeSimulation;