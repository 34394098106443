/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Row, Col, message } from 'antd';
import { useHistory } from "react-router-dom";
import { SyncOutlined } from '@ant-design/icons';
import { getOpTrTxAsync, validateOpTrTxAsync, saveOpTrTxAsync } from "../../../../services/apiRequests";
import './ProfileOpenTrading.less';

import ProfileOpenTradingTxInfo from "../ProfileOpenTradingTxInfo/ProfileOpenTradingTxInfo";
import ProfileOpenTradingValidation from "../ProfileOpenTradingValidation/ProfileOpenTradingValidation";
import SignOpenTraidingTxByMetamask from '../../../SignOpenTraidingTxByMetamask/SignOpenTraidingTxByMetamask';

const ProfileOpenTrading = ({ accessToken, profile, networks, loading, setLoading, updateProfile }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [lpTransaction, setLpTransaction] = useState();
  const [lpValidation, setLpValidation] = useState();

  useEffect(async () => {
    if (loading || !profile.lpTransaction || !!lpTransaction || lpTransaction === false || !accessToken) {
      return;
    }
    await update();
    return;
  });

  const update = async () => {
    setLoading(true);
    await updateProfile();
    await Promise.all(
      [
        updateLpTransaction(),
        updateLpValidation(profile.lpTransaction)
      ]
    )
    setLoading(false);
  }

  const updateLpTransaction = async () => {
    let response = await getOpTrTxAsync(accessToken || null, profile.uuid);
    if (response.errorMessage) {
      setLpTransaction(false);
      return;
    }
    return setLpTransaction(response);
  }

  const updateLpValidation = async (lpTransaction) => {
    let response = await validateOpTrTxAsync(profile.uuid, lpTransaction);
    if (response.errorMessage) {
      setLpValidation(false);
      return;
    }
    return setLpValidation(response);
  }

  const onSaveLpTransaction = async (values) => {
    setLoading(true);
    if (!!values.hexTransaction) {
      let response = await saveOpTrTxAsync(profile.uuid, values);
      if (response.errorMessage) {
        message.error(response.errorMessage, 5);
        setLoading(false);
        return;
      }
      
      message.success("Transaction saved successfully", 5);
      setLpTransaction(response);
      await updateLpValidation(values);
    }

    await updateProfile();

    setLoading(false);
    return
  }

  const currentNetwork = networks ? networks.find(network => network.chainId === profile.calculation.chainId) : undefined;
  const currentExchange = !!currentNetwork ? currentNetwork.exchanges.find(exchange => exchange.exchangeId === profile.calculation.exchangeId) : undefined;

  return (
    <div className={"profile-lp-transaction"}>
      <Row gutter={8}>
        <Col span={18}>
          <h3>Open Traiding transaction</h3>
          <ProfileOpenTradingTxInfo
            parsedTransaction={!!profile.lpTransaction && !!profile.lpTransaction.parsedResult ? profile.lpTransaction.parsedResult : null}
            currentNetwork={currentNetwork}
          />
          <h3>Add/Update transaction (HEX or sign by MetaMask)</h3>
          <Row gutter={8}>
            <Col span={12}>
              <div className={"add-lp-transaction"}>
                <Form
                  form={form}
                  layout="vertical"
                  autoComplete="off"
                  onFinish={onSaveLpTransaction}
                >
                  <Form.Item
                    name="hexTransaction"
                    label="Signed transaction (hex)"
                  >
                    <Input placeholder="0x..." />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" loading={loading} disabled={loading} htmlType="submit">{!!profile.lpTransaction ? "Update" : "Add"}</Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>

            <Col span={12}>
              <div className={"add-lp-transaction"}>
                <SignOpenTraidingTxByMetamask
                  calculation={profile.calculation}
                  currentNetwork={currentNetwork}
                  currentExchange={currentExchange}
                  onSaveLpTransaction={onSaveLpTransaction}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <ProfileOpenTradingValidation
            validation={lpValidation}
          />
        </Col>
      </Row>
      <div className={"btn-group"}>
        <a className={'ant-btn ant-btn-link'} target="_blank" href={`/signLp/calculation/${profile.calculation.uuid}/openTrading`} rel="noreferrer">Share</a>
        <Button type={"default"} onClick={() => history.push(`/profiles/${profile.uuid}/multiExecutor`)}>Back</Button>
        <Button type={"default"} onClick={() => update()}><SyncOutlined spin={loading} />Validate</Button>
        <Button type={"primary"} onClick={() => history.push(`/profiles/${profile.uuid}/run`)}>Continue</Button>
      </div>
    </div>
  );
};

export default ProfileOpenTrading;