import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Alert, Collapse, Space, Form, Input, InputNumber, Button, Select, Row, Col, Spin, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { getProfileAsync, calculationAsync, updateProfileAsync } from '../../services/apiRequests';
import { observer } from 'mobx-react';
import { useStores } from '../../store/RootStore';
import CalculationResult from './components/CalculationResult/CalculationResult';
import CreateProfileModal from './components/CreateProfileModal/CreateProfileModal';
import './Calculation.less';

const { Option } = Select;
const { Panel } = Collapse;
const { Title } = Typography;

const Calculation = observer(() => {
  const {
    AppStore: { networks },
    AuthStore: { accessToken }
  } = useStores();

  const history = useHistory();
  const search = useLocation().search;
  const profileId = new URLSearchParams(search).get('profile');

  const { isAuthenticated } = useAuth0();
  const [profile, setProfile] = useState();
  const [calculation, setCalculation] = useState({});
  const [currentNetwork, setCurrentNetwork] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [collapseActiveKey, setCollapseActiveKey] = useState(1);
  const [tradeVolume, setTradeVolume] = useState(0);
  const [activeCreateProfileModal, setActiveCreateProfileModal] = useState(false);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [mode, setMode] = useState();

  useEffect(async () => {
    if (!profileId || !!profile || !accessToken) {
      return;
    }
    let response = await getProfileAsync(accessToken, profileId);
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
      return setProfile();
    }
    setProfile(response);
    setCalculation(response.calculation);

    let networkInfo = networks.find(network => network.chainId === Number(response.calculation.chainId));
    if (!networkInfo) {
      setErrorMessage("Network not found");
      return false;
    }

    setCurrentNetwork(networkInfo);
    let initialTrade = response.calculation.trades.find(t => t.type === "initial");
    let sniperTrade = response.calculation.trades.find(t => t.type === "sniper");

    form.setFieldsValue({
      chainId: response.calculation.chainId,
      exchangeId: response.calculation.exchangeId,
      baseTokenAddress: response.calculation.baseToken,
      baseTokenName: response.calculation.baseTokenName,
      baseTokenDecimals: response.calculation.baseTokenDecimals,
      baseTokenBuyFeePercent: response.calculation.baseTokenBuyFeePercent || 0,
      tokenListingPriceInUsd: response.calculation.inputTokenListingPriceInUsd,
      quoteTokenAddress: response.calculation.quoteToken,
      quoteTokenUsdPrice: response.calculation.quoteTokenPriceInUsd,
      liquidityAddGasPrice: response.calculation.liquidityAddGasPrice,
      totalLiquidityVolumeInUsd: response.calculation.inputTotalLiquidityVolumeInUsd,
      mode: response.calculation.mode,
      initialTradeVolumeInUsd: !!initialTrade ? initialTrade.quoteTokenAmountFromInUSD : 0,
      executorCount: response.calculation.executorCount,
      aheadExecutorCount: response.calculation.aheadExecutorCount,
      secondaryBroadcastLatencyMs: response.calculation.secondaryBroadcastLatencyMs,
      sniperVolumeInUSD: !!sniperTrade ? sniperTrade.quoteTokenAmountFromInUSD : null,
      trades: response.calculation.trades.map(trade => {
        return {
          tradeVolume: trade.quoteTokenAmountFromInUSD || 0
        }
      })
    });

    setTradeVolume(response.calculation.trades.reduce((acc, trade) => { acc += (trade.quoteTokenAmountFromInUSD || 0); return acc; }, 0))
    setMode(response.calculation.mode);
    setCollapseActiveKey(2);
    return;
  });

  const onValuesChange = (values, allFields) => {
    if (values.hasOwnProperty('chainId')) {
      let networkInfo = networks.find(network => network.chainId === Number(values.chainId));
      if (!networkInfo) {
        setErrorMessage("Network not found");
        return false;
      }

      setCurrentNetwork(networkInfo);
      let defaultMode = Object.keys(networkInfo.modes)[0];

      form.setFieldsValue({
        exchangeId: networkInfo.exchanges.length === 1 ? networkInfo.exchanges[0].exchangeId : null,
        liquidityAddGasPrice: networkInfo.initialLiquidityAddGasPrice,
      });

      updateMode(defaultMode, networkInfo)
    }

    if (values.hasOwnProperty('mode')) {
      updateMode(values.mode, currentNetwork)
    }

    if (values.hasOwnProperty('trades')) {
      setTradeVolume(allFields.trades.reduce((acc, trade) => { acc += !!trade ? trade.tradeVolume : 0; return acc; }, 0))
    }
  };

  const updateMode = (modeId, network) => {
    setMode(modeId);
    form.setFieldsValue({
      mode: modeId
    });

    if (modeId === "multiExecutor") {
      form.setFieldsValue({
        initialTradeVolumeInUsd: network.modes[modeId].initialTradeVolumeInUsd,
        executorCount: network.modes[modeId].count,
        aheadExecutorCount: network.modes[modeId].aheadAccountsBroadcastCount,
        secondaryBroadcastLatencyMs: network.modes[modeId].secondaryBroadcastLatencyMs
      });
    } else {
      form.setFieldsValue({
        initialTradeVolumeInUsd: null,
        executorCount: null,
        aheadExecutorCount: null,
        secondaryBroadcastLatencyMs: null
      });
    }
  }

  const onFinish = async (values) => {
    setLoading(true);
    setErrorMessage(null);
    setCalculation({})
    let calculationResult = await calculationAsync(values);
    if (calculationResult.errorMessage) {
      setLoading(false);
      return setErrorMessage(calculationResult.errorMessage);
    }
    setCalculation(calculationResult);
    setLoading(false);
    return setCollapseActiveKey(2);
  };

  const onCollapse = (key) => {
    if (!!key) {
      setCollapseActiveKey(key);
    }
  }

  const onBackToProfile = () => {
    history.push('/profiles/' + profile.uuid);
  }

  const onProfileUpdate = () => {
    setErrorMessage(null);
    try {
      if (calculation.uuid === profile.calculation.uuid) {
        return history.push('/profiles/' + profile.uuid);
      }

      let update = updateProfileAsync(accessToken, profileId, {
        calculationId: calculation.uuid
      });

      if (!update) {
        return setErrorMessage("Internal server error")
      }
      if (update.errorMessage) {
        return setErrorMessage(update.errorMessage)
      }

      return history.push('/profiles/' + profile.uuid);
    } catch (e) {
      return setErrorMessage(e.errorMessage)
    }
  }

  const onProfileCreate = () => {
    setActiveCreateProfileModal(true);
  }

  const onCloseProfileCreate = () => {
    setActiveCreateProfileModal(false);
  }

  return (
    <div className={"calculation"}>
      {
        !!errorMessage &&
        <Alert message={errorMessage} type="error" showIcon />
      }
      <Space direction="vertical">
        <Collapse accordion activeKey={collapseActiveKey} onChange={onCollapse}>
          <Panel header={"Form"} key="1">
            <Spin style={{ display: "none" }} spinning={networks.length == 0}>
              <Form
                className={"calculation-form"}
                form={form}
                layout="vertical"
                onValuesChange={onValuesChange}
                onFinish={onFinish}
              >
                <Row gutter={8}>
                  <Col span={9}>
                    <Form.Item
                      name="chainId"
                      label="Network"
                      rules={[{ required: true, message: "required" }]}
                    >
                      <Select
                        placeholder={"Select network"}
                        disabled={!!profile || networks.length === 0}
                      >
                        {
                          networks.map(network => <Option key={"network-" + network.chainId} value={network.chainId}>{network.networkName}</Option>)
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item
                      name="exchangeId"
                      label="Exchange"
                      rules={[{ required: true, message: "required" }]}
                    >
                      <Select
                        placeholder={"Select exchange"}
                        disabled={!!profile || !currentNetwork.exchanges || currentNetwork.exchanges.length === 0}
                      >
                        {
                          !!currentNetwork.exchanges &&
                          currentNetwork.exchanges.map(exchange => <Option key={"exchange-" + exchange.exchangeId} value={exchange.exchangeId}>{exchange.exchangeName}</Option>)
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="mode"
                      label="Mode"
                      rules={[{ required: true, message: "required" }]}
                    >
                      <Select
                        placeholder={"Select mode"}
                        disabled={!!profile || !currentNetwork.exchanges || currentNetwork.exchanges.length === 0}
                      >
                        {
                          !!currentNetwork.modes &&
                          Object.keys(currentNetwork.modes).map(modeId => <Option key={"mode-" + modeId} value={modeId}>{currentNetwork.modes[modeId].name}</Option>)
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item
                      name="baseTokenAddress"
                      label="Base token contract address (Custom token)"
                      rules={[{ required: true, message: "required" }]}
                    >
                      <Input placeholder="0x0000000000000000000000000000000000000000" />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item
                      name="baseTokenName"
                      label="Symbol"
                      rules={[{ required: true, message: "required" }]}
                    >
                      <Input placeholder="ABC" />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item
                      name="baseTokenDecimals"
                      label="Decimals"
                      rules={[{ required: true, message: "required" }]}
                    >
                      <InputNumber placeholder="18" />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item
                      name="baseTokenBuyFeePercent"
                      label="Buy Fee%"
                      rules={[{ required: true, message: "required" }]}
                    >
                      <InputNumber placeholder="0" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="tokenListingPriceInUsd"
                      label="Token price"
                      rules={[
                        { required: true, message: "required" }
                      ]}
                    >
                      <InputNumber addonAfter={"$"} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={18}>
                    <Form.Item
                      name="quoteTokenAddress"
                      label="Quote token contract address (WBNB, WETH, ...)"
                      rules={[{ required: true, message: "required" }]}
                    >
                      <Input placeholder="0x0000000000000000000000000000000000000000" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="quoteTokenUsdPrice"
                      label="Token price"
                      rules={[
                        { required: true, message: "required" }
                      ]}
                    >
                      <InputNumber addonAfter={"$"} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={6}>
                    <Form.Item
                      name="totalLiquidityVolumeInUsd"
                      label="Total liquidity volume"
                      rules={[
                        { required: true, message: "required" }
                      ]}
                    >
                      <InputNumber addonAfter={"$"} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="liquidityAddGasPrice"
                      label="Liquidity add gas price"
                      rules={[
                        { required: true, message: "required" }
                      ]}
                    >
                      <InputNumber addonAfter={"gwei"} />
                    </Form.Item>
                  </Col>
                </Row>
                {
                  !!currentNetwork.chainId &&
                  <>
                    {
                      mode === "multiExecutor" &&
                      <>
                        {
                          currentNetwork.broadcastType !== "mev" &&
                          <Title level={5}>MultiExecutor config</Title>
                        }
                        <Row gutter={8}>
                          <Col span={6}>
                            <Form.Item
                              name="executorCount"
                              label="Number of executors"
                              rules={[
                                { required: true, message: "required" }
                              ]}
                              hidden={currentNetwork.broadcastType === "mev"}
                            >
                              <InputNumber />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name="aheadExecutorCount"
                              label="Transactions ahead"
                              rules={[
                                { required: true, message: "required" }
                              ]}
                              hidden={currentNetwork.broadcastType === "mev"}
                            >
                              <InputNumber />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name="secondaryBroadcastLatencyMs"
                              label="Delay between transactions"
                              rules={[
                                { required: true, message: "required" }
                              ]}
                              hidden={currentNetwork.broadcastType === "mev"}
                            >
                              <InputNumber addonAfter={"ms"} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Title level={5}>Trades</Title>
                        <Row gutter={8}>
                          <Col span={6}>
                            <Form.Item
                              name="initialTradeVolumeInUsd"
                              label="Initial"
                              rules={[
                                { required: true, message: "required" }
                              ]}
                            >
                              <InputNumber addonAfter={"$"} />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name="sniperVolumeInUSD"
                              label="Sniper"
                              rules={[
                                { required: true, message: "required" }
                              ]}
                            >
                              <InputNumber addonAfter={"$"} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    }
                    {
                      mode === "multipleWallets" &&
                      <>
                        <Title level={5}>Trades <i style={{fontSize: '10px'}}>(max: {currentNetwork.modes[mode].maxTrades})</i></Title>
                        <Form.List
                          name="trades"
                          initialValue={[undefined]}
                        >
                          {(fields, { add, remove }) => (
                            <Row gutter={8}>
                              {fields.map(field => (
                                <Col key={"field-" + field.name + "-" + field.key} span={6} style={{ position: "relative" }}>
                                  <Form.Item
                                    key={"input-" + field.name + "-" + field.key}
                                    {...field}
                                    name={[field.name, 'tradeVolume']}
                                    rules={[
                                      { required: true, message: 'Missing trade volume' },
                                    ]}
                                    validateTrigger="onBlur"
                                  >
                                    <InputNumber min={1} addonBefore={"Wallet " + (field.name + 1)} addonAfter={"$"} />
                                  </Form.Item>
                                  {
                                    fields.length > 1 &&
                                    <MinusCircleOutlined
                                      key={"remove-field-" + field.name + "-" + field.key}
                                      className={"remove-trade"}
                                      onClick={() => remove(field.name)}
                                    />
                                  }
                                </Col>
                              ))}
                              {
                                fields.length < currentNetwork.modes[mode].maxTrades &&
                                <Col key={"add-trade"} span={6}>
                                  <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                      Add
                                    </Button>
                                  </Form.Item>
                                </Col>
                              }
                            </Row>
                          )}
                        </Form.List>
                        <Form.Item>
                          <b>Total trade volume: ${tradeVolume}</b>
                        </Form.Item>
                      </>
                    }
                  </>
                }
                <Form.Item style={{ textAlign: "center" }}>
                  <Button loading={loading} disabled={loading} type="primary" htmlType="submit">
                    Calculate
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </Panel>
          <Panel header="Result" key="2" collapsible={Object.keys(calculation).length == 0 ? "disabled" : "header"}>
            {
              Object.keys(calculation).length > 0 &&
              <CalculationResult calculation={calculation} networks={networks} />
            }
            <div className={"create-profile-btn-group"}>
              <Button className={"back-btn"} onClick={() => setCollapseActiveKey(1)}>
                {!!profile ? "Edit" : "Back"}
              </Button>
              {
                isAuthenticated &&
                !!profile &&
                <Button className={"create-profile-btn"} onClick={onBackToProfile}>Back to profile</Button>
              }
              {
                isAuthenticated &&
                !profile &&
                <Button className={"create-profile-btn"} type="primary" onClick={onProfileCreate}>Create profile</Button>
              }
              {
                isAuthenticated &&
                !!profile &&
                calculation.uuid !== profile.calculation.uuid &&
                <Button className={"create-profile-btn"} type="primary" onClick={onProfileUpdate}>Update profile</Button>
              }
            </div>
          </Panel>
        </Collapse>
      </Space>
      <CreateProfileModal
        accessToken={accessToken}
        visible={activeCreateProfileModal}
        mode={calculation.mode}
        exchangeId={calculation.exchangeId}
        calculationId={calculation.uuid || null}
        onCancel={onCloseProfileCreate}
      />
    </div >
  );
});

export default Calculation;