import React from 'react';
import { Tabs } from 'antd';
import { useHistory, useLocation } from "react-router-dom";
import './ProfileTabs.less';

const { TabPane } = Tabs;

const ProfileTabs = ({ profileId, mode, exchangeId }) => {
  const history = useHistory();
  const location = useLocation();

  const tabs = [
    {
      title: "Info",
      path: ""
    },
    {
      title: "Executors",
      path: mode === "multipleWallets"
        ? "multipleWallets"
        : "multiExecutor"
    },
    {
      title: exchangeId === "uniswapV2openTrading" 
        ? "OpenTrading Tx" 
        : "LP transaction",
      path: "lpTransaction"
    },
    {
      title: "Run",
      path: "run"
    },
  ]

  const getActiveTab = () => {
    let profileTabPage = location.pathname.split("/profiles/" + profileId);
    const array = profileTabPage[profileTabPage.length - 1].split('/');
    profileTabPage = array[0];
    if (array.length > 1) {
      profileTabPage = array[1];
    }
    let ind = tabs.findIndex(tab => tab.path === profileTabPage);
    return ind >= 0 ? ind : 0;
  }

  let tabIndex = getActiveTab();

  return (
    <Tabs centered={true} key={"tab-" + tabIndex} className={"profile-tabs"} defaultActiveKey={String(tabIndex)} onChange={(key) => { history.push(`/profiles/${profileId}/${tabs[key].path}`) }}>
      {
        tabs.map((tab, index) => <TabPane key={index} tab={tab.title} />)
      }
    </Tabs>
  );
};

export default ProfileTabs;