import React from 'react';
import { useHistory } from "react-router-dom";
import { Button, Row, Col } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useStores } from '../../../../store/RootStore';
import { getTransactionsAsync } from '../../../../services/apiRequests';
import Wallets from './components/Wallets/Wallets';
import './MultipleWallets.less';

const MultipleWallets = observer(({ loading, setLoading }) => {
  const {
    ProfileStore,
    AuthStore: { accessToken },
  } = useStores();
  const history = useHistory();

  const { profile } = ProfileStore;

  const updatemultipleWalletsState = async () => {
    setLoading(true);
    await getTransactionsAsync(accessToken, profile.uuid);
    await ProfileStore.updateProfile(accessToken, profile.uuid);
    setLoading(false);
    return;
  }

  return (
    <div className="multi-executor">
      <Row gutter={8}>
        <Col span={24}>
          <Wallets />
        </Col>
      </Row>

      <div className={"btn-group"}>
        <Button type={"default"} onClick={() => history.push(`/profiles/${profile.uuid}`)}>Back</Button>
        <Button type={"default"} onClick={() => updatemultipleWalletsState()}><SyncOutlined spin={loading} />Validate</Button>
        <Button type={"primary"} onClick={() => history.push(`/profiles/${profile.uuid}/lpTransaction`)}>Continue</Button>
      </div>
    </div>
  );
});

export default MultipleWallets;