import { observable, action, makeObservable } from "mobx";
import { getMultiExecutorStatusAsync, getMultipleWalletsStatusAsync, getExecutorsStateAsync, getProfileAsync } from "../services/apiRequests";
import { message } from 'antd';

class ProfileStore {
    profile = null;
    multiExecutorState = null;
    multipleWalletsState = null;
    executorsState = {};

    constructor() {
        makeObservable(this, {
            profile: observable,
            multiExecutorState: observable,
            multipleWalletsState: observable,
            executorsState: observable,

            getProfile: action,
            setProfile: action,
            updateProfile: action,
            closeProfile: action,

            getMultiExecutorState: action,
            setMultiExecutorState: action,
            updateMultiExecutorState: action,

            getMultipleWalletsState: action,
            setMultipleWalletsState: action,
            updateMultipleWalletsState: action,

            getExecutorsState: action,
            setExecutorsState: action,
            updateExecutorsState: action,
        });
    };

    getProfile = () => this.profile;
    setProfile = (profile) => {
        this.profile = profile;
    };
    updateProfile = async (accessToken, profileUUID) => {
        let response = await getProfileAsync(accessToken || null, profileUUID);
        if (response.errorMessage) {
            message.error(response.errorMessage);
            this.setProfile({});
        } else {
            this.setProfile(response);
        }
    };
    closeProfile = async () => {
        this.setProfile(null);
        this.setMultiExecutorState(null);
        this.setExecutorsState({});
    };

    getMultiExecutorState = () => this.multiExecutorState;
    setMultiExecutorState = (multiExecutorState) => {
        this.multiExecutorState = multiExecutorState;
    };
    updateMultiExecutorState = async (accessToken, profileUUID) => {
        let response = await getMultiExecutorStatusAsync(accessToken || null, profileUUID);
        if (response.errorMessage) {
            message.error(response.errorMessage);
            this.setMultiExecutorState(false);
        } else {
            this.setMultiExecutorState(response);
            this.setExecutorsState({});
        }
    };

    getMultipleWalletsState = () => this.multiExecutorState;
    setMultipleWalletsState = (multipleWalletsState) => {
        this.multipleWalletsState = multipleWalletsState;
    };
    updateMultipleWalletsState = async (accessToken, profileUUID) => {
        let response = await getMultipleWalletsStatusAsync(accessToken || null, profileUUID);
        if (response.errorMessage) {
            message.error(response.errorMessage);
            this.setMultipleWalletsState(false);
        } else {
            this.setMultipleWalletsState(response);
            this.setExecutorsState({});
        }
    };

    getExecutorsState = () => this.executorsState;
    setExecutorsState = (executorsState) => {
        this.executorsState = executorsState;
    };
    updateExecutorsState = async (accessToken, profileUUID, batchId) => {
        let executors = this.getExecutorsState();
        let response = await getExecutorsStateAsync(accessToken || null, profileUUID, batchId);
        if (response.errorMessage) {
            message.error(response.errorMessage);
            this.setExecutorsState(false);
        } else {
            executors[batchId] = response;
            this.setExecutorsState(executors);
        }
    };
};

export default new ProfileStore();
