import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import moment from 'moment';
import { Button, DatePicker, Modal, message } from 'antd';
import { observer } from 'mobx-react';
import { updateProfileAsync } from '../../../../../../services/apiRequests';
import { useStores } from '../../../../../../store/RootStore';
import './SetLaunchUtcTimeButton.less';

const getCurrentUtcTime = () => {
  return moment().utc();
}

let interval;

const SetLaunchUtcTimeButton = observer(() => {
  const { ProfileStore, AuthStore: { accessToken } } = useStores();
  const { profile } = ProfileStore;
  const { profileId } = useParams();
  const [visible, setVisible] = useState(false);
  const [currentTime, setCurrentTime] = useState(getCurrentUtcTime());
  const [launchTime, setLaunchTime] = useState(!!profile.launchTime ? moment(profile.launchTime).utc() : undefined);

  useEffect(() => {
    interval = setInterval(() => setCurrentTime(getCurrentUtcTime()), 1000);
    return () => {
      clearInterval(interval);
    }
  }, []);

  const onOpen = () => {
    setVisible(true);
  }

  const onCancel = () => {
    setVisible(false);
  }

  const setTime = async () => {
    const currTime = getCurrentUtcTime();
    if (!launchTime) {
      return message.error("Time not selected");
    }
    if (currTime > launchTime) {
      return message.error("Time is out of date")
    }

    let response = await updateProfileAsync(accessToken, profileId, { launchTime });

    if (response.errorMessage) {
      return message.error(response.errorMessage);
    }

    ProfileStore.setProfile(response);
    onCancel();
  }

  const onChangeTime = (time) => {
    time = time.utc();
    setLaunchTime(time);
  }

  const deleteLaunchTime = async () => {
    let response = await updateProfileAsync(accessToken, profileId, { launchTime: null });

    if (response.errorMessage) {
      return message.error(response.errorMessage);
    }

    ProfileStore.setProfile(response);

    setLaunchTime(undefined);
    onCancel();
  }

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  function disabledDate(current) {
    // Can not select days before today and today
    current = current.utc();
    return current < getCurrentUtcTime().startOf('day');
  }

  function disabledDateTime(current) {
    let time = getCurrentUtcTime();

    let result = {
      disabledHours: () => [],
      disabledMinutes: () => []
    }

    if (current) {
      current = current.utc();

      if (current.format("DD") === time.format("DD")) {
        result['disabledHours'] = () => range(0, time.format("HH"))
      }

      if (current.format("DD") === time.format("DD") && current.format("HH") === time.format("HH")) {
        result['disabledMinutes'] = () => range(0, time.format("mm"))
      }
    } else {
      result = {
        disabledHours: () => range(0, 23),
        disabledMinutes: () => range(0, 59)
      }
    }

    return result;
  }

  const modalFooter = [
    <Button key="close" onClick={onCancel}>
      Close
    </Button>,
    <Button key="set" type="primary" onClick={setTime}>
      Set
    </Button>
  ]

  if (!!launchTime) {
    modalFooter.unshift(
      <Button
        key="delete"
        type="danger"
        onClick={deleteLaunchTime}
      >
        Delete
      </Button>
    )
  }

  return (
    <div className={"set-launch-utc-time"}>
      {
        !!launchTime &&
        <div className={"launch-time"}>{launchTime.format("DD.MM.YYYY HH:mm")}</div>
      }

      <Button type={"primary"} onClick={onOpen}>{!!launchTime ? "Update" : "Set"} launch UTC time</Button>

      {
        !!visible &&
        <Modal
          className={"set-launch-utc-time-modal"}
          visible={true}
          title={<h2>Launch UTC time</h2>}
          width={"400px"}
          onCancel={onCancel}
          okText={"Set"}
          footer={modalFooter}
        >
          <div className={"current-time"}>
            Current time: <b>{currentTime.format("DD.MM.YYYY HH:mm")} UTC</b>
          </div>

          <DatePicker
            format="DD.MM.YYYY HH:mm"
            disabledDate={disabledDate}
            disabledTime={disabledDateTime}
            showSecond={false}
            showTime={{ defaultValue: getCurrentUtcTime() }}
            onChange={onChangeTime}
            defaultValue={launchTime}
          />
        </Modal>
      }
    </div>

  );
});

export default SetLaunchUtcTimeButton;