import { observable, action, makeObservable } from "mobx";

class WalletStore {
    walletAddress = null;
    provider = null;

    constructor() {
        makeObservable(this, {
            walletAddress: observable,
            provider: observable,

            onDisconnect: action,
            getWalletAddress: action,
            setWalletAddress: action,
            getProvider: action,
            setProvider: action,
        });
    };

    onDisconnect = () => {
        this.walletAddress = null;
        this.provider = null;
    };

    getWalletAddress = () => this.walletAddress;
    setWalletAddress = (walletAddress) => {
        this.walletAddress = walletAddress;
    };
    getProvider = () => this.provider;
    setProvider = (provider) => {
        this.provider = provider;
    };

};

export default new WalletStore();
