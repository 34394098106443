import React from 'react';
import { Table } from 'antd';
import './ProfileAddLiquidity.less';
import { ethers } from 'ethers';

const ProfileAddLiquidity = props => {
  const { calculation, currentNetwork } = props;
  const tradeInfoColumns = [
    {
      dataIndex: "1",
      key: "1",
      width: "200px",
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if ([0, 6].indexOf(index) >=0 ) {
          obj.props.colSpan = 2;
        }
        return obj;
      }
    },
    {
      dataIndex: "2",
      key: "2",
    },
  ];

  const tradeInfoData = [
    {
      key: '1',
      "1": <b>Base token ({calculation.baseTokenName})</b>,
    },
    {
      key: '2',
      "1": "Contract address",
      "2": <><b><a href={`${currentNetwork.explorer}address/${calculation.baseToken}`} target={"_blank"} rel="noreferrer">{calculation.baseToken}</a></b></>,
    },
    {
      key: '41',
      "1": "Token amount, wei",
      "2": <>{ethers.BigNumber.from(calculation.baseTokenAmountBN).toString()}</>,
    },
    {
      key: '42',
      "1": "Buy fee%",
      "2": <>{ethers.BigNumber.from(calculation.baseTokenBuyFeePercent || 0).toString()}</>,
    },
    {
      key: '3',
      "1": "Decimals",
      "2": <><b>{calculation.baseTokenDecimals}</b></>,
    },
    {
      key: '4',
      "1": "Token amount",
      "2": <><b>{+(calculation.baseTokenAmount).toFixed(8)}</b></>,
    },
    {
      key: '5',
      "1": "Token price",
      "2": <><b>${+(calculation.baseTokenPriceInUsd).toFixed(4)}</b></>,
    },
    {
      key: '6',
      "1": <b>Quote token</b>,
    },
    {
      key: '7',
      "1": "Contract address",
      "2": <><b><a href={`${currentNetwork.explorer}address/${calculation.quoteToken}`} target={"_blank"}>{calculation.quoteToken}</a></b></>
    },
    {
      key: '91',
      "1": "Token amount, wei",
      "2": <>{ethers.BigNumber.from(calculation.quoteTokenAmountBN).toString()}</>,
    },
    {
      key: '8',
      "1": "Decimals",
      "2": <><b>{calculation.quoteTokenDecimals}</b></>
    },
    {
      key: '9',
      "1": "Token amount",
      "2": <><b>{+(calculation.quoteTokenAmount).toFixed(8)}</b></>
    },
    {
      key: '10',
      "1": "Token price",
      "2": <><b>${+(calculation.quoteTokenPriceInUsd).toFixed(4)}</b></>
    },
    {
      key: '11',
      "1": <b>Total amount ($)</b>,
      "2": <><b>${+(calculation.baseTokenAmountInUsd + calculation.quoteTokenAmountInUsd).toFixed(2)}</b></>
    },
    
  ]

  return (
    <div className={"profile-add-liquidity"}>
      <h3>Adding liquidity</h3>
      <Table className={"profile-add-liquidity-table"} pagination={false} size={"small"} showHeader={false} columns={tradeInfoColumns} dataSource={tradeInfoData} />
    </div>
  );
};

export default ProfileAddLiquidity;