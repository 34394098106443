import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Table, Popconfirm, message } from 'antd';
import { CloseSquareOutlined } from '@ant-design/icons';
import { getProfilesAsync, updateProfileAsync } from '../../services/apiRequests';
import { observer } from 'mobx-react';
import { useStores } from '../../store/RootStore';
import './ProfileList.less';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: "200px",
    ellipsis: true,
    render: (item) => (
      <b>{item}</b>
    )
  },
  {
    title: 'ID',
    dataIndex: 'id',
    width: "250px",
    ellipsis: true,
    render: (item) => <b>{item}</b>,
  },
  {
    title: 'Network',
    dataIndex: 'network',
    width: "200px",
  },
  {
    title: 'Exchange',
    dataIndex: 'exchange',
  },
  {
    title: 'Mode',
    dataIndex: 'mode',
  },
  {
    title: 'Date of creation',
    dataIndex: 'date',
  },
  {
    title: 'To archive',
    dataIndex: 'action',
    align: "center",
    width: "100px",
  },
];

const ProfileList = observer(() => {
  const {
    AppStore: { networks },
    AuthStore: { accessToken }
  } = useStores();

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    if (accessToken) {
      updateProfiles();
    }
  }, []);

  const onDelete = async (profileId) => {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      await updateProfileAsync(accessToken, profileId, { isActive: false });
      await updateProfiles();
    } catch (e) { }
    setLoading(false);
  }

  const updateProfiles = async () => {
    let response = await getProfilesAsync(accessToken);
    if (response.errorMessage) {
      message.error(response.errorMessage);
      return setProfiles([]);
    }

    response.reverse();

    return setProfiles(response
      .filter(profile => !!profile.calculation)
      .map(((profile, index) => {
        let currentNetwork = networks ? networks.find(network => network.chainId === profile.calculation.chainId) : undefined;
        let currentExchange = !!currentNetwork ? currentNetwork.exchanges.find(exchange => exchange.exchangeId === profile.calculation.exchangeId) : undefined;

        let creationDate = new Date(profile.createdAt);
        return {
          key: index,
          id: profile.uuid,
          name: profile.name,
          network: currentNetwork.networkName,
          exchange: currentExchange.exchangeName,
          mode: currentNetwork.modes[profile.calculation.mode] ? currentNetwork.modes[profile.calculation.mode].name : profile.calculation.mode,
          date: creationDate.toLocaleDateString() + " " + creationDate.toLocaleTimeString(),
          action: [
            <Popconfirm
              key={2}
              title="Are you sure you want to move this profile to archive?"
              onConfirm={(e) => { e.stopPropagation(); onDelete(profile.uuid) }}
              onCancel={(e) => e.stopPropagation()}
              okText="Yes"
              cancelText="No"
            >
              <Button style={{ color: "red" }} size={"small"} loading={loading} disabled={loading} type={"link"}><CloseSquareOutlined /></Button>
            </Popconfirm>
          ]
        }
      })));
  }

  return (
    <div className="profile-list">
      <Table
        size="middle"
        pagination={{
          defaultPageSize: 25
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: e => {
              if (!e.defaultPrevented) {
                history.push("/profiles/" + record.id)
              }
            }
          };
        }}
        loading={!profiles && !!accessToken}
        columns={columns}
        dataSource={!!profiles ? profiles.map((p, i) => { p.key = i; return p; }) : []}
      />
    </div>
  );
});

export default ProfileList;