import Config from 'Config';

const apiUrl = Config['apiUrl'];

const apiRequestAsync = async (url, method, body = null, accessToken = null) => {
    let requestOptions = {
        method: method,
        timeout: 120000,
        headers: {
            'Content-Type': 'application/json'
        }
    }

    if (!!accessToken) {
        requestOptions.headers['authorization'] = 'Bearer ' + accessToken;
    }

    if (!!body) {
        requestOptions.body = JSON.stringify(body);
    }

    try {
        let response = await fetch(apiUrl + url, requestOptions);
        return await response.json();
    } catch (e) {
        return {
            errorMessage: e.message === "Failed to fetch" ? "API server unavailable" : e.message
        }
    }
}

export const getNetworksAsync = async () => {
    return await apiRequestAsync('/networks', 'get');
}

export const getCalculationByIDAsync = async (calculationId) => {
    return await apiRequestAsync('/calculation/' + calculationId, 'get');
}

export const calculationAsync = async (body) => {
    return await apiRequestAsync('/calculation/', 'post', body);
}

export const createProfileAsync = async (accessToken, body) => {
    return await apiRequestAsync('/profile/', 'post', body, accessToken);
}

export const getProfilesAsync = async (accessToken) => {
    return await apiRequestAsync('/profiles', 'get', null, accessToken);
}

export const getProfileAsync = async (accessToken, profileId) => {
    return await apiRequestAsync('/profile/' + profileId, 'get', null, accessToken);
}

export const updateProfileAsync = async (accessToken, profileId, body) => {
    return await apiRequestAsync('/profile/' + profileId, 'put', body, accessToken);
}

export const getMultiExecutorStatusAsync = async (accessToken, profileId) => {
    return await apiRequestAsync('/profile/' + profileId + '/multiExecutor', 'get', null, accessToken);
}

export const getExecutorsStateAsync = async (accessToken, profileId, batchId) => {
    return await apiRequestAsync('/profile/' + profileId + '/executors/batch/' + batchId, 'get', null, accessToken);
}

export const gasFillingAsync = async (accessToken, profileId, batchId) => {
    return await apiRequestAsync('/profile/' + profileId + '/executors/batch/' + batchId + "/fill", 'post', null, accessToken);
}

export const deployAsync = async (accessToken, profileId) => {
    return await apiRequestAsync('/profile/' + profileId + '/multiExecutor/deploy', 'post', null, accessToken);
}

export const initializeAsync = async (accessToken, profileId) => {
    return await apiRequestAsync('/profile/' + profileId + '/multiExecutor/initialize', 'post', null, accessToken);
}

export const allowAsync = async (accessToken, profileId) => {
    return await apiRequestAsync('/profile/' + profileId + '/multiExecutor/addExecutors', 'post', null, accessToken);
}

export const signTxsAsync = async (accessToken, profileId) => {
    return await apiRequestAsync('/profile/' + profileId + '/multiExecutor/signTransactions', 'post', null, accessToken);
}

export const cleaningAsync = async (accessToken, profileId) => {
    return await apiRequestAsync('/profile/' + profileId + '/multiExecutor/cleaning', 'post', null, accessToken);
}

export const getTradesAsync = async (accessToken, profileId) => {
  return await apiRequestAsync('/profile/' + profileId + '/trades/', 'get', null, accessToken);
}

export const getTradeAsync = async (accessToken, profileId, tradeId) => {
  return await apiRequestAsync('/profile/' + profileId + '/trades/' + tradeId, 'get', null, accessToken);
}

export const saveTradeTxAsync = async (accessToken, profileId, tradeId, body) => {
  return await apiRequestAsync('/profile/' + profileId + '/trades/' + tradeId, 'post', body, accessToken);
}

export const getMultipleWalletsStatusAsync = async (accessToken, profileId) => {
    return await apiRequestAsync('/profile/' + profileId + '/multipleWallets', 'get', null, accessToken);
}

export const getLpTxAsync = async (accessToken, profileId) => {
    return await apiRequestAsync('/profile/' + profileId + '/lp', 'get', null, accessToken);
}

export const sendLpTxAsync = async (accessToken, profileId) => {
    return await apiRequestAsync('/profile/' + profileId + '/lp/send', 'post', null, accessToken);
}

export const saveLpTxAsync = async (profileId, body) => {
    return await apiRequestAsync('/profile/' + profileId + '/lp', 'post', body);
}

export const validateLpTxAsync = async (profileId, body) => {
    return await apiRequestAsync('/profile/' + profileId + '/lp/validate', 'post', body);
}

export const createLowGasLpTxConfigAsync = async (accessToken, profileId, body) => {
    return await apiRequestAsync('/profile/' + profileId + '/lowGas/lp', 'post', body, accessToken);
}

export const updateLowGasLpTxConfigAsync = async (profileId, body) => {
    return await apiRequestAsync('/profile/' + profileId + '/lowGas/lp', 'put', body);
}

export const deleteLowGasLpTxConfigAsync = async (accessToken, profileId) => {
    return await apiRequestAsync('/profile/' + profileId + '/lowGas/lp', 'delete', null, accessToken);
}

export const getLowGasLpTxConfigAsync = async (accessToken, profileId) => {
    return await apiRequestAsync('/profile/' + profileId + '/lowGas/lp', 'get', null, accessToken);
}

export const getOpTrTxAsync = async (accessToken, profileId) => {
  return await apiRequestAsync('/profile/' + profileId + '/ot', 'get', null, accessToken);
}

export const sendOpTrTxAsync = async (accessToken, profileId) => {
  return await apiRequestAsync('/profile/' + profileId + '/ot/send', 'post', null, accessToken);
}

export const saveOpTrTxAsync = async (profileId, body) => {
  return await apiRequestAsync('/profile/' + profileId + '/ot', 'post', body);
}

export const validateOpTrTxAsync = async (profileId, body) => {
  return await apiRequestAsync('/profile/' + profileId + '/ot/validate', 'post', body);
}

export const finalCheckAsync = async (accessToken, profileId) => {
    return await apiRequestAsync('/profile/' + profileId + '/final/check', 'post', null, accessToken);
}

export const runAsync = async (accessToken, profileId) => {
    return await apiRequestAsync('/profile/' + profileId + '/final/run', 'post', null, accessToken);
}

export const getTransactionsAsync = async (accessToken, profileId) => {
    return await apiRequestAsync('/profile/' + profileId + '/transactions', 'get', null, accessToken);
}

export const pingServicesAsync = async () => {
    return await apiRequestAsync('/app/ping', 'get');
}

export const restartBroadcastServiceAsync = async (accessToken) => {
    return await apiRequestAsync('/app/broadcast-service/restart', 'post', null, accessToken);
}