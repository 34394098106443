import React, { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import Web3 from 'web3';
import { observer } from 'mobx-react';
import { useStores } from '../../store/RootStore';
import './ConnectMetamaskButton.less';
import { convertMevChains } from '../../services/utils';

const ConnectMetamaskButton = observer(({ currentNetwork }) => {
  const { WalletStore } = useStores();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    window.web3 = new Web3(window.ethereum);
    WalletStore.setProvider(window.ethereum || null);
    useMetaMask();
    getConnectedWallet();
  })

  const useMetaMask = async () => {
    ethereum.on('connect', () => {
      getConnectedWallet();
    });

    ethereum.on('disconnect', () => {
      WalletStore.onDisconnect();
    });

    ethereum.on('accountsChanged', () => {
      getConnectedWallet();
    });

    ethereum.on('chainChanged', () => {
      window.location.reload();
    });
  }

  const hasMetaMask = async () => {
    setErrorMessage();

    if (
      !WalletStore.provider
      || !WalletStore.provider.isMetaMask
    ) {
      setErrorMessage("Metamask not found. Check and try again.");
      return false;
    }
    let networkId = await Web3.utils.hexToNumber(WalletStore.provider.chainId);
    if (networkId !== convertMevChains(currentNetwork.chainId)) {
      return false;
    }

    return true;
  }

  const getConnectedWallet = async () => {
    if (await hasMetaMask()) {
      let selectedAddresses = await WalletStore.provider.request({ method: 'eth_accounts' });
      if (selectedAddresses) {
        WalletStore.setWalletAddress(selectedAddresses[0]);
        return true;
      }
    }

    WalletStore.setWalletAddress(null);
    return false;
  }

  const onConnect = async (e) => {
    setLoading(true);
    await hasMetaMask();

    if (errorMessage) {
      setLoading(false);
      message.error(errorMessage, 3);
      return false;
    }

    try {
      let networkId = await Web3.utils.hexToNumber(WalletStore.provider.chainId);
      if (networkId !== convertMevChains(currentNetwork.chainId)) {
        await WalletStore.provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: web3.utils.toHex(convertMevChains(currentNetwork.chainId)) }]
        });

        setLoading(false);
        return false;
      }
      const accounts = await WalletStore.provider.request({ method: 'eth_requestAccounts' })
      if (typeof accounts === "object" && accounts.length === 0) {
        message.error("Address not found. Check and try again.", 2);
        setLoading(false);
        return false;
      }
      await getConnectedWallet();
      if (errorMessage) {
        message.error(err.message);
      }
    } catch (err) {
      if (err) {
        message.error(err.message);
      }
    }
    setLoading(false);
  };

  return (
    <div className={"connect-metamask"}>
      {
        WalletStore.walletAddress
          ? <div className={"connected"}>
            <div className={"title"}>Connected Metamask wallet</div>
            <div className={"address"}><a href={`${currentNetwork.explorer}address/${WalletStore.walletAddress}`} target={"_blank"}><b>{WalletStore.walletAddress}</b></a></div>
          </div>
          : <Button className={"connect-button"} loading={loading} disabled={loading} type="primary" htmlType="submit" onClick={onConnect}>Connect MetaMask</Button>
      }
    </div>
  );
});

export default ConnectMetamaskButton;