import React, { useState, useEffect } from "react";
import { Button, message } from 'antd';
import * as ethers from 'ethers';
import Decimal from 'decimal.js';
import { Transaction } from "@ethereumjs/tx";
import Common from '@ethereumjs/common'
import { observer } from 'mobx-react';
import { useStores } from '../../store/RootStore';
import { convertMevChains, getRouterAbi } from "../../services/utils";
import './SignLpTxByMetamask.less';

import ConnectMetamaskButton from '../ConnectMetamaskButton/ConnectMetamaskButton';
import ApproveButton from '../ApproveButton/ApproveButton';
import CreatePairButton from '../CreatePairButton/CreatePairButton';

const SignLpTxByMetamask = observer(({ calculation, lpTokenReceiver, lowGasLpTransaction, currentNetwork, currentExchange, onSaveLpTransaction, customRouter }) => {
  const { WalletStore } = useStores();

  useEffect(() => {
    return () => {
      WalletStore.setProvider(null);
      WalletStore.setWalletAddress(null);
    };
  }, []);

  const [isBaseTokenReady, setIsBaseTokenReady] = useState(false);
  const [isQuoteTokenReady, setIsQuoteTokenReady] = useState(false);
  const [isPairReady, setIsPairReady] = useState(!currentNetwork.isPairCreationRequired);
  const [loading, setLoading] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);

  const onSign = async (gasPrice = null) => {
    setLoading(true);

    try {
      lpTokenReceiver = ethers.utils.getAddress(lpTokenReceiver);
    } catch (e) {
      message.error("Wrong LP token receiver");
      setLoading(false);
      return false;
    }

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      let routerContract = new ethers.Contract(customRouter || currentExchange.routerAddress, getRouterAbi(calculation.chainId, calculation.exchangeId), provider);
      let slippageToleranceCoefficient = ((100 - currentNetwork.initialLiquidityAddSlippageTolerancePercent) / 100);

      const baseTokenDesired = calculation.baseTokenAmountBN 
        ? ethers.BigNumber.from(calculation.baseTokenAmountBN)
        : convertDecimalToBigNumber(calculation.baseTokenAmount, calculation.baseTokenDecimals);
      const baseTokenMin = convertDecimalToBigNumber(+(calculation.baseTokenAmount * slippageToleranceCoefficient).toFixed(calculation.baseTokenDecimals), calculation.baseTokenDecimals);
      const quoteTokenDesired = calculation.quoteTokenAmountBN 
        ? ethers.BigNumber.from(calculation.quoteTokenAmountBN)
        : convertDecimalToBigNumber(calculation.quoteTokenAmount, calculation.quoteTokenDecimals);
      const quoteTokenMin = convertDecimalToBigNumber(+(calculation.quoteTokenAmount * slippageToleranceCoefficient).toFixed(calculation.quoteTokenDecimals), calculation.quoteTokenDecimals);

      const isNativeCurrency = calculation.quoteToken.toLowerCase() === currentNetwork.networkCurrency.address.toLowerCase();

      const nonce = await provider.getTransactionCount(WalletStore.walletAddress);

      let tx;
      if (isNativeCurrency) {
        tx = await routerContract.populateTransaction[currentExchange.addLiquidityWithNativeCurrencyMethodName](
          ethers.utils.getAddress(calculation.baseToken),
          baseTokenDesired,
          baseTokenMin,
          quoteTokenMin,
          ethers.utils.getAddress(lpTokenReceiver),
          getDeadline(72)
        );
      } else {
        tx = await routerContract.populateTransaction[currentExchange.addLiquidityWithCustomCurrencyMethodName](
          ethers.utils.getAddress(calculation.baseToken),
          ethers.utils.getAddress(calculation.quoteToken),
          baseTokenDesired,
          quoteTokenDesired,
          baseTokenMin,
          quoteTokenMin,
          ethers.utils.getAddress(lpTokenReceiver),
          getDeadline(72)
        );
      };

      const common = Common.custom({ chainId: convertMevChains(currentNetwork.chainId) });

      let transaction = new Transaction({
        data: tx.data,
        to: tx.to,
        chainId: ethers.utils.hexlify(convertMevChains(currentNetwork.chainId)),
        value: isNativeCurrency ? quoteTokenDesired.toHexString() : ethers.BigNumber.from(0).toHexString(),
        gasPrice: ethers.utils.parseUnits(String(gasPrice || calculation.liquidityAddGasPrice), "gwei")._hex,
        gasLimit: ethers.utils.hexlify(ethers.BigNumber.from(currentNetwork.liquidityAddOperationGasLimit), { hexPad: "left" }),
        nonce: ethers.utils.hexlify(ethers.BigNumber.from(nonce), { hexPad: "left" })
      }, { common });

      let unsignedTx = transaction.getMessageToSign();
      let signature = await window.ethereum.request({
        method: 'eth_sign',
        params: [
          WalletStore.walletAddress,
          ethers.utils.hexlify(unsignedTx)
        ]
      });

      let signatureParts = ethers.utils.splitSignature(signature);

      let txWithSignature = transaction._processSignature(
        signatureParts.v,
        ethers.utils.arrayify(signatureParts.r),
        ethers.utils.arrayify(signatureParts.s)
      );

      let serializedTxWithSignature = ethers.utils.hexlify(txWithSignature.serialize());
      if (!!gasPrice) {
        await onSaveLpTransaction({
          lowGasHexTransaction: serializedTxWithSignature
        });
      } else {
        await onSaveLpTransaction({
          hexTransaction: serializedTxWithSignature
        });
      }
    } catch (e) {
      message.error(e.message)
    }

    setLoading(false)
  }

  const convertDecimalToBigNumber = (amount, decimals) => {
    return ethers.BigNumber.from(
      Decimal.pow(10, decimals).times(new Decimal(amount)).trunc().toFixed());
  }

  const getDeadline = (hours) => {
    return Math.floor(Date.now() / 1000) + 60 * 60 * hours;
  }

  return (
    <div className={"sign-by-metamask"}>
      <ConnectMetamaskButton currentNetwork={currentNetwork} />
      {
        !!WalletStore.walletAddress &&
        <div className={!!lowGasLpTransaction ? "buttons low-gas" : "buttons"}>
          <ApproveButton
            key={"bt-user-address-" + WalletStore.walletAddress}
            tokenName={"Base token"}
            tokenContract={calculation.baseToken}
            tokenDecimals={calculation.baseTokenDecimals}
            tokenAmount={calculation.baseTokenAmountBN 
              ? +ethers.utils.formatUnits(calculation.baseTokenAmountBN, calculation.baseTokenDecimals)
              : calculation.baseTokenAmount
            }
            currentNetwork={currentNetwork}
            currentExchange={currentExchange}
            disabled={disableButtons}
            onLoading={setDisableButtons}
            onReady={setIsBaseTokenReady}
            customRouter={customRouter}
          />
          <ApproveButton
            key={"qt-user-address-" + WalletStore.walletAddress}
            tokenName={"Quote token"}
            tokenContract={calculation.quoteToken}
            tokenDecimals={calculation.quoteTokenDecimals}
            tokenAmount={calculation.quoteTokenAmount}
            currentNetwork={currentNetwork}
            currentExchange={currentExchange}
            disabled={disableButtons}
            onLoading={setDisableButtons}
            onReady={setIsQuoteTokenReady}
            customRouter={customRouter}
          />
          {
            currentNetwork.isPairCreationRequired &&
            <CreatePairButton
              key={"create-pair"}
              baseTokenContract={calculation.baseToken}
              quoteTokenContract={calculation.quoteToken}
              currentNetwork={currentNetwork}
              currentExchange={currentExchange}
              disabled={disableButtons}
              onLoading={setDisableButtons}
              onReady={setIsPairReady}
            />
          }

          {
            !!lowGasLpTransaction &&
            <div>
              <Button
                loading={loading}
                disabled={disableButtons || loading || !isBaseTokenReady || !isQuoteTokenReady || !isPairReady}
                type="primary"
                size={"large"}
                onClick={() => onSign()}
              >
                Sign main tx
              </Button>
              <Button
                loading={loading}
                disabled={disableButtons || loading || !isBaseTokenReady || !isQuoteTokenReady || !isPairReady}
                type="primary"
                size={"large"}
                onClick={() => onSign(lowGasLpTransaction.gasPriceGwei)}
              >
                Sign low gas tx
              </Button>

            </div>
          }
          {
            !lowGasLpTransaction &&
            <Button
              loading={loading}
              disabled={disableButtons || loading || !isBaseTokenReady || !isQuoteTokenReady || !isPairReady}
              type="primary"
              size={"large"}
              onClick={() => onSign()}
            >
              Sign
            </Button>
          }
        </div>
      }
    </div>
  )
});

export default SignLpTxByMetamask;