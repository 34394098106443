import React from 'react';
import { Table } from 'antd';
import './ProfileSnipingTrade.less';

const ProfileSnipingTrade = props => {
  const { profile } = props;

  const snipingTradeColumns = [
    {
      dataIndex: '1',
      key: '1',
      width: "250px"
    },
    {
      dataIndex: '2',
      key: '2'
    },
  ];

  const snipingTradeData = [
    {
      key: '1',
      '1': "From ($)",
      '2': <><b>${+(profile.calculation.trades.reduce((acc, v) => { return acc + v.quoteTokenAmountFromInUSD }, 0)).toFixed(6)}</b></>
    },
    {
      key: '2',
      '1': "From (Quote token)",
      '2': <><b>{+(profile.calculation.trades.reduce((acc, v) => { return acc + v.quoteTokenAmountFrom }, 0)).toFixed(6)}</b></>
    },
    {
      key: '3',
      '1': "To (Base token)",
      '2': <><b>{+(profile.calculation.trades.reduce((acc, v) => { return acc + v.baseTokenAmountTo }, 0)).toFixed(6)}</b></>
    },
    {
      key: '4',
      '1': "Base token price after sniping ($)",
      '2': <><b>${profile.calculation.trades[profile.calculation.trades.length - 1].baseTokenToQuoteTokenAfterTradePriceInUsd}</b></>
    },
    {
      key: '5',
      '1': "Price change",
      '2': <><b>{+(profile.calculation.trades[profile.calculation.trades.length - 1].baseTokenToQuoteTokenAfterTradePriceInUsd / profile.calculation.trades[0].baseTokenToQuoteTokenBeforeTradePriceInUsd).toFixed(4)}x</b></>
    }
  ];

  return (
    <div className={"profile-sniping-trade"}>
      <h3>Sniping trade</h3>
      <Table className={"profile-sniping-trade-table"} pagination={false} size={"small"} showHeader={false} columns={snipingTradeColumns} dataSource={snipingTradeData} />
    </div>
  );
};

export default ProfileSnipingTrade;