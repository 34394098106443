import React from 'react';
import Config from 'Config';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = Config['authDomain'];
  const clientId = Config['authClientId'];
  const audience = Config['authAudience'];

  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(!!appState ? appState.returnTo : window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;