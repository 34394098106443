import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Row, Col, Switch, Tabs, Popconfirm, Space, message } from 'antd';
import { useHistory } from "react-router-dom";
import { SyncOutlined, CloseOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons';
import { getLpTxAsync, validateLpTxAsync, saveLpTxAsync, deleteLowGasLpTxConfigAsync, updateLowGasLpTxConfigAsync } from "../../../../services/apiRequests";
import './ProfileLpTransaction.less';

import ProfileLpTxInfo from "../ProfileLpTxInfo/ProfileLpTxInfo";
import ProfileLpValidation from "../ProfileLpValidation/ProfileLpValidation";
import SignLpTxByMetamask from "../../../SignLpTxByMetamask/SignLpTxByMetamask";
import CreateLowGasLpConfigModal from "../CreateLowGasLpConfigModal/CreateLowGasLpConfigModal";
import ProfileEditModal from "../ProfileEditModal/ProfileEditModal";

const { TabPane } = Tabs;

const ProfileLpTransaction = ({ accessToken, profile, networks, loading, setLoading, updateProfile }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [lpTransaction, setLpTransaction] = useState();
  const [lpValidation, setLpValidation] = useState();
  const [lowGasLpModalVisability, setLowGasLpModalVisability] = useState(false);
  const [lowGasLpConfigPopconfirm, setLowGasLpConfigPopconfirm] = useState(false);
  const [lowGasLpConfigEdit, setLowGasLpConfigEdit] = useState(false);

  useEffect(async () => {
    if (loading || !profile.lpTransaction || !!lpTransaction || lpTransaction === false || !accessToken) {
      return;
    }
    await update();
    return;
  });

  const update = async () => {
    setLoading(true);
    await updateProfile();
    await Promise.all(
      [
        updateLpTransaction(),
        updateLpValidation(profile.lpTransaction)
      ]
    )
    setLoading(false);
  }

  const updateLpTransaction = async () => {
    let response = await getLpTxAsync(accessToken || null, profile.uuid);
    if (response.errorMessage) {
      setLpTransaction(false);
      return;
    }
    return setLpTransaction(response);
  }

  const updateLpValidation = async (lpTransaction) => {
    let response = await validateLpTxAsync(profile.uuid, lpTransaction);
    if (response.errorMessage) {
      setLpValidation(false);
      return;
    }
    return setLpValidation(response);
  }

  const onSaveLpTransaction = async (values) => {
    setLoading(true);
    if (!!values.hexTransaction) {
      let response = await saveLpTxAsync(profile.uuid, values);
      if (response.errorMessage) {
        message.error(response.errorMessage, 5);
        setLoading(false);
        return;
      }
      message.success("LP transaction saved successfully", 5);
      setLpTransaction(response);
      await updateLpValidation(values);
    }

    if (!!profile.lpTransactionWithLowGas && !!values.lowGasHexTransaction) {
      let response = await updateLowGasLpTxConfigAsync(profile.uuid, {
        hexTransaction: values.lowGasHexTransaction
      });
      if (response.errorMessage) {
        message.error(response.errorMessage, 5);
        setLoading(false);
        return;
      }
      message.success("LP transaction with low gas saved successfully", 5);
    }

    await updateProfile();

    setLoading(false);
    return
  }

  const validate = async (hexTransaction) => {
    let validation = await validateLpTxAsync(profile.uuid, { hexTransaction });
    return setLpValidation(validation);
  }

  const switchLowGasLpConfig = async () => {
    if (!!profile.lpTransactionWithLowGas) {
      setLowGasLpConfigPopconfirm(true);
    } else {
      let unactiveConfig = await updateLowGasLpTxConfigAsync(profile.uuid, {
        isActive: true
      });
      if (!!unactiveConfig && !unactiveConfig.errorMessage) {
        await updateProfile();
      } else {
        setLowGasLpModalVisability(true);
      }
    }
  }

  const updateDelayInLowGasLpConfig = async (value) => {
    if (!!value && value.hasOwnProperty("delayMs") && (typeof value.delayMs === "number" || typeof value.delayMs === "string")) {
      try {
        value.delayMs = Number(value.delayMs);
        await updateLowGasLpTxConfigAsync(profile.uuid, {
          delayMs: value.delayMs
        });
      } catch (e) { }
      await updateProfile();
    }
    setLowGasLpConfigEdit(false);
    return;
  }

  const deleteLowGasLpConfig = async () => {
    await deleteLowGasLpTxConfigAsync(accessToken || null, profile.uuid);
    await updateProfile();
    setLowGasLpConfigPopconfirm(false);
    return;
  }

  const disableLowGasLpConfig = async () => {
    await updateLowGasLpTxConfigAsync(profile.uuid, {
      isActive: false
    });
    await updateProfile();
    setLowGasLpConfigPopconfirm(false);
    return;
  }

  const currentNetwork = networks ? networks.find(network => network.chainId === profile.calculation.chainId) : undefined;
  const currentExchange = !!currentNetwork ? currentNetwork.exchanges.find(exchange => exchange.exchangeId === profile.calculation.exchangeId) : undefined;

  return (
    <div className={"profile-lp-transaction"}>
      <Row gutter={8}>
        <Col span={18}>
          <h3>LP transaction</h3>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Main" key="1">
              <ProfileLpTxInfo
                parsedTransaction={!!profile.lpTransaction && !!profile.lpTransaction.parsedResult ? profile.lpTransaction.parsedResult : null}
                currentNetwork={currentNetwork}
              />
            </TabPane>
            <TabPane tab="Low gas" key="2" disabled={!profile.lpTransactionWithLowGas}>
              {
                !!profile.lpTransactionWithLowGas &&
                <ProfileLpTxInfo
                  parsedTransaction={profile.lpTransactionWithLowGas.parsedResult || null}
                  currentNetwork={currentNetwork}
                />
              }
            </TabPane>
          </Tabs>
          <h3>Add/Update LP transaction (HEX or sign by MetaMask)</h3>
          <div style={{ marginBottom: "20px" }}>
            Send LP tx with low gas ahead of main LP tx:
            <Switch
              style={{ marginLeft: "10px" }}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={!!profile.lpTransactionWithLowGas}
              onClick={switchLowGasLpConfig}
              disabled={[1, 5, 100056, 100097, 110056, 100137].indexOf(profile.calculation.chainId) >= 0}
            />
            <Popconfirm
              zIndex={1}
              title={
                <div>
                  <div>Do you really want to delete/disable LP transaction with low gas price?</div>
                  <Space
                    style={{
                      width: "100%",
                      justifyContent: "right",
                      marginTop: "10px"
                    }}
                    size={"small"}
                  >
                    <Button key={1} type={"link"} style={{ color: "red" }} size={"small"} onClick={deleteLowGasLpConfig}>Delete</Button>
                    <Button key={2} type={"primary"} size={"small"} onClick={disableLowGasLpConfig}>Disable</Button>
                  </Space>
                </div>
              }
              visible={lowGasLpConfigPopconfirm}
              okButtonProps={{ hidden: true }}
              onCancel={() => setLowGasLpConfigPopconfirm(false)}
            />
            {
              !!profile.lpTransactionWithLowGas &&
              <>
                <div><i>Gas price: <b>{profile.lpTransactionWithLowGas.gasPriceGwei} gwei</b></i></div>
                <div><i>Delay: <b>{profile.lpTransactionWithLowGas.delayMs} ms</b> <EditOutlined onClick={() => setLowGasLpConfigEdit(true)} /></i></div>

                {
                  !!lowGasLpConfigEdit &&
                  <ProfileEditModal
                    param={{
                      title: "Delay, ms",
                      name: "delayMs",
                      value: profile.lpTransactionWithLowGas.delayMs,
                    }}
                    visible={true}
                    onCreate={updateDelayInLowGasLpConfig}
                    onCancel={() => setLowGasLpConfigEdit(false)}
                  />
                }
              </>
            }

          </div>
          <Row gutter={8}>
            <Col span={12}>
              <div className={"add-lp-transaction"}>
                <Form
                  form={form}
                  layout="vertical"
                  autoComplete="off"
                  onFinish={onSaveLpTransaction}
                >
                  <Form.Item
                    name="hexTransaction"
                    label="Signed transaction (hex)"
                  >
                    <Input placeholder="0x..." />
                  </Form.Item>
                  {
                    !!profile.lpTransactionWithLowGas &&
                    <Form.Item
                      name="lowGasHexTransaction"
                      label="Signed transaction with low gas (hex)"
                    >
                      <Input placeholder="0x..." />
                    </Form.Item>
                  }
                  <Form.Item>
                    <Button type="primary" loading={loading} disabled={loading} htmlType="submit">{!!profile.lpTransaction ? "Update" : "Add"}</Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>

            <Col span={12}>
              <div className={"add-lp-transaction"}>
                <SignLpTxByMetamask
                  calculation={profile.calculation}
                  lpTokenReceiver={profile.lpTokenReceiver}
                  lowGasLpTransaction={profile.lpTransactionWithLowGas}
                  currentNetwork={currentNetwork}
                  currentExchange={currentExchange}
                  onSaveLpTransaction={onSaveLpTransaction}
                  customRouter={profile.customRouter}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <ProfileLpValidation
            validation={lpValidation}
          />
        </Col>
      </Row>
      <div className={"btn-group"}>
        <a className={'ant-btn ant-btn-link'} target="_blank" href={`/signLp/calculation/${profile.calculation.uuid}/receiver/${profile.lpTokenReceiver}`}>Share</a>
        <Button type={"default"} onClick={() => history.push(`/profiles/${profile.uuid}/multiExecutor`)}>Back</Button>
        <Button type={"default"} onClick={() => update()}><SyncOutlined spin={loading} />Validate</Button>
        <Button type={"primary"} onClick={() => history.push(`/profiles/${profile.uuid}/run`)}>Continue</Button>
      </div>

      <CreateLowGasLpConfigModal
        accessToken={accessToken}
        profile={profile}
        visible={lowGasLpModalVisability}
        onCancel={() => setLowGasLpModalVisability(false)}
        onOk={async () => {
          await updateProfile();
          setLowGasLpModalVisability(false);
        }}
      />
    </div>
  );
};

export default ProfileLpTransaction;