import React, { useEffect, useState } from 'react';
import { Alert, Button, List, Table } from 'antd';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../../store/RootStore';
import { getStatusIcon } from '../../../../../../services/utils';
import './Wallets.less';
import { useHistory } from 'react-router-dom';
import { ethers } from 'ethers';
import { getTradesAsync } from '../../../../../../services/apiRequests';

const Wallets = observer(() => {
  const history = useHistory();
  const {
    ProfileStore: { profile },
    AuthStore: { accessToken }
  } = useStores();

  const [trades, setTrades] = useState([]);
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    updateTransaction();
  }, []);

  const updateTransaction = async () => {
    setLoading(true);
    const trades = await getTradesAsync(accessToken || null, profile.uuid);

    if (trades.errorMessage) {
      setErrorMessage(trades.errorMessage);
      setLoading(false);
      return setTrades([]);
    }

    setLoading(false);
    return setTrades(trades);
  }

  let columns = [
    { dataIndex: 'trade', key: 'trade', title: 'Trade', width: "60px", align: "center" },
    { dataIndex: 'wallet', key: 'wallet', title: 'Wallet', width: "400px", align: "left" },
    { dataIndex: 'nativeCurrencyBalance', key: 'nativeCurrencyBalance', title: 'Native currency balance', width: "250px", align: "left" },
    { dataIndex: 'quoteTokenBalance', key: 'quoteTokenBalance', title: 'Quote token balance', width: "200px", align: "left" },
    { dataIndex: 'validation', key: 'validation', title: 'Validation', width: "100px", align: "center" },
    { dataIndex: 'action', key: 'action', title: '', width: "100px", align: "right" },
  ];

  let dataSource = [];
  if (trades.length) {
    if (trades.every(operation => operation.isQuoteCurrencyNative)) {
      columns = columns.filter(column => column.dataIndex !== 'quoteTokenBalance');
    }

    dataSource = trades.map(operation => {
      const tradeTransaction = operation.trade.tradeTransaction;
      const item = {
        key: operation.id,
        trade: operation.trade.position,
        wallet: (
          <List.Item.Meta
            title={tradeTransaction && tradeTransaction.address
              ? ethers.utils.getAddress(tradeTransaction.address)
              : 'None'}
            description={
              <>
                <div><b>Trade:</b> {operation.trade.quoteTokenAmountFrom} Quote token {"->"} {operation.trade.baseTokenAmountTo} Base token</div>
              </>
            }
          />
        ),
        nativeCurrencyBalance: (
          <>
            <div>Required: {operation.required.nativeCurrency}</div>
            <div>Actual: {
              operation.validation &&
                operation.validation.validationDetails &&
                operation.validation.validationDetails.chainDataValidation &&
                operation.validation.validationDetails.chainDataValidation.nativeCurrencyBalanceCheck
                ? +operation.validation.validationDetails.chainDataValidation.nativeCurrencyBalanceCheck.actual.toFixed(8)
                : 0
            }
            </div>
          </>
        ),
        validation: getStatusIcon(operation.validation.validationStatus),
        action: <Button
          key={2}
          size={"small"}
          type={"primary"}
          loading={loading}
          disabled={loading}
          onClick={() => history.push(`/profiles/${profile.uuid}/multipleWallets/${operation.id}`)}
        >
          Manage
        </Button>
      }

      if (!operation.isQuoteCurrencyNative) {
        item.quoteTokenBalance = (
          <>
            <div>Required: {operation.required.quoteCurrency}</div>
            <div>Actual: {
              operation.validation &&
                operation.validation.validationDetails &&
                operation.validation.validationDetails.chainDataValidation &&
                operation.validation.validationDetails.chainDataValidation.quoteTokenBalanceCheck
                ? +operation.validation.validationDetails.chainDataValidation.quoteTokenBalanceCheck.actual.toFixed(8)
                : 0
            }
            </div>
          </>
        );
      }

      return item;
    })
  }

  return (
    <div className={"trades-table"}>
      {
        !!errorMessage &&
        <Alert message={errorMessage} type="error" showIcon />
      }

      <Table
        bordered={false}
        pagination={false}
        loading={loading}
        size={"small"}
        columns={columns}
        dataSource={dataSource}
        tableLayout={"fixed"}
      />
    </div>
  );
});

export default Wallets;