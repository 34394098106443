import React from 'react';
import { Table, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import './ProfileWalletTransactionValidation.less';
import { getStatusIcon } from '../../../../../../services/utils';

const columns = [
  {
    key: '1',
    dataIndex: '1',
  },
  {
    dataIndex: '2',
    key: '2',
    align: "left"
  },
];

const ProfileWalletTransactionValidation = ({ title, validation }) => {
  const validationInfo = (validation) => {
    if (!validation || !validation.hasOwnProperty('isValid')) {
      return (
        <div className={"validation-status"}>
          {getStatusIcon(false)}
          <Tooltip placement="left" title={"no data"}>
            <QuestionCircleOutlined style={{ marginLeft: "3px" }} />
          </Tooltip>
        </div>
      )
    }

    let message = [];
    if (validation.hasOwnProperty('actual')) {
      message.push(<div key={1}>actual: {validation.actual}</div>)
    }
    if (validation.hasOwnProperty('expected')) {
      message.push(<div key={2}>expected: {validation.expected}</div>)
    }
    if (validation.hasOwnProperty('gasUsage')) {
      message.push(<div key={3}>gas usage: {validation.gasUsage}</div>)
    }

    return (
      <div className={"validation-status"}>
        {getStatusIcon(validation.hasOwnProperty('isValid') ? validation.isValid : false)}
        <Tooltip placement="left" title={message}>
          <QuestionCircleOutlined style={{ marginLeft: "3px" }} />
        </Tooltip>
      </div>
    )
  }

  let hasResult = !!validation && Object.keys(validation).length && validation.hasOwnProperty("validationDetails");

  let data = [
    {
      key: "title",
      "1": <h3>{title || "Validation"}</h3>,
      "2": title ? "" : <b style={{ fontSize: "20px" }}>{getStatusIcon(hasResult ? validation.validationStatus : false)}</b>
    },
    {
      key: "chain-data",
      "1": <b>Chain data</b>,
      colSpan: 2
    },
    {
      key: "nc-balance",
      "1": "Native currency balance",
      "2": validationInfo(hasResult ? validation.validationDetails.chainDataValidation.nativeCurrencyBalanceCheck : {})
    },
    {
      key: "qt-approval",
      "1": "Quote token approval",
      "2": validationInfo(hasResult ? validation.validationDetails.chainDataValidation.quoteTokenApprovalCheck : {})
    },
    {
      key: "qt-balance",
      "1": "Quote token balance",
      "2": validationInfo(hasResult ? validation.validationDetails.chainDataValidation.quoteTokenBalanceCheck : {})
    },
    {
      key: "contract-data",
      "1": <b>Contract data</b>,
      colSpan: 2
    },
    {
      key: "qt-addtrss",
      "1": "Quote token address",
      "2": validationInfo(hasResult ? validation.validationDetails.contractDataValidation.quoteCurrencyAddressCheck : {})
    },
    {
      key: "qt-amount",
      "1": "Quote token amount",
      "2": validationInfo(hasResult ? validation.validationDetails.contractDataValidation.quoteCurrencyAmountCheck : {})
    },
    {
      key: "bt-address",
      "1": "Base token address",
      "2": validationInfo(hasResult ? validation.validationDetails.contractDataValidation.baseCurrencyAddressCheck : {})
    },
    {
      key: "bt-min-amount",
      "1": "Base token min amount",
      "2": validationInfo(hasResult ? validation.validationDetails.contractDataValidation.baseCurrencyMinAmountCheck : {})
    },
    {
      key: "receiver",
      "1": "Receiver address",
      "2": validationInfo(hasResult ? validation.validationDetails.contractDataValidation.receiverAddressCheck : {})
    },
    {
      key: "deadline",
      "1": "Deadline",
      "2": validationInfo(hasResult ? validation.validationDetails.contractDataValidation.deadLineCheck : {})
    },
    {
      key: "transaction-data",
      "1": <b>Transaction data</b>,
      colSpan: 2
    },
    {
      key: "chain",
      "1": "Chain",
      "2": validationInfo(hasResult ? validation.validationDetails.transactionDataValidation.chainIdCheck : {})
    },
    {
      key: "gas-limit",
      "1": "Gas limit",
      "2": validationInfo(hasResult ? validation.validationDetails.transactionDataValidation.gasLimitCheck : {})
    },
    {
      key: "gas-price",
      "1": "Gas price",
      "2": validationInfo(hasResult ? validation.validationDetails.transactionDataValidation.gasPriceCheck : {})
    },
    {
      key: "nonce",
      "1": "Nonce",
      "2": validationInfo(hasResult ? validation.validationDetails.transactionDataValidation.nonceCheck : {})
    },
    {
      key: "router",
      "1": "Router address",
      "2": validationInfo(hasResult ? validation.validationDetails.transactionDataValidation.routerAddressCheck : {})
    },
  ];
  data = data.filter(Boolean)

  return (
    <div className={"profile-wallet-validation"}>
      <Table className={"profile-wallet-validation-table"} pagination={false} showHeader={false} size={"small"} columns={columns} dataSource={data} />
    </div>
  );
};

export default ProfileWalletTransactionValidation;