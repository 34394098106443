import React, { useState, useEffect } from 'react';
import { Button, Table, message } from 'antd';
import { observer } from 'mobx-react';
import { SyncOutlined } from '@ant-design/icons';
import { useStores } from '../../../../../../store/RootStore';
import { getStatusIcon } from '../../../../../../services/utils';
import './ExecutorBatches.less';
import { gasFillingAsync } from '../../../../../../services/apiRequests';

const columns = [
  { dataIndex: 'batch', key: 'batch', title: 'Batches', width: "300px" },
  { dataIndex: 'allowance', key: 'allowance', title: 'Allowed', align: "center" },
  { dataIndex: 'balaceCheck', key: 'balaceCheck', title: 'Balance check', align: "center" },
  { dataIndex: 'txSign', key: 'txSign', title: 'Txs signed', align: "center" },
  { dataIndex: 'nonceCheck', key: 'nonceCheck', title: 'Nonce check', align: "center" },
  { dataIndex: 'gasPriceCheck', key: 'gasPriceCheck', title: 'Gas price check', align: "center" },
  { dataIndex: 'action', key: 'action', title: '', align: "center", width: "150px" },
];

const ExecutorBatches = observer(() => {
  const {
    ProfileStore: { profile, multiExecutorState, executorsState, updateExecutorsState },
    AuthStore: { accessToken }
  } = useStores();

  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    if (!!multiExecutorState && Object.keys(multiExecutorState.executors.batches).length === 1 && (!executorsState || Object.keys(executorsState).length === 0)) {
      await updateState(Object.keys(multiExecutorState.executors.batches)[0]);
    }
  })

  const updateState = async (batchId) => {
    setLoading(true);
    await updateExecutorsState(accessToken, profile.uuid, batchId);
    setLoading(false);
    return;
  }

  const gasFilling = async (batchId) => {
    if (
      loading
      || !executorsState[batchId]
      || !executorsState[batchId].allExecutorsHasEnoughBalanceCheck
      || executorsState[batchId].allExecutorsHasEnoughBalanceCheck.isValid
    ) {
      return false;
    }
    setLoading(true);
    let response = await gasFillingAsync(accessToken, profile.uuid, batchId);
    if (response.errorMessage) {
      message.error(response.errorMessage, 5);
    } else {
      message.success('Transaction sent successfully');
    }
    return setLoading(false);
  }

  let dataSource = [];
  if (!!multiExecutorState) {
    dataSource = Object.keys(multiExecutorState.executors.batches)
      .map((key, index) => {
        let batch = multiExecutorState.executors.batches[key];
        let allowance, balaceCheck, txSign, nonceCheck, gasPriceCheck;
        if (executorsState[key]) {
          allowance = executorsState[key].allExecutorsAreAllowedCheck.isValid;
          balaceCheck = executorsState[key].allExecutorsHasEnoughBalanceCheck.isValid;
          txSign = executorsState[key].allExecutorsSignedTransactionsCheck.isValid;
          nonceCheck = txSign && executorsState[key].allExecutorsSignedTransactionsNonceCheck.isValid;
          gasPriceCheck = txSign && executorsState[key].allExecutorsSignedTransactionsGasCheck.isValid;
        }

        return {
          key: Number(index) + 1,
          batch: <div style={{ margin: "0 10px" }}>{batch.from + 1}-{batch.to + 1}</div>,
          allowance: getStatusIcon(allowance),
          balaceCheck: getStatusIcon(balaceCheck),
          txSign: getStatusIcon(txSign),
          nonceCheck: getStatusIcon(nonceCheck),
          gasPriceCheck: getStatusIcon(gasPriceCheck),
          action: [
            <Button
              key={1}
              size={"small"}
              type={executorsState[key] ? "default" : "primary"}
              disabled={loading}
              style={{ marginRight: "5px" }}
              onClick={() => updateState(key)}
            >
              <SyncOutlined spin={loading} />
            </Button>,
            <Button
              key={2}
              size={"small"}
              type={"primary"}
              loading={loading}
              disabled={loading || balaceCheck === undefined || !!balaceCheck || multiExecutorState.multiExecutorOwner.balance < (multiExecutorState.requiredNativeCurrencyBalance.executorGasFilling * batch.size)}
              onClick={() => gasFilling(key)}
            >
              Gas filling
            </Button>
          ]
        }
      })
  }

  return (
    <div className={"executor-batches"}>
      <Table
        bordered={false}
        pagination={false}
        size={"small"}
        columns={columns}
        dataSource={dataSource}
        tableLayout={"fixed"}
      />
    </div>
  );
});

export default ExecutorBatches;