import React from 'react';
import { Table, Tooltip } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone, QuestionCircleOutlined } from '@ant-design/icons';
import './MultiExecutorValidation.less';

const columns = [
  {
    key: '1',
    dataIndex: '1',
  },
  {
    dataIndex: '2',
    key: '2',
    align: "left"
  },
  {
    dataIndex: '3',
    key: '3',
    align: "left"
  },
];

const MultiExecutorValidation = ({ title, validation }) => {
  const getStatusIcon = (status) => {
    if (!!status) {
      return <CheckCircleTwoTone twoToneColor="#52c41a" />
    } else {
      return <CloseCircleTwoTone twoToneColor="#eb2f96" />
    }
  }

  const validationInfo = (validation, type = null) => {
    if (!validation || !validation.hasOwnProperty('isValid')) {
      return (
        <div className={"validation-status"}>
          {getStatusIcon(false)}
          <Tooltip placement="left" title={"no data"}>
            <QuestionCircleOutlined style={{ marginLeft: "3px" }} />
          </Tooltip>
        </div>
      )
    }

    let message = [];
    if (validation.hasOwnProperty('actual')) {
      message.push(<div key={1}>actual: {String(validation.actual)}</div>)
    }
    if (validation.hasOwnProperty('expected')) {
      message.push(<div key={2}>expected: {String(validation.expected)}</div>)
    }
    if (validation.hasOwnProperty('gasUsage')) {
      message.push(<div key={3}>gas usage: {String(validation.gasUsage)}</div>)
    }

    if (type === 'gasCheck') {
      message = [
        <div key={1}>actual: {
          validation.actual.length == 0 
            ? validation.expected
            : validation.actual[0]
        }</div>,
        <div key={2}>expected: {String(validation.expected)}</div>
      ]
    }

    return (
      <div className={"validation-status"}>
        {getStatusIcon(validation.hasOwnProperty('isValid') ? validation.isValid : false)}
        <Tooltip placement="left" title={message}>
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    )
  }

  let hasResult = !!validation && Object.keys(validation).length;
  const data = [
    {
      key: "0",
      "1": <h3>{title || "Validation"}</h3>,
      "2": title ? "" : <b style={{ fontSize: "20px" }}>{getStatusIcon(hasResult ? validation.validationStatus : false)}</b>
    },
    {
      key: "1",
      "1": <b>Contract</b>,
      colSpan: 2
    },
    {
      key: "2",
      "1": "Base token",
      "2": validationInfo(hasResult ? validation.validationDetails.baseTokenCheck : {}),
    },
    {
      key: "3",
      "1": "Quote token",
      "2": validationInfo(hasResult ? validation.validationDetails.quoteTokenCheck : {})
    },
    {
      key: "4",
      "1": "Router contract",
      "2": validationInfo(hasResult ? validation.validationDetails.routerAddressCheck : {})
    },
    {
      key: "5",
      "1": "Sniper receiver",
      "2": validationInfo(hasResult ? validation.validationDetails.sniperReceiverCheck : {})
    },
    {
      key: "6",
      "1": "Operations",
      "2": validationInfo(hasResult ? validation.validationDetails.operationsLengthCheck : {})
    },
    {
      key: "7",
      "1": "Sniping trade amountIn",
      "2": validationInfo(hasResult ? validation.validationDetails.lastOperationAmountInCheck : {})
    },
    {
      key: "8",
      "1": "Sniping trade amountOut",
      "2": validationInfo(hasResult ? validation.validationDetails.lastOperationAmountOutCheck : {})
    },
    {
      key: "9",
      "1": "MultiExecutor balance",
      "2": validationInfo(hasResult ? validation.validationDetails.tradingBalanceCheck : {})
    }
  ]

  return (
    <div className={"multiexecutor-validation"}>
      <Table className={"multiexecutor-validation-table"} pagination={false} showHeader={false} size={"small"} columns={columns} dataSource={data} />
    </div>
  );
};

export default MultiExecutorValidation;