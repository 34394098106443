import React from 'react';
import { Statistic, Button, Modal, Empty, Space, message } from 'antd';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import './RunCountdown.less';

const { Countdown } = Statistic;

const hideAfterMs = 20 * 60 * 1000; // 20m

const RunCountdown = ({ launchTime }) => {
  const deadline = moment(launchTime);
  
  if(moment().utc().valueOf() - deadline.utc().valueOf() > hideAfterMs){
    return null;
  }

  return (
    <div className={"run-countdown"}>
        <div className={"rc-title"}>Time to launch</div>
        <Countdown className={"rc-countdown"} value={deadline} valueStyle={{ color: '#f75425' }} />
        <div className={"rc-utc-time"}>{deadline.utc().format("DD.MM.YYYY HH:mm")} UTC</div>
      <Space align="center">
      </Space>
    </div>
  );
};

export default RunCountdown;