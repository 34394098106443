import React from 'react';
import uniswapRouterAbi from "../abi/uniswapRouterAbi.json";
import traderJoeRouterAbi from "../abi/traderJoeRouterAbi.json";
import openTraidingAbi from "../abi/opTrAbi.json";
import { CheckCircleTwoTone, CloseCircleTwoTone, SyncOutlined } from '@ant-design/icons';

export const getStatusIcon = (status) => {
    if (status === true) {
        return <CheckCircleTwoTone twoToneColor="#52c41a" />
    } else if (status === false) {
        return <CloseCircleTwoTone twoToneColor="#eb2f96" />
    } else {
        return <SyncOutlined twoToneColor="#dddddd" />
    }
}

export const convertMevChains = (chainId) => {
    if (chainId === 100056 || chainId === 110056) {
        chainId = 56
    } else if (chainId === 100097) {
        chainId = 97
    } else if (chainId === 100137) {
        chainId = 137
    }

    return chainId;
}

export const getRouterAbi = (chainId, exchangeId) => {
    return (chainId === 43114 || chainId === 43113) && exchangeId === "traderJoe" // Network: Avalance, DEX: Trader Joe
        ? traderJoeRouterAbi
        : uniswapRouterAbi
}

export const getOpenTraidingAbi = () => {
  return openTraidingAbi
}