import React, { useState, useEffect } from "react";
import { Button, message } from 'antd';
import * as ethers from 'ethers';
import { Transaction } from "@ethereumjs/tx";
import Common from '@ethereumjs/common'
import { observer } from 'mobx-react';
import { useStores } from '../../store/RootStore';
import { convertMevChains, getOpenTraidingAbi } from "../../services/utils";
import './SignOpenTraidingTxByMetamask.less';

import ConnectMetamaskButton from '../ConnectMetamaskButton/ConnectMetamaskButton';

const SignOpenTraidingTxByMetamask = observer(({ calculation, currentNetwork, currentExchange, onSaveLpTransaction }) => {
  const { WalletStore } = useStores();

  useEffect(() => {
    return () => {
      WalletStore.setProvider(null);
      WalletStore.setWalletAddress(null);
    };
  }, []);

  const [loading, setLoading] = useState(false);

  const onSign = async (gasPrice = null) => {
    setLoading(true);

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      let contract = new ethers.Contract(calculation.baseToken, getOpenTraidingAbi(), provider);

      const nonce = await provider.getTransactionCount(WalletStore.walletAddress);

      let tx = await contract.populateTransaction[currentExchange.addLiquidityWithNativeCurrencyMethodName]();

      const common = Common.custom({ chainId: convertMevChains(currentNetwork.chainId) });

      let transaction = new Transaction({
        data: tx.data,
        to: tx.to,
        chainId: ethers.BigNumber.from(convertMevChains(currentNetwork.chainId)).toHexString(),
        value: ethers.BigNumber.from(0).toHexString(),
        gasPrice: ethers.utils.parseUnits(String(gasPrice || calculation.liquidityAddGasPrice), "gwei").toHexString(),
        gasLimit: ethers.BigNumber.from(currentExchange.liquidityAddOperationGasLimit).toHexString(),
        nonce: ethers.BigNumber.from(nonce).toHexString()
      }, { common });

      let unsignedTx = transaction.getMessageToSign();
      let signature = await window.ethereum.request({
        method: 'eth_sign',
        params: [
          WalletStore.walletAddress,
          ethers.utils.hexlify(unsignedTx)
        ]
      });

      let signatureParts = ethers.utils.splitSignature(signature);

      let txWithSignature = transaction._processSignature(
        signatureParts.v,
        ethers.utils.arrayify(signatureParts.r),
        ethers.utils.arrayify(signatureParts.s)
      );

      let serializedTxWithSignature = ethers.utils.hexlify(txWithSignature.serialize());
      if (!!gasPrice) {
        await onSaveLpTransaction({
          lowGasHexTransaction: serializedTxWithSignature
        });
      } else {
        await onSaveLpTransaction({
          hexTransaction: serializedTxWithSignature
        });
      }
    } catch (e) {
      message.error(e.message)
    }

    setLoading(false)
  }

  return (
    <div className={"sign-by-metamask"}>
      <ConnectMetamaskButton currentNetwork={currentNetwork} />
      {
        !!WalletStore.walletAddress &&
        <div className={"buttons"}>
          <Button
            loading={loading}
            disabled={loading}
            type="primary"
            size={"large"}
            onClick={() => onSign()}
          >
            Sign
          </Button>
        </div>
      }
    </div>
  )
});

export default SignOpenTraidingTxByMetamask;