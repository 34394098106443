import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import { Modal, Menu, message } from 'antd';
import { ExclamationCircleOutlined, SettingOutlined, WarningOutlined } from '@ant-design/icons';
import LogoImg from '../../images/logo.svg';
import { observer } from 'mobx-react';
import { useStores } from '../../store/RootStore';
import { pingServicesAsync, restartBroadcastServiceAsync } from '../../services/apiRequests';
import './NavBar.less';

import LoginButton from '../LoginButton/LoginButton';
import LogoutButton from '../LogoutButton/LogoutButton';

const { SubMenu } = Menu;

const NavBar = observer(() => {
  const {
    AuthStore: { accessToken }
  } = useStores();

  const history = useHistory();
  const location = useLocation();
  const { isAuthenticated } = useAuth0();

  const [serviceStatus, setServiceStatus] = useState(false);

  const refreshServiceStatus = async () => {
    try {
      let ping = await pingServicesAsync();
      setServiceStatus(ping);
    } catch (e) {
      setServiceStatus({});
    }
  }

  useEffect(() => {
    refreshServiceStatus();
    const timerId = setInterval(refreshServiceStatus, 15000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  const handleClick = e => {
    history.push('/' + e.key);
  };

  const activeTab = () => {
    let path = location.pathname.split("/");
    path = path.filter((item) => item !== '');
    return path[0];
  }

  const showModal = () => {
    Modal.confirm({
      title: 'Are you sure to restart broadcast service?',
      icon: <ExclamationCircleOutlined />,
      content: 'Do this no more than once every 10 minutes',
      okText: 'Yes',
      cancelText: 'No',
      onOk: restartBroadcastService
    });
  }

  const restartBroadcastService = async () => {
    let result = await restartBroadcastServiceAsync(accessToken);
    if (!!result && result.status === "success") {
      message.success("Service restarted successfully");
      await refreshServiceStatus();
      return true
    }

    if (!!result.errorMessage) {
      message.error(result.errorMessage);
    } else {
      message.error("Broadcast service restart error");
    }

    await refreshServiceStatus();
    return false
  }

  return (
    <div key={"path-" + location.pathname} className={"main-nav"}>
      <div className={"auth-nav"}>
        <Menu key="right-menu" theme="light" mode="horizontal">
          <Menu.Item key="auth">
            {isAuthenticated ? <LogoutButton /> : <LoginButton />}
          </Menu.Item>
          <SubMenu key="config" title={<>{serviceStatus === false || (!!serviceStatus.api && !!serviceStatus.broadcast) ? "" : <WarningOutlined style={{ color: '#faad14', fontSize: "18px", verticalAlign: "sub" }} />}<SettingOutlined /></>}>
            <Menu.Item key="config-1" style={{ textAlign: "right" }} disabled>API service: {!!serviceStatus && serviceStatus.api ? <b style={{ color: "green", opacity: 0.6 }}>online</b> : <b style={{ color: "red", opacity: 0.6 }}>offline</b>}</Menu.Item>
            <Menu.Item key="config-2" style={{ textAlign: "right" }} disabled>Broadcast service: {!!serviceStatus && serviceStatus.broadcast ? <b style={{ color: "green", opacity: 0.6 }}>online</b> : <b style={{ color: "red", opacity: 0.6 }}>offline</b>}</Menu.Item>
            {
              isAuthenticated ?
                <Menu.Item key="config-3" style={{ textAlign: "right" }} onClick={showModal}>Reboot Broadcast Service</Menu.Item>
                : null
            }
          </SubMenu>
        </Menu>
      </div>
      <LogoImg className={"logo"} />
      <Menu onClick={handleClick} theme="light" mode="horizontal" defaultSelectedKeys={[activeTab()]}>
        <Menu.Item key="calculation">
          Calculation
        </Menu.Item>
        <Menu.Item key="signLp">
          Sign LP
        </Menu.Item>
        <Menu.Item key="profiles" disabled={!isAuthenticated}>
          Profiles
        </Menu.Item>
      </Menu>
    </div>
  );
});

export default NavBar;