import React, { useState, useEffect } from 'react';
import { Drawer, Table, Form, Switch, Space, Button } from 'antd';
import { LoadingOutlined, CheckCircleTwoTone, CloseCircleTwoTone, ReloadOutlined } from '@ant-design/icons';
import { getTransactionsAsync } from "../../../../services/apiRequests";
import './ProfileTransactions.less';

const ProfileTransactions = ({ profile, accessToken, currentNetwork, visible, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState();
  const [showCleaningTxs, setShowCleaningTxs] = useState(false);
  const [showGasFillingTxs, setShowGasFillingTxs] = useState(false);
  const [showFailedTxs, setShowFailedTxs] = useState(false);

  useEffect(async () => {
    if (loading || !!transactions || transactions === false || !accessToken) {
      return;
    }
    await updateTransactions();
    return;
  });

  const updateTransactions = async () => {
    setLoading(true);
    let response = await getTransactionsAsync(accessToken || null, profile.uuid);
    if (response.errorMessage) {
      // setErrorMessage(response.errorMessage);
      setTransactions(false);
      return;
    }
    setTransactions(response);
    setLoading(false);
    return;
  }

  const getStatusIcon = (tx) => {
    if (tx.status == 1) {
      return <CheckCircleTwoTone twoToneColor="#52c41a" />
    } else if ((tx.status === null || tx.status === undefined) && !tx.receipt) {
      return <LoadingOutlined />
    } else {
      return <CloseCircleTwoTone twoToneColor="#eb2f96" />
    }
  }

  const columns = [
    {
      dataIndex: '1',
      key: '1',
      width: "200px"
    },
    {
      dataIndex: '2',
      key: '2'
    },
    {
      dataIndex: '3',
      key: '3'
    }
  ];

  let transactionsData = !transactions
    ? []
    : transactions
      .filter((tx) => {
        if (!showGasFillingTxs && tx.type === 'gasFilling') {
          return false;
        }
        if (!showCleaningTxs && tx.type === 'cleaningTransaction') {
          return false;
        }
        if (!showFailedTxs && tx.status !== 1 && tx.status !== null && tx.status !== undefined) {
          return false;
        }
        return true;
      })
      .map((tx, index) => {
        return {
          key: index,
          "1": tx.type,
          "2": <a href={`${currentNetwork.explorer}tx/${tx.hash}`} target={"_blank"}>{tx.hash.substring(0, 24)}...</a>,
          "3": getStatusIcon(tx),
        }
      });

  return (
    <Drawer
      className={"profile-txs"}
      title="Transactions"
      placement={"right"}
      closable={false}
      onClose={onClose}
      visible={visible}
      width={"650px"}
      extra={
        <Space>
          <Button disabled={loading} onClick={updateTransactions} type={"default"}><ReloadOutlined /></Button>
        </Space>
      }
    >
      <Form
        layout="inline"
        className="profile-txs-control-bar"
        style={{ marginBottom: 16 }}
      >
        <Form.Item label="Show gas filling txs">
          <Switch checked={showGasFillingTxs} onChange={() => setShowGasFillingTxs(!showGasFillingTxs)} />
        </Form.Item>
        <Form.Item label="Show cleaning txs">
          <Switch checked={showCleaningTxs} onChange={() => setShowCleaningTxs(!showCleaningTxs)} />
        </Form.Item>
        <Form.Item label="Show failed txs">
          <Switch checked={showFailedTxs} onChange={() => setShowFailedTxs(!showFailedTxs)} />
        </Form.Item>
      </Form>
      <Table
        className={"profile-txs-table"}
        pagination={false}
        size={"small"}
        showHeader={false}
        columns={columns}
        dataSource={transactionsData}
        loading={loading}
      />
    </Drawer>
  );
};

export default ProfileTransactions;