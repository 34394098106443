import React from 'react';
import { Table } from 'antd';
import './ProfileOpenTradingTxInfo.less';

const ProfileOpenTradingTxInfo = ({ parsedTransaction, currentNetwork }) => {
  const columns = [
    {
      key: '1',
      dataIndex: '1',
    },
    {
      dataIndex: '2',
      key: '2',
    },
  ];

  let data = []
  if (parsedTransaction) {
    const parsedResult = typeof parsedTransaction === "string" ? JSON.parse(parsedTransaction) : parsedTransaction;
    data = [
      {
        key: '0',
        "1": "From",
        "2": <><b><a href={`${currentNetwork.explorer}address/${parsedResult.transactionData.from}`} target={"_blank"}>{parsedResult.transactionData.from}</a></b></>
      },
      {
        key: '1',
        "1": "To",
        "2": <><b><a href={`${currentNetwork.explorer}address/${parsedResult.transactionData.to}`} target={"_blank"}>{parsedResult.transactionData.to}</a></b></>
      },
      {
        key: '2',
        "1": "Method",
        "2": <><b>{parsedResult.contractData.method}</b></>
      },
      {
        key: '4',
        "1": "Gas price",
        "2": <><b>{parsedResult.transactionData.gasPriceGwei} gwei</b></>
      },
      {
        key: '5',
        "1": "Gas limit",
        "2": <><b>{parsedResult.transactionData.gasLimit}</b></>
      },
      {
        key: '6',
        "1": "Nonce",
        "2": <><b>{parsedResult.transactionData.nonce}</b></>
      },
    ]
  }

  return (
    <div className={"profile-lp-tx-info"}>
      <Table className={"profile-lp-tx-info-table"} pagination={false} showHeader={false} size={"small"} columns={columns} dataSource={data} />
    </div>
  );
};

export default ProfileOpenTradingTxInfo;