import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Alert, Button, Table, Row, Col, Popconfirm, message } from 'antd';
import { CopyOutlined, SyncOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useStores } from '../../../../store/RootStore';
import { deployAsync, initializeAsync, allowAsync, signTxsAsync, cleaningAsync, getTransactionsAsync } from '../../../../services/apiRequests';
import { getStatusIcon } from '../../../../services/utils';
import ExecutorBatches from './components/ExecutorBatches/ExecutorBatches';
import './MultiExecutor.less';

const columns = [
  { dataIndex: '1', key: '1', width: "300px" },
  { dataIndex: '2', key: '2' },
];

const validationColumns = [
  { dataIndex: '1', key: '1', width: "180px" },
  { dataIndex: '2', key: '2', width: "40px", align: "center" },
];

const MultiExecutor = observer(({ loading, setLoading }) => {
  const {
    ProfileStore,
    AuthStore: { accessToken },
    AppStore: { networks }
  } = useStores();
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState(null);

  const { profile, multiExecutorState, executorsState } = ProfileStore;
  const currentNetwork = networks ? networks.find(network => network.chainId === profile.calculation.chainId) : undefined;

  useEffect(async () => {
    if (!multiExecutorState) {
      setLoading(true);
      await ProfileStore.updateMultiExecutorState(accessToken, profile.uuid);
      setLoading(false);
    }
  }, []);


  const updateMultiExecutorState = async () => {
    setLoading(true);
    await getTransactionsAsync(accessToken, profile.uuid);
    await ProfileStore.updateProfile(accessToken, profile.uuid);
    await ProfileStore.updateMultiExecutorState(accessToken, profile.uuid);
    setLoading(false);
    return;
  }

  const deploy = async () => {
    setErrorMessage();
    if (loading || !multiExecutorState || !!multiExecutorState.multiExecutor.address || multiExecutorState.multiExecutorOwner.balance < multiExecutorState.requiredNativeCurrencyBalance.deploy) {
      return false;
    }

    setLoading(true);
    let response = await deployAsync(accessToken, profile.uuid);
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      message.success('Transaction sent successfully');
    }
    return setLoading(false);
  }

  const signTxs = async () => {
    setErrorMessage();
    if (loading || !multiExecutorState || !multiExecutorState.multiExecutor.address) {
      return false;
    }

    setLoading(true);
    let response = await signTxsAsync(accessToken, profile.uuid);
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      message.success('Signed');
      await ProfileStore.updateProfile(accessToken, profile.uuid);
    }
    return setLoading(false);
  }

  const сlear = async () => {
    setLoading(true);
    let response = await cleaningAsync(accessToken || null, profile.uuid);
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      if (response.transactions.length > 0) {
        message.success('Transactions sent successfully');
      } else {
        message.warning('Nothing to send');
      }
    }
    return setLoading(false);
  }

  const multiExecutorIsDeployed = !!multiExecutorState && !!multiExecutorState.multiExecutor.address;
  const multiExecutorIsInitialized = multiExecutorIsDeployed
    && !!multiExecutorState.multiExecutor
    && !!multiExecutorState.multiExecutor.validationDetails
    && Object.keys(multiExecutorState.multiExecutor.validationDetails).length > 0
    && multiExecutorState.multiExecutor.validationDetails.baseTokenCheck.isValid
    && multiExecutorState.multiExecutor.validationDetails.quoteTokenCheck.isValid
    && multiExecutorState.multiExecutor.validationDetails.sniperReceiverCheck.isValid
    && multiExecutorState.multiExecutor.validationDetails.routerAddressCheck.isValid
    && multiExecutorState.multiExecutor.validationDetails.operationsLengthCheck.isValid
    && multiExecutorState.multiExecutor.validationDetails.lastOperationAmountInCheck.isValid
    && multiExecutorState.multiExecutor.validationDetails.lastOperationAmountOutCheck.isValid
    && multiExecutorState.multiExecutor.validationDetails.counterCheck.isValid;

  const allBatchesAreValidated = !!multiExecutorState
    && !!multiExecutorState.executors
    && !!multiExecutorState.executors.batches
    && Object.keys(multiExecutorState.executors.batches).length > 0
    && !!executorsState
    && Object.keys(executorsState).length > 0
    && Object.keys(multiExecutorState.executors.batches).length === Object.keys(executorsState).length;

  const allExecutorsHasEnoughBalance = !!allBatchesAreValidated
    && Object.keys(executorsState)
      .map(batchId => executorsState[batchId].allExecutorsHasEnoughBalanceCheck.isValid)
      .every(status => status);

  const allExecutorsAreAllowed = !!allBatchesAreValidated
    && Object.keys(executorsState)
      .map(batchId => executorsState[batchId].allExecutorsAreAllowedCheck.isValid)
      .every(status => status);

  const allExecutorsHasSignedTransactions = !!allBatchesAreValidated
    && Object.keys(executorsState)
      .map(batchId => executorsState[batchId].allExecutorsSignedTransactionsCheck.isValid)
      .every(status => status);

  const allExecutorsHasValidNonce = !!allBatchesAreValidated
    && Object.keys(executorsState)
      .map(batchId => executorsState[batchId].allExecutorsSignedTransactionsNonceCheck.isValid)
      .every(status => status);

  let multiExecutorOwnerRequiredBalance = !multiExecutorState ? 0 : Object.keys(multiExecutorState.requiredNativeCurrencyBalance)
    .map((key) => {
      if (key === 'executorGasFilling') {
        let count = multiExecutorState.executors.count;
        if (!!executorsState) {
          for (let batchId in executorsState) {
            if (executorsState[batchId].allExecutorsHasEnoughBalanceCheck.isValid) {
              count -= executorsState[batchId].executors.length;
            }
          }
        }
        return count > 0 ? multiExecutorState.requiredNativeCurrencyBalance[key] * count : 0;
      } else if ((key === 'executorsInitializing' && !!multiExecutorState.multiExecutor.address)) {
        if (!allExecutorsAreAllowed) {
          return multiExecutorState.requiredNativeCurrencyBalance[key];
        }
        return 0;
      } else if (
        (key === 'deploy' && !!multiExecutorState.multiExecutor.address)
        || (key === 'stateInitializing' && multiExecutorIsInitialized)
      ) {
        return 0;
      }
      return multiExecutorState.requiredNativeCurrencyBalance[key];
    })
    .reduce((acc, value) => { return (acc + value) }, 0);

  const multiExecutorOwnerHasEnoughBalance = !!multiExecutorState && multiExecutorState.multiExecutorOwner.balance > multiExecutorOwnerRequiredBalance;
  const multiExecutorHasEnoughBalance = multiExecutorIsDeployed && multiExecutorState.multiExecutor.quoteTokenBalance > 0 && multiExecutorState.multiExecutor.quoteTokenBalance >= multiExecutorState.multiExecutor.quoteTokenRequiredBalance;

  const validationStatus = multiExecutorOwnerHasEnoughBalance
    && multiExecutorIsDeployed
    && multiExecutorHasEnoughBalance
    && multiExecutorIsInitialized
    && allBatchesAreValidated
    && allExecutorsAreAllowed
    && allExecutorsHasEnoughBalance
    && allExecutorsHasSignedTransactions
    && allExecutorsHasValidNonce;

  const initialize = async () => {
    setErrorMessage();
    if (loading || !multiExecutorIsDeployed || multiExecutorIsInitialized || multiExecutorState.multiExecutorOwner.balance < multiExecutorState.requiredNativeCurrencyBalance.stateInitializing) {
      return false;
    }

    setLoading(true);
    let response = await initializeAsync(accessToken || null, profile.uuid);
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      message.success('Transaction sent successfully');
    }

    return setLoading(false);
  }

  const allow = async () => {
    setErrorMessage();
    if (loading || !multiExecutorIsDeployed || !allBatchesAreValidated || allExecutorsAreAllowed || multiExecutorState.multiExecutorOwner.balance < multiExecutorState.requiredNativeCurrencyBalance.executorsInitializing) {
      return false;
    }

    setLoading(true);
    let response = await allowAsync(accessToken || null, profile.uuid);
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      message.success('Transaction sent successfully');
    }
    return setLoading(false);
  }

  return (
    <div className="multi-executor">
      {
        !!errorMessage &&
        <Alert message={errorMessage} type="error" showIcon />
      }
      <Row gutter={8}>
        <Col span={18}>
          <Table className={"info-table"} bordered={false} pagination={false} size={"small"} showHeader={false} columns={columns} dataSource={[
            { key: "0", "1": <h3>MultiExecutor owner</h3>, "2": <></> },
            {
              key: "1", "1": "Address", "2": <>
                {
                  !!multiExecutorState && multiExecutorState.multiExecutorOwner && multiExecutorState.multiExecutorOwner.address
                    ? <>
                      <b><a href={`${currentNetwork.explorer}address/${multiExecutorState.multiExecutorOwner.address}`} target={"_blank"}>{multiExecutorState.multiExecutorOwner.address}</a></b>
                      <CopyToClipboard text={multiExecutorState.multiExecutorOwner.address} onCopy={() => message.success("copied")}><CopyOutlined /></CopyToClipboard>
                    </>
                    : <b>NONE</b>
                }
              </>
            },
            {
              key: "2", "1": "Balance", "2": <>
                {
                  !!multiExecutorState && multiExecutorState.multiExecutorOwner && multiExecutorState.multiExecutorOwner.balance
                    ? <b>{multiExecutorState.multiExecutorOwner.balance} {currentNetwork.networkCurrency.symbol}</b>
                    : <b>0 {currentNetwork.networkCurrency.symbol}</b>
                }
              </>
            },
            {
              key: "3", "1": "Required balance (min)", "2": <>
                <b>{multiExecutorOwnerRequiredBalance > 0 && !allBatchesAreValidated ? "~ " : ""}{multiExecutorOwnerRequiredBalance} {currentNetwork.networkCurrency.symbol}</b>
              </>
            },
          ]} />
        </Col>
        <Col span={6}>
          <Table className={"validation-table"} bordered={false} pagination={false} size={"small"} showHeader={false} columns={validationColumns} dataSource={[
            {
              key: "0",
              "1": <h3>Validation</h3>,
              "2": <b style={{ fontSize: "20px" }}>{getStatusIcon(!allBatchesAreValidated ? null : validationStatus)}</b>
            },
            {
              key: "1",
              "1": "Has enough balance",
              "2": <b>{getStatusIcon(!allBatchesAreValidated || !multiExecutorState.multiExecutorOwner ? null : multiExecutorOwnerHasEnoughBalance)}</b>
            },
          ]} />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={18}>
          <Table className={"info-table"} bordered={false} pagination={false} size={"small"} showHeader={false} columns={columns} dataSource={[
            {
              key: "0", "1": <h3>MultiExecutor contract</h3>, "2":
                <>
                  <Button
                    size={"small"}
                    type={"primary"}
                    loading={loading}
                    disabled={loading || !multiExecutorState || multiExecutorIsDeployed || multiExecutorState.multiExecutorOwner.balance < multiExecutorState.requiredNativeCurrencyBalance.deploy}
                    onClick={deploy}
                  >
                    {multiExecutorIsDeployed ? "Deployed" : "Deploy"}
                  </Button>
                  <Button
                    size={"small"}
                    type={"primary"}
                    loading={loading}
                    disabled={loading || !multiExecutorIsDeployed || multiExecutorIsInitialized || multiExecutorState.multiExecutorOwner.balance < multiExecutorState.requiredNativeCurrencyBalance.stateInitializing}
                    onClick={initialize}
                  >
                    {multiExecutorIsInitialized ? "Initialized" : "Initialize"}
                  </Button>
                </>
            },
            {
              key: "1", "1": "Address", "2": <>
                {
                  multiExecutorIsDeployed
                    ? <>
                      <b><a href={`${currentNetwork.explorer}address/${multiExecutorState.multiExecutor.address}`} target={"_blank"}>{multiExecutorState.multiExecutor.address}</a></b>
                      <CopyToClipboard text={multiExecutorState.multiExecutor.address} onCopy={() => message.success("copied")}><CopyOutlined /></CopyToClipboard>
                    </>
                    : <b>NONE</b>
                }
              </>
            },
            {
              key: "2",
              "1": "Quote token balance",
              "2": <b>{multiExecutorIsDeployed ? multiExecutorState.multiExecutor.quoteTokenBalance : 0}</b>
            },
            {
              key: "3",
              "1": "Required quote token balance",
              "2": <b>{multiExecutorIsDeployed ? multiExecutorState.multiExecutor.quoteTokenRequiredBalance : 0}</b>
            },
          ]} />
        </Col>
        <Col span={6}>
          <Table className={"validation-table"} bordered={false} pagination={false} size={"small"} showHeader={false} columns={validationColumns} dataSource={[
            {
              key: "1",
              "1": "Contract deployed",
              "2": <b>{getStatusIcon(multiExecutorIsDeployed)}</b>
            },
            {
              key: "2",
              "1": "Has enough balance",
              "2": <b>{getStatusIcon(multiExecutorHasEnoughBalance)}</b>
            },
            {
              key: "3",
              "1": "Initialized",
              "2": <b>{getStatusIcon(multiExecutorIsInitialized)}</b>
            }
          ]} />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={24}>
          <Table className={"info-table"} bordered={false} pagination={false} size={"small"} showHeader={false} columns={columns} dataSource={[
            {
              key: "0", "1": <h3>Executors</h3>, "2":
                <>
                  <Button
                    size={"small"}
                    type={"primary"}
                    loading={loading}
                    disabled={loading || !multiExecutorState || !allBatchesAreValidated || allExecutorsAreAllowed || !multiExecutorState.multiExecutor.address || multiExecutorState.multiExecutorOwner.balance < multiExecutorState.requiredNativeCurrencyBalance.executorsInitializing}
                    onClick={allow}
                  >
                    {allExecutorsAreAllowed ? "Allowed" : "Allow"}
                  </Button>
                  <Button
                    size={"small"}
                    type={"primary"}
                    loading={loading}
                    disabled={loading || !multiExecutorState || !multiExecutorState.multiExecutor.address}
                    onClick={signTxs}
                  >
                    {profile.profileExecutors.filter(ex => ex.isMultiExecutorOwner === false).every(ex => !!ex.transactions) ? "Resign TXs" : "Sign TXs"}
                  </Button>
                </>
            },
            {
              key: "1",
              "1": "Count",
              "2": <b>{!!multiExecutorState ? multiExecutorState.executors.count : 0}</b>
            },
          ]} />
          <ExecutorBatches />
        </Col>
      </Row>

      <div className={"btn-group"}>
        <Popconfirm
          title={<>Are you sure to clear all addresses?<br />All coins will be sent to the sniper receiver</>}
          onConfirm={сlear}
          okText="Yes"
          cancelText="No"
          disabled={loading}
        >
          <Button type={"link"} loading={loading}>Сlear all</Button>
        </Popconfirm>
        <Button type={"default"} onClick={() => history.push(`/profiles/${profile.uuid}`)}>Back</Button>
        <Button type={"default"} onClick={() => updateMultiExecutorState()}><SyncOutlined spin={loading} />Validate</Button>
        <Button type={"primary"} onClick={() => history.push(`/profiles/${profile.uuid}/lpTransaction`)}>Continue</Button>
      </div>
    </div>
  );
});

export default MultiExecutor;