import React, { useState } from 'react';
import { Alert, Modal, Form, Input, Spin } from 'antd';
import { useHistory } from "react-router-dom";
import { createProfileAsync } from '../../../../services/apiRequests';
import './CreateProfileModal.less';
import { ethers } from 'ethers';

const CreateProfileModal = ({ accessToken, visible, calculationId, mode, exchangeId, onCancel }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  if (!calculationId) {
    return null;
  }

  const onCreate = async (values) => {
    setLoading(true);
    setErrorMessage(null);
    values.calculationId = calculationId;
    try {
      let result = await createProfileAsync(accessToken, values);
      if (!result) {
        setLoading(false);
        return setErrorMessage("Internal server error")
      }
      if (result.errorMessage) {
        setLoading(false);
        return setErrorMessage(result.errorMessage)
      }

      history.push("/profiles/" + result.uuid);

    } catch (e) {
      setErrorMessage(e.message);
    }
    setLoading(false);
  }

  return (
    <Modal
      visible={visible}
      title={"Create profile"}
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      okButtonProps={{ loading }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      {
        !!errorMessage &&
        <><Alert message={errorMessage} type="error" showIcon /><br /></>
      }
      <Spin spinning={loading}>
        <Form
          className={"create-profile-modal"}
          form={form}
          layout="vertical"
          name="create-profile-modal"
          fields={[
            {
              name: ['lpTokenReceiver'],
              value: exchangeId === "uniswapV2openTrading" ? ethers.constants.AddressZero : null,
            },
            {
              name: ['sniperReceiver'],
              value: mode === "multipleWallets" ? ethers.constants.AddressZero : null,
            },
          ]}
        >
          <Form.Item
            label={"Profile name"}
            name={"name"}
            rules={[
              {
                required: true,
                message: `Profile name is required!`,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={"LP token receiver address"}
            name={"lpTokenReceiver"}
            hidden={exchangeId === "uniswapV2openTrading"}
            rules={[
              {
                required: true,
                message: `required`,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            hidden={mode === "multipleWallets"}
            label={"Sniper receiver address"}
            name={"sniperReceiver"}
            rules={[
              {
                required: true,
                message: `required`,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </Modal >
  );
};

export default CreateProfileModal;