import React, { useState, useEffect } from 'react';
import { Button, Table, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import { QuestionCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { useStores } from '../../../../../../store/RootStore';
import { getStatusIcon } from '../../../../../../services/utils';
import './ExecutorBatchesValidation.less';

const columns = [
  {
    key: '1',
    dataIndex: '1',
  },
  {
    dataIndex: '2',
    key: '2',
    align: "left"
  },
  {
    dataIndex: '3',
    key: '3',
    align: "left"
  },
];

const ExecutorBatchesValidation = observer(() => {
  const {
    ProfileStore: { profile, multiExecutorState, executorsState, updateExecutorsState },
    AuthStore: { accessToken }
  } = useStores();

  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    if (!!multiExecutorState && Object.keys(multiExecutorState.executors.batches).length === 1 && (!executorsState || Object.keys(executorsState).length === 0)) {
      await updateState(Object.keys(multiExecutorState.executors.batches)[0]);
    }
  })

  const updateState = async (batchId) => {
    setLoading(true);
    await updateExecutorsState(accessToken, profile.uuid, batchId);
    setLoading(false);
    return;
  }

  const dataSource = [
    {
      key: 0,
      "1": <b>Executors</b>,
      colSpan: 3
    }
  ];
  if (!!multiExecutorState) {
    Object.keys(multiExecutorState.executors.batches)
      .forEach((key, index) => {
        let batch = multiExecutorState.executors.batches[key];
        let allowance, balaceCheck, txSign, nonceCheck, gasPriceCheck;
        if (executorsState[key]) {
          allowance = executorsState[key].allExecutorsAreAllowedCheck.isValid;
          balaceCheck = executorsState[key].allExecutorsHasEnoughBalanceCheck.isValid;
          txSign = executorsState[key].allExecutorsSignedTransactionsCheck.isValid;
          nonceCheck = txSign && executorsState[key].allExecutorsSignedTransactionsNonceCheck.isValid;
          gasPriceCheck = txSign && executorsState[key].allExecutorsSignedTransactionsGasCheck.isValid;
        }

        dataSource.push({
          key: Number(index) + 1,
          "1": <div>{batch.from + 1}-{batch.to + 1}</div>,
          "2": <div className={"validation-status"}>
            {
              !!executorsState[key] &&
              <>
                {getStatusIcon(allowance && balaceCheck && txSign && nonceCheck && gasPriceCheck)}
                <Tooltip
                  placement="left"
                  title={
                    <Table
                      className={"ebv-tooltio-table"}
                      showHeader={false}
                      bordered={false}
                      pagination={false}
                      size={"small"}
                      columns={[{
                        key: '1',
                        dataIndex: '1',
                      },
                      {
                        dataIndex: '2',
                        key: '2',
                      }]}
                      dataSource={[
                        {
                          key: "1",
                          "1": "Allowed:",
                          "2": allowance ? "+" : "-",
                        },
                        {
                          key: "2",
                          "1": "Has enough balance:",
                          "2": balaceCheck ? "+" : "-",
                        },
                        {
                          key: "3",
                          "1": "Txs signed:",
                          "2": txSign ? "+" : "-",
                        },
                        {
                          key: "4",
                          "1": "Nonce check:",
                          "2": nonceCheck ? "+" : "-",
                        },
                        {
                          key: "5",
                          "1": "Gas price check:",
                          "2": gasPriceCheck ? "+" : "-",
                        },
                      ]}
                    />
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }
          </div>,
          "3": [
            <Button
              key={1}
              size={"small"}
              type={!!executorsState && !!executorsState[key] ? "default" : "primary"}
              disabled={loading}
              onClick={() => updateState(key)}
            >
              <SyncOutlined spin={loading} />
            </Button>
          ]
        })
      })
  }

  return (
    <div className={"multiexecutor-validation"}>
      <Table
        className={"multiexecutor-validation-table"}
        showHeader={false}
        bordered={false}
        pagination={false}
        size={"small"}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  );
});

export default ExecutorBatchesValidation;