import React, { useState, useEffect } from 'react';
import { Switch, Route, Link, useParams, useHistory } from "react-router-dom";
import { Alert, Spin, PageHeader, Result, Descriptions, Button } from 'antd';
import { FileSearchOutlined, EditOutlined, DeleteTwoTone } from '@ant-design/icons';
import { updateProfileAsync } from '../../services/apiRequests';
import { observer } from 'mobx-react';
import { useStores } from '../../store/RootStore';
import './ProfileCard.less';

import ProfileTabs from './components/ProfileTabs/ProfileTabs';
import ProfileInfo from './components/ProfileInfo/ProfileInfo';
import MultiExecutor from './components/MultiExecutor/MultiExecutor';
import MultipleWallets from './components/MultipleWallets/MultipleWallets';
import ProfileLpTransaction from './components/ProfileLpTransaction/ProfileLpTransaction';
import ProfileRun from './components/ProfileRun/ProfileRun';
import ProfileEditModal from './components/ProfileEditModal/ProfileEditModal';
import ProfileTransactions from './components/ProfileTransactions/ProfileTransactions';
import RunCountdown from './components/RunCountdown/RunCountdown';
import ProfileWalletTransaction from './components/ProfileWalletTransaction/ProfileWalletTransaction';
import ProfileOpenTrading from './components/ProfileOpenTrading/ProfileOpenTrading';

const ProfileCard = observer(() => {
  const {
    ProfileStore,
    AppStore: { networks },
    AuthStore: { accessToken }
  } = useStores();

  const { profileId } = useParams();
  const [loading, setLoading] = useState(false);
  const [editParam, setEditParam] = useState();
  const [profileTxsModal, setProfileTxsModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const history = useHistory();

  useEffect(() => {
    updateProfile();
    return () => {
      ProfileStore.closeProfile();
    };
  }, []);

  const updateProfile = () => {
    return ProfileStore.updateProfile(accessToken, profileId);
  }

  const showProfileTxsModal = () => {
    setProfileTxsModal(true);
  }

  const onCloseProfileTxsModal = () => {
    setProfileTxsModal(false);
  }

  const { profile } = ProfileStore;

  if (!profile && !!accessToken) {
    return <div className="profile-card"><Spin className={"loader"} size="large"></Spin></div>
  }

  if (!profile || Object.keys(ProfileStore.profile).length === 0) {
    return <div className="profile-card">
      <Result
        icon={<FileSearchOutlined />}
        title="404"
        subTitle={<>Sorry, profile <b>{profileId}</b> not found</>}
        extra={<Button type="primary"><Link to={'/profiles'}>Profiles</Link></Button>}
      />
    </div>
  }

  const currentNetwork = networks ? networks.find(network => network.chainId === profile.calculation.chainId) : undefined;
  const currentExchange = !!currentNetwork ? currentNetwork.exchanges.find(exchange => exchange.exchangeId === profile.calculation.exchangeId) : undefined;

  const onShowEditModal = (param) => {
    setErrorMessage();
    setEditParam(param);
  }

  const onCancelEdit = () => {
    setEditParam();
  }

  const onEdit = async (values) => {
    if (Object.keys(values).length === 0) {
      return;
    }

    let response = await updateProfileAsync(accessToken, profileId, values);
    if (response.errorMessage) {
      return setErrorMessage(response.errorMessage);
    }

    return ProfileStore.setProfile(response);
  }

  return (
    <div className="profile-card">
      {
        !!errorMessage &&
        <Alert message={errorMessage} type="error" showIcon />
      }
      <PageHeader
        className="page-header"
        onBack={() => history.push('/profiles')}
        title={<>{profile.name} <EditOutlined onClick={() => onShowEditModal({ name: "name", title: "Profile name", value: profile.name })} /></>}
        extra={[
          <Button key="1" type="dashed" onClick={() => onShowEditModal({ name: "customRouter", title: "Custom router address", value: profile.customRouter })}>{!!profile.customRouter ? "Update" : "Add"} custom router for LP tx</Button>,
          <Button key="2" type="dashed" onClick={() => onShowEditModal({ name: "emergencyRpcUrl", title: "Emergency RPC url", value: profile.emergencyRpcUrl })}>{!!profile.emergencyRpcUrl ? "Update" : "Add"} emergency RPC url</Button>,
          <Button key="3" type="primary" onClick={showProfileTxsModal}>Transactions</Button>
        ]}
        footer={
          <ProfileTabs profileId={profileId} mode={profile.calculation.mode} exchangeId={profile.calculation.exchangeId} />
        }
      >
        <Descriptions size="small" column={4}>
          <Descriptions.Item span={2} label="ID"><b>{profileId}</b></Descriptions.Item>
          <Descriptions.Item label="Network"><b>{currentNetwork.networkName}</b> </Descriptions.Item>
          <Descriptions.Item label="Exchange"><b>{currentExchange.exchangeName}</b></Descriptions.Item>
          {
            profile.calculation.exchangeId !== "uniswapV2openTrading" &&
            <Descriptions.Item span={2} label="LP token receiver"><b><a href={`${currentNetwork.explorer}address/${profile.lpTokenReceiver}`} target={"_blank"}>{profile.lpTokenReceiver}</a></b> <EditOutlined onClick={() => onShowEditModal({ name: "lpTokenReceiver", title: "LP token receiver", value: profile.lpTokenReceiver })} /></Descriptions.Item>
          }
          {
            profile.calculation.mode === "multipleWallets" &&
            <Descriptions.Item span={2} label={'Mode'}><b>MultipleWallets</b></Descriptions.Item>
          }
          {
            profile.calculation.mode === "multiExecutor" &&
            <Descriptions.Item span={2} label={"Sniper receiver"}>
              <b>
                <a href={`${currentNetwork.explorer}address/${profile.sniperReceiver}`} target={"_blank"} rel="noreferrer">{profile.sniperReceiver}</a>
              </b>
              <EditOutlined
                onClick={() => onShowEditModal({
                  name: "sniperReceiver",
                  title: "Sniper receiver",
                  value: profile.sniperReceiver
                })}
              />
            </Descriptions.Item>
          }
          {
            !!profile.emergencyRpcUrl &&
            <Descriptions.Item className={"emergency-rpc-url"} span={4} label="Emergency RPC url is active"><b>{profile.emergencyRpcUrl}</b> <DeleteTwoTone twoToneColor="#f75425" onClick={() => onEdit({ "emergencyRpcUrl": null })} /></Descriptions.Item>
          }
          {
            !!profile.customRouter &&
            <Descriptions.Item className={"custom-router"} span={4} label="Custom router is active"><b>{profile.customRouter}</b> <DeleteTwoTone twoToneColor="#f75425" onClick={() => onEdit({ "customRouter": null })} /></Descriptions.Item>
          }
        </Descriptions>
        {
          !!profile.launchTime &&
          <RunCountdown launchTime={profile.launchTime} />
        }
      </PageHeader>

      <div className={"profile-card-body"}>
        <Spin spinning={loading}>
          <Switch>
            <Route path="/profiles/:profileId/run">
              <ProfileRun
                accessToken={accessToken}
                networks={networks}
                profile={profile}
                loading={loading}
                setLoading={setLoading}
                updateProfile={updateProfile}
              />
            </Route>
            <Route path="/profiles/:profileId/lpTransaction">
              {
                profile.calculation.exchangeId === "uniswapV2openTrading"
                  ? <ProfileOpenTrading
                  accessToken={accessToken}
                  profile={profile}
                  networks={networks}
                  loading={loading}
                  setLoading={setLoading}
                  updateProfile={updateProfile}
                />
                  : <ProfileLpTransaction
                    accessToken={accessToken}
                    profile={profile}
                    networks={networks}
                    loading={loading}
                    setLoading={setLoading}
                    updateProfile={updateProfile}
                  />
              }
            </Route>
            {
              profile.calculation.mode === "multiExecutor" &&
              <Route path="/profiles/:profileId/multiExecutor">
                <MultiExecutor
                  loading={loading}
                  setLoading={setLoading}
                />
              </Route>
            }
            {
              profile.calculation.mode === "multipleWallets" &&
              ([
                <Route path="/profiles/:profileId/multipleWallets/:tradeId">
                  <ProfileWalletTransaction
                    accessToken={accessToken}
                    profile={profile}
                    networks={networks}
                    loading={loading}
                    setLoading={setLoading}
                    updateProfile={updateProfile}
                  />
                </Route>,
                <Route path="/profiles/:profileId/multipleWallets">
                  <MultipleWallets
                    loading={loading}
                    setLoading={setLoading}
                  />
                </Route>
              ])
            }
            <Route path="/profiles/:profileId">
              <ProfileInfo
                profile={profile}
                networks={networks}
                onShowEditModal={onShowEditModal}
              />
            </Route>
          </Switch>
        </Spin>
      </div>
      {
        !!editParam &&
        <ProfileEditModal
          param={editParam}
          visible={!!editParam}
          onCancel={onCancelEdit}
          onCreate={(values) => { onEdit(values); onCancelEdit(); }}
        />
      }
      {
        !!profileTxsModal &&
        <ProfileTransactions
          profile={profile}
          accessToken={accessToken}
          currentNetwork={currentNetwork}
          visible={true}
          onClose={onCloseProfileTxsModal}
        />
      }
    </div>
  );
});

export default ProfileCard;