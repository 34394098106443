import { observable, action, makeObservable } from "mobx";

class AppStore {
    networks = null;

    constructor() {
        makeObservable(this, {
            networks: observable,

            getNetworks: action,
            setNetworks: action
        });
    };

    getNetworks = () => this.networks;
    setNetworks = (networks) => {
        this.networks = networks;
    };
};

export default new AppStore();
