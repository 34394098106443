import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Alert, Modal, Spin, Form, Result, Button, Row, Col, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FileSearchOutlined, CopyOutlined, SaveOutlined } from '@ant-design/icons';
import { getCalculationByIDAsync, saveOpTrTxAsync, validateOpTrTxAsync } from '../../services/apiRequests';
import { observer } from 'mobx-react';
import { useStores } from '../../store/RootStore';
import './SharedOpenTradingTransaction.less';

import CalculationResult from '../Calculation/components/CalculationResult/CalculationResult';
import ProfileOpenTradingValidation from '../ProfileCard/components/ProfileOpenTradingValidation/ProfileOpenTradingValidation';
import ProfileOpenTradingTxInfo from '../ProfileCard/components/ProfileOpenTradingTxInfo/ProfileOpenTradingTxInfo';
import SignOpenTraidingTxByMetamask from '../SignOpenTraidingTxByMetamask/SignOpenTraidingTxByMetamask';

const SharedOpenTradingTransaction = observer(() => {
  const { AppStore: { networks } } = useStores();
  const { calculationId } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hexTransaction, setHexTransaction] = useState();
  const [validation, setValidation] = useState();
  const [showModal, setShowModal] = useState(false);
  const [calculation, setCalculation] = useState(false);
  const [successMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(async () => {
    if (!!calculation) {
      return;
    }
    let response = await getCalculationByIDAsync(calculationId);
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
      setCalculation({});
      return;
    }
    return setCalculation(response);
  });

  if (!calculation && !!calculationId) {
    return <div className="profile-card"><Spin className={"loader"} size="large"></Spin></div>
  }

  if (!calculation || !calculation.profileId || Object.keys(calculation).length === 0) {
    return <div className="profile-card">
      <Result
        icon={<FileSearchOutlined />}
        title="404"
        subTitle={<>Sorry, calculation <b>{calculationId}</b> not found</>}
      />
    </div>
  }

  const currentNetwork = networks ? networks.find(network => network.chainId === calculation.chainId) : undefined;
  const currentExchange = !!currentNetwork ? currentNetwork.exchanges.find(exchange => exchange.exchangeId === calculation.exchangeId) : undefined;

  const validate = async (hexTransaction) => {
    let validation = await validateOpTrTxAsync(calculation.profileId, { hexTransaction });
    return setValidation(validation);
  }

  const onSave = async () => {
    setLoading(true);

    const result = await saveOpTrTxAsync(calculation.profileId, { hexTransaction });
    if (result && result.hasOwnProperty('parsedResult')) {
      message.success("Transaction saved successfully");
    } else {
      message.error("ERROR: Transaction not saved");
    }

    setShowModal(false);
    setLoading(false)
  }

  const onSaveLpTransaction = async (values) => {
    setHexTransaction(values.hexTransaction);
    await validate(values.hexTransaction)
    setShowModal(true);
  }

  return (
    <div className="profile-shared-lp-transaction">
      <h1>Sign OpenTrading transaction</h1>
      {
        !!errorMessage &&
        <><Alert message={errorMessage} type="error" showIcon /><br /></>
      }
      {
        !!successMessage &&
        <><Alert message={successMessage} type="success" /><br /></>
      }

      <CalculationResult
        calculation={calculation}
        networks={networks}
        hideSniperTxs={true}
      />
      <div>
        <Form
          form={form}
          layout="vertical"
        >
          <SignOpenTraidingTxByMetamask
            calculation={calculation}
            currentNetwork={currentNetwork}
            currentExchange={currentExchange}
            onSaveLpTransaction={onSaveLpTransaction}
          />
        </Form>
        <Modal
          className={"lp-modal"}
          style={{ top: 20 }}
          visible={showModal}
          width={"900px"}
          onCancel={() => setShowModal(false)}
          footer={[
            <Button
              key="back"
              onClick={() => setShowModal(false)}
              loading={loading}
            >
              Cancel
            </Button>,
            <CopyToClipboard key="copy" text={hexTransaction} onCopy={() => message.success("copied")}>
              <Button
                type="primary"
                loading={loading}
              >
                <CopyOutlined /> Copy HEX data
              </Button>
            </CopyToClipboard>,
            <Button
              key="save"
              type="primary"
              loading={loading}
              onClick={onSave}
            >
              <SaveOutlined /> Save to profile
            </Button>,
          ]}
        >
          <Row>
            <Col span={16}>
              <h3>OpenTrading transaction</h3>
              <ProfileOpenTradingTxInfo
                parsedTransaction={validation && validation.info ? validation.info.parsedResult : null}
                currentNetwork={currentNetwork}
              />
              <h3>HEX data</h3>
              <p style={{ fontSize: "10px" }}>{hexTransaction}</p>
            </Col>
            <Col span={8}>
              <ProfileOpenTradingValidation
                validation={validation}
              />
            </Col>
          </Row>
        </Modal>
      </div>
    </div>
  );
});

export default SharedOpenTradingTransaction;