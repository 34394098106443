import React, { useState } from 'react';
import { Alert, Modal, Form, Input, Spin } from 'antd';
import { createLowGasLpTxConfigAsync } from '../../../../services/apiRequests';
import './CreateLowGasLpConfigModal.less';

const CreateLowGasLpConfigModal = ({ accessToken, visible, profile, onCancel, onOk }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  if (!profile) {
    return null;
  }

  const onCreate = async (values) => {
    setLoading(true);
    setErrorMessage(null);
    try {
      let result = await createLowGasLpTxConfigAsync(accessToken, profile.uuid, values);
      if (!result) {
        setLoading(false);
        return setErrorMessage("Internal server error")
      }
      if (result.errorMessage) {
        setLoading(false);
        return setErrorMessage(result.errorMessage)
      }
      onOk();
    } catch (e) {
      if (e.message) {
        setErrorMessage(e.message)
      }
    }
    setLoading(false);
  }

  return (
    <Modal
      visible={visible}
      title={"Create low gas LP tx config"}
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      okButtonProps={{loading}}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      {
        !!errorMessage &&
        <><Alert message={errorMessage} type="error" showIcon /><br /></>
      }
      <Spin spinning={loading}>
        <Form
          className={"create-low-gas-lp-modal"}
          form={form}
          layout="vertical"
          name="create-low-gas-lp-modal"
        >
          <Form.Item
            label={"Gas price, gwei"}
            name={"gasPriceGwei"}
            rules={[
              {
                required: true,
                message: `Gas price is required!`,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={"Waiting after transaction, ms"}
            name={"delayMs"}
            rules={[
              {
                required: true,
                message: `Delay value is required!`,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreateLowGasLpConfigModal;