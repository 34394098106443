import React, { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import * as ethers from 'ethers';
import { observer } from 'mobx-react';
import uniswpFactoryAbi from '../../abi/uniswpFactoryAbi.json';
import { useStores } from '../../store/RootStore';
import './CreatePairButton.less';
import { convertMevChains } from '../../services/utils';

const CreatePairButton = observer(({ baseTokenContract, quoteTokenContract, currentNetwork, currentExchange, disabled, onLoading, onReady }) => {
  const { WalletStore } = useStores();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();

  if (!WalletStore.walletAddress || !baseTokenContract || !quoteTokenContract) {
    return null
  }

  useEffect(async () => {
    if (loading || status !== undefined) {
      return;
    }
    await updateStatus();
    return;
  });

  const updateStatus = async () => {
    setLoading(true);
    setStatus(await getPairStatus());
    setLoading(false);
  }

  const getPairStatus = async () => {
    onReady(false);
    if (!WalletStore.walletAddress || !WalletStore.provider) {
      return false;
    }

    try {
      const provider = new ethers.providers.Web3Provider(WalletStore.provider);
      let contract = new ethers.Contract(ethers.utils.getAddress(currentExchange.factoryAddress), uniswpFactoryAbi, provider);

      let pairAddress = await contract.getPair(ethers.utils.getAddress(quoteTokenContract), ethers.utils.getAddress(baseTokenContract));

      if (pairAddress !== "0x0000000000000000000000000000000000000000") {
        onReady(true);
        return pairAddress;
      }
      return false;
    } catch (e) {
      message.error(e.message)
    }

    return false
  }

  const create = async () => {
    setLoading(true);
    onLoading(true);

    if (!WalletStore.walletAddress) {
      message.error("Please connect your wallet");
      setLoading(false);
      onLoading(false);
      return false;
    }

    try {
      const provider = new ethers.providers.Web3Provider(WalletStore.provider);
      let contract = new ethers.Contract(ethers.utils.getAddress(currentExchange.factoryAddress), uniswpFactoryAbi, provider);
      let tx = await contract.populateTransaction.createPair(
        ethers.utils.getAddress(quoteTokenContract),
        ethers.utils.getAddress(baseTokenContract)
      );

      let nonce = await provider.getTransactionCount(WalletStore.walletAddress);
      let gasPrice = await provider.getGasPrice();

      let txHash = await WalletStore.provider.request({
        method: 'eth_sendTransaction',
        params: [{
          from: WalletStore.walletAddress,
          data: tx.data,
          to: tx.to,
          chainId: ethers.utils.hexlify(convertMevChains(currentNetwork.chainId)),
          value: ethers.utils.hexlify(ethers.BigNumber.from(0)),
          gasPrice: gasPrice._hex,
          gas: ethers.utils.hexlify(ethers.BigNumber.from(4000000), { hexPad: "left" }),
          nonce: ethers.utils.hexlify(ethers.BigNumber.from(nonce), { hexPad: "left" })
        }]
      });

      if (txHash) {
        message.success('Transaction sent successfully. Waiting..', 3);
        let receipt = await provider.waitForTransaction(txHash);
        if (receipt && receipt.status === 1) {
          message.success(`Pair created: ${await getPairStatus()}`, 3);
        }
      } else {
        message.error(`Something went wrong`, 3);
      }
    } catch (e) {
      message.error(e.message)
    }
    await updateStatus();
    setLoading(false);
    onLoading(false);
  }

  return (
    <Button
      className={"create-pair-button"}
      loading={loading}
      disabled={disabled || loading || status}
      type="primary"
      onClick={create}
    >
      {status ? "Pair created" : "Create pair"}
    </Button>
  );
});

export default CreatePairButton;