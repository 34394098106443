import React from 'react';
import { Table, Statistic } from 'antd';
import './ProfileLpTxInfo.less';

const { Countdown } = Statistic;

const ProfileLpTxInfo = ({ parsedTransaction, currentNetwork }) => {
  const columns = [
    {
      key: '1',
      dataIndex: '1',
    },
    {
      dataIndex: '2',
      key: '2',
    },
  ];

  let data = []
  if (parsedTransaction) {
    const parsedResult = typeof parsedTransaction === "string" ? JSON.parse(parsedTransaction) : parsedTransaction;
    data = [
      {
        key: '1',
        "1": "From",
        "2": <><b><a href={`${currentNetwork.explorer}address/${parsedResult.transactionData.from}`} target={"_blank"}>{parsedResult.transactionData.from}</a></b></>
      },
      {
        key: '2',
        "1": "Method",
        "2": <><b>{parsedResult.contractData.method}</b></>
      },
      {
        key: '3',
        "1": "Deadline",
        "2": <><b><Countdown style={{ fontSize: "10px" }} value={parsedResult.liquidityAddData.deadLine * 1000} /></b></>
      },
      {
        key: '4',
        "1": "Gas price",
        "2": <><b>{parsedResult.transactionData.gasPriceGwei} gwei</b></>
      },
      {
        key: '5',
        "1": "Gas limit",
        "2": <><b>{parsedResult.transactionData.gasLimit}</b></>
      },
      {
        key: '6',
        "1": "Nonce",
        "2": <><b>{parsedResult.transactionData.nonce}</b></>
      },
      {
        key: '7',
        "1": <b>Base token</b>,
        colSpan: 2,
      },
      {
        key: '8',
        "1": "Contract address",
        "2": <><b><a href={`${currentNetwork.explorer}address/0x${parsedResult.liquidityAddData.baseCurrencyAddress}`} target={"_blank"}>0x{parsedResult.liquidityAddData.baseCurrencyAddress}</a></b></>,
      },
      {
        key: '9',
        "1": "Token amount",
        "2": <><b>{+parsedResult.liquidityAddData.baseCurrencyDesiredAmount.toFixed(8)}</b> <i>(min: {+parsedResult.liquidityAddData.baseCurrencyMinAmount.toFixed(8)})</i></>,
      },
      {
        key: '10',
        "1": <b>Quote token</b>,
        colSpan: 2,
      },
      {
        key: '11',
        "1": "Contract address",
        "2": <><b><a href={`${currentNetwork.explorer}address/0x${parsedResult.liquidityAddData.quoteCurrencyAddress}`} target={"_blank"}>0x{parsedResult.liquidityAddData.quoteCurrencyAddress}</a></b></>
      },
      {
        key: '12',
        "1": "Token amount",
        "2": <><b>{+parsedResult.liquidityAddData.quoteCurrencyDesiredAmount.toFixed(8)}</b> <i>(min: {+parsedResult.liquidityAddData.quoteCurrencyMinAmount.toFixed(8)})</i></>
      },
    ]
  }

  return (
    <div className={"profile-lp-tx-info"}>
      <Table className={"profile-lp-tx-info-table"} pagination={false} showHeader={false} size={"small"} columns={columns} dataSource={data} />
    </div>
  );
};

export default ProfileLpTxInfo;