import React from 'react';
import { Descriptions } from 'antd';
import './CalculationResult.less';

const CalculationResult = ({ calculation, networks, hideSniperTxs }) => {
  const currentNetwork = networks ? networks.find(network => network.chainId === calculation.chainId) : undefined;
  const currentExchange = !!currentNetwork ? currentNetwork.exchanges.find(exchange => exchange.exchangeId === calculation.exchangeId) : undefined;

  return (
    <div className={"calculation-result"}>
      <Descriptions column={4} layout="vertical" bordered size={"small"}>
        <Descriptions.Item span={2} label="CalculationID"><b>{calculation.uuid}</b></Descriptions.Item>
        <Descriptions.Item span={1} label="Network"><b>{currentNetwork.networkName}</b></Descriptions.Item>
        <Descriptions.Item span={1} label="Exchange"><b>{currentExchange.exchangeName}</b></Descriptions.Item>

        <Descriptions.Item span={2} label={`Base token (${calculation.baseTokenName})`}>
          <table>
            <tbody>
              <tr>
                <td colSpan={2}><b><a href={`${currentNetwork.explorer}address/${calculation.baseToken}`} target={"_blank"} rel="noreferrer">{calculation.baseToken}</a></b></td>
              </tr>
              <tr>
                <td>Decimals</td>
                <td><b>{calculation.baseTokenDecimals}</b></td>
              </tr>
              <tr>
                <td>Amount</td>
                <td><b>{+calculation.baseTokenAmount.toFixed(6)}</b></td>
              </tr>
              <tr>
                <td>Amount, $</td>
                <td><b>{+calculation.baseTokenAmountInUsd.toFixed(4)}</b></td>
              </tr>
              <tr>
                <td>Price, $</td>
                <td><b>{+calculation.baseTokenPriceInUsd.toFixed(4)}</b></td>
              </tr>
            </tbody>
          </table>
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Quote token">
          <table>
            <tbody>
              <tr>
                <td colSpan={2}><b><a href={`${currentNetwork.explorer}address/${calculation.quoteToken}`} target={"_blank"} rel="noreferrer">{calculation.quoteToken}</a></b></td>
              </tr>
              <tr>
                <td>Decimals</td>
                <td><b>{calculation.quoteTokenDecimals}</b></td>
              </tr>
              <tr>
                <td>Amount</td>
                <td><b>{+calculation.quoteTokenAmount.toFixed(6)}</b></td>
              </tr>
              <tr>
                <td>Amount, $</td>
                <td><b>{+calculation.quoteTokenAmountInUsd.toFixed(4)}</b></td>
              </tr>
              <tr>
                <td>Price, $</td>
                <td><b>{+calculation.quoteTokenPriceInUsd.toFixed(4)}</b></td>
              </tr>
            </tbody>
          </table>
        </Descriptions.Item>
        <Descriptions.Item style={{ background: "#fff" }} span={4} label={<>Base to quote token price: <b>{calculation.baseTokenToQuoteTokenPrice}</b></>}></Descriptions.Item>
        {
          !hideSniperTxs &&
          <Descriptions.Item span={4} label="Trades">
            <table className={"trades-table"}>
              <thead>
                <tr>
                  <th rowSpan={2}>Type</th>
                  <th rowSpan={2}>From (QT)</th>
                  <th rowSpan={2}>To (BT)</th>
                  <th colSpan={6} style={{ textAlign: "center" }}>Base to quote token trade price</th>
                  <th rowSpan={2}>Price change</th>
                </tr>
                <tr>
                  <th>Before</th>
                  <th>Before, $</th>
                  <th>Actual</th>
                  <th>Actual, $</th>
                  <th>After</th>
                  <th>After, $</th>
                </tr>
              </thead>
              <tbody>
                {
                  calculation.trades.map((trade, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ textTransform: 'capitalize', fontWeight: "bold" }}>{trade.type}</td>
                        <td>{+trade.quoteTokenAmountFrom.toFixed(5)}</td>
                        <td>{+(trade.baseTokenAmountToWithFee || trade.baseTokenAmountTo).toFixed(5)}</td>
                        <td>{+trade.baseTokenToQuoteTokenBeforeTradePrice.toFixed(5)}</td>
                        <td>{+trade.baseTokenToQuoteTokenBeforeTradePriceInUsd.toFixed(5)}</td>
                        <td>{+trade.baseTokenToQuoteTokenActualTradePrice.toFixed(5)}</td>
                        <td>{+trade.baseTokenToQuoteTokenActualTradePriceInUsd.toFixed(5)}</td>
                        <td>{+trade.baseTokenToQuoteTokenAfterTradePrice.toFixed(5)}</td>
                        <td>{+trade.baseTokenToQuoteTokenAfterTradePriceInUsd.toFixed(5)}</td>
                        <td>{+trade.priceChangePercent.toFixed(3)}x</td>
                      </tr>
                    )
                  })
                }
                {
                  !!calculation.trades.length && <tr className={'total-result'} key={'total'}>
                  <td style={{ textTransform: 'capitalize', fontWeight: "bold" }} colSpan={9}>Total</td>
                  <td>{+(calculation.trades[calculation.trades.length - 1].baseTokenToQuoteTokenAfterTradePrice / calculation.trades[0].baseTokenToQuoteTokenBeforeTradePrice).toFixed(3)}x</td>
                </tr>
                }
              </tbody>
            </table>
          </Descriptions.Item>
        }
      </Descriptions>
    </div>
  );
};

export default CalculationResult;