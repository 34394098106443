import React, { useState, useEffect } from "react";
import { Button, message } from 'antd';
import * as ethers from 'ethers';
import { Transaction } from "@ethereumjs/tx";
import Common from '@ethereumjs/common'
import { observer } from 'mobx-react';
import { useStores } from '../../store/RootStore';
import { convertMevChains, getRouterAbi } from "../../services/utils";
import './SignTradeTxByMetamask.less';

import ConnectMetamaskButton from '../ConnectMetamaskButton/ConnectMetamaskButton';
import ApproveButton from '../ApproveButton/ApproveButton';

const SignTradeTxByMetamask = observer(({ calculation, trade, required, currentNetwork, currentExchange, onSaveTransaction, customRouter }) => {
  const { WalletStore } = useStores();

  useEffect(() => {
    return () => {
      WalletStore.setProvider(null);
      WalletStore.setWalletAddress(null);
    };
  }, []);

  const [isQuoteTokenReady, setIsQuoteTokenReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);

  const signTransaction = async () => {
    setLoading(true);

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      let routerContract = new ethers.Contract(customRouter || currentExchange.routerAddress, getRouterAbi(calculation.chainId, calculation.exchangeId), provider);

      const quoteFrom = ethers.BigNumber.from(trade.quoteTokenAmountFromBN);
      let baseTo = ethers.BigNumber.from(trade.baseTokenAmountToMinBN);

      const isNativeCurrency = calculation.quoteToken.toLowerCase() === currentNetwork.networkCurrency.address.toLowerCase();

      const nonce = await provider.getTransactionCount(WalletStore.walletAddress);

      let tx;
      const path = [
        ethers.utils.getAddress(calculation.quoteToken), 
        ethers.utils.getAddress(calculation.baseToken)
      ];
      if (isNativeCurrency) {
        tx = await routerContract.populateTransaction[currentExchange.nativeForTokensSwapMethod](
          baseTo,
          path,
          ethers.utils.getAddress(WalletStore.walletAddress),
          getDeadline(72)
        );
      } else {
        tx = await routerContract.populateTransaction[currentExchange.tokensForTokensSwapMethod](
          quoteFrom,
          baseTo,
          path,
          ethers.utils.getAddress(WalletStore.walletAddress),
          getDeadline(72)
        );
      };

      const common = Common.custom({ chainId: convertMevChains(currentNetwork.chainId) });

      let transaction = new Transaction({
        data: tx.data,
        to: tx.to,
        chainId: ethers.BigNumber.from(convertMevChains(currentNetwork.chainId)).toHexString(),
        value: isNativeCurrency ? quoteFrom.toHexString() : ethers.BigNumber.from(0).toHexString(),
        gasPrice: ethers.utils.parseUnits(String(required.gasPriceGwei), "gwei").toHexString(),
        gasLimit: ethers.BigNumber.from(required.gasLimit).toHexString(),
        nonce: ethers.BigNumber.from(nonce).toHexString()
      }, { common });

      let unsignedTx = transaction.getMessageToSign();
      let signature = await window.ethereum.request({
        method: 'eth_sign',
        params: [
          WalletStore.walletAddress,
          ethers.utils.hexlify(unsignedTx)
        ]
      });

      let signatureParts = ethers.utils.splitSignature(signature);

      let txWithSignature = transaction._processSignature(
        signatureParts.v,
        ethers.utils.arrayify(signatureParts.r),
        ethers.utils.arrayify(signatureParts.s)
      );

      let serializedTxWithSignature = ethers.utils.hexlify(txWithSignature.serialize());
      await onSaveTransaction({
        address: WalletStore.walletAddress,
        hexTransaction: serializedTxWithSignature
      });
    } catch (e) {
      message.error(e.message)
    }

    setLoading(false)
  }

  const getDeadline = (hours) => {
    return Math.floor(Date.now() / 1000) + 60 * 60 * hours;
  }

  return (
    <div className={"sign-by-metamask"}>
      <ConnectMetamaskButton currentNetwork={currentNetwork} />
      {
        !!WalletStore.walletAddress &&
        <div className={"buttons"}>
          <ApproveButton
            key={"qt-user-address-" + WalletStore.walletAddress}
            tokenName={"Quote token"}
            tokenContract={calculation.quoteToken}
            tokenDecimals={calculation.quoteTokenDecimals}
            tokenAmount={calculation.quoteTokenAmount}
            currentNetwork={currentNetwork}
            currentExchange={currentExchange}
            disabled={disableButtons}
            onLoading={setDisableButtons}
            onReady={setIsQuoteTokenReady}
            customRouter={customRouter}
          />

          <Button
              loading={loading}
              disabled={disableButtons || loading || !isQuoteTokenReady}
              type="primary"
              size={"large"}
              onClick={() => signTransaction()}
            >
              Sign
            </Button>
        </div>
      }
    </div>
  )
});

export default SignTradeTxByMetamask;