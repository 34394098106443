import React from 'react';
import { Modal, Form, Input } from 'antd';
import './ProfileEditModal.less';

const ProfileEditModal = ({ param, visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title={param.title}
      okText="Update"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        className={"profile-edit-modal"}
        form={form}
        layout="vertical"
        name="profile-edit-modal"
        initialValues={{
          [param.name]: param.value,
        }}
      >
        <Form.Item
          name={param.name}
          rules={[
            {
              required: true,
              message: `${param.title} is required!`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProfileEditModal;