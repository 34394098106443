import React from 'react';
import { Table, Tooltip } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone, QuestionCircleOutlined } from '@ant-design/icons';
import './TradeValidation.less';
import ProfileWalletTransactionValidation from '../../../ProfileWalletTransaction/components/ProfileWalletTransactionValidation/ProfileWalletTransactionValidation';

const columns = [
  {
    key: '1',
    dataIndex: '1',
  },
  {
    dataIndex: '2',
    key: '2',
    align: "left"
  },
  {
    dataIndex: '3',
    key: '3',
    align: "left"
  },
];

const TradeValidation = ({ title, trades }) => {
  const getStatusIcon = (status) => {
    if (!!status) {
      return <CheckCircleTwoTone twoToneColor="#52c41a" />
    } else {
      return <CloseCircleTwoTone twoToneColor="#eb2f96" />
    }
  }

  const validationInfo = (validation) => {
    if (!validation) {
      return (
        <div className={"validation-status"}>
          {getStatusIcon(false)}
          <Tooltip placement="left" title={"no data"}>
            <QuestionCircleOutlined style={{ marginLeft: "3px" }} />
          </Tooltip>
        </div>
      )
    }

    return (
      <div className={"validation-status"}>
        {getStatusIcon(validation.validationStatus)}
        <Tooltip
          className={'tooltip'}
          placement="left"
          color={'#fff'}
          overlayStyle={{maxWidth: '400px'}}
          title={
            <ProfileWalletTransactionValidation
              title={'Validation'}
              validation={validation}
            />
          }>
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    )
  }

  const dataSource = [];

  dataSource.push({
    key: "title",
    "1": <h3>{title || "Validation"}</h3>,
    "2": ""
  });

  trades.forEach((trade) => {
    dataSource.push({
      key: trade.id,
      "1": <>Wallet {trade.trade.position}</>,
      "2": validationInfo(trade.validation),
    });
  });

  return (
    <div className={"trade-validation"}>
      <Table className={"trade-validation-table"} pagination={false} showHeader={false} size={"small"} columns={columns} dataSource={dataSource} />
    </div>
  );
};

export default TradeValidation;