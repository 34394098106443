import React, { useState } from 'react';
import { Button, Modal, Form, Input, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../../store/RootStore';
import { sendLpTxAsync } from "../../../../../../services/apiRequests";
import './SendLpTransactionPubliclyButton.less';

const confirmText = "CONFIRM";

const SendLpTransactionPubliclyButton = observer(({ lpTransaction, update }) => {
  const {
    ProfileStore,
    AppStore: { networks },
    AuthStore: { accessToken }
  } = useStores();

  const { profile } = ProfileStore;
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [form] = Form.useForm();

  const onOpen = () => {
    setLoading(true);
    setVisible(true);
  }

  const onCancel = async () => {
    form.resetFields();
    setVisible(false);
    setLoading(false);
  }

  const onSend = async () => {
    const currentNetwork = networks.find(network => network.chainId === profile.calculation.chainId);
    let tx = await sendLpTxAsync(accessToken, profile.uuid);
    if (!!tx.errorMessage) {
      return message.error(tx.errorMessage, 5);
    }

    if (!!tx.hash) {
      message.success(<>
        <div><b>Transaction sent successfully!</b></div>
        <div><a href={currentNetwork.explorer + "tx/" + tx.hash} target={"_blank"}>{tx.hash}</a></div>
      </>, 6);
      onCancel();

      await update();
      return;
    }

    return message.error("Internal server error", 5);
  }

  const onConfirm = () => {
    form
      .validateFields()
      .then(async values => {
        form.resetFields();
        if (values.text === confirmText) {
          await onSend();
          return true;
        }
        form.validateFields(['text'])
      })
      .catch(info => {
        form.resetFields();
        form.validateFields(['text'])
        return false;
      });
  }

  return (
    <div className={"send-lp-publicly"}>
      <Button
        className={"send-lp-publicly-button"}
        type="dashed"
        danger
        onClick={onOpen}
        loading={loading}
        disabled={!lpTransaction || !!loading}
      >
        {
          profile.calculation.exchangeId === 'uniswapV2openTrading'
            ? "Send Open Trading tx publicly"
            : "Send LP transaction publicly"
        }
      </Button>
      <Modal
        className={"send-lp-publicly-modal"}
        visible={visible}
        title={<h2><ExclamationCircleOutlined /> Confirm</h2>}
        width={"400px"}
        onCancel={onCancel}
        onOk={onConfirm}
        okText={"Send"}
      >
        <div><b>Are you sure you want to send the transaction publicly?</b></div>
        <div>All Fair Launch processes will be disabled.</div>
        <br />
        <div>Type <b>{confirmText}</b> to send</div>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: 'public' }}
        >
          <Form.Item
            name="text"
            rules={[
              { required: true, message: 'Please enter the text exactly as displayed to confirm' }
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default SendLpTransactionPubliclyButton;