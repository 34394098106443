import React, { useState, useEffect } from 'react';
import { Button, Row, Col, message, Spin } from 'antd';
import { useHistory, useParams } from "react-router-dom";
import { SyncOutlined } from '@ant-design/icons';
import { getTradeAsync, saveTradeTxAsync } from "../../../../services/apiRequests";
import './ProfileWalletTransaction.less';

import SignTradeTxByMetamask from "../../../SignTradeTxByMetamask/SignTradeTxByMetamask";
import ProfileWalletTransactionValidation from './components/ProfileWalletTransactionValidation/ProfileWalletTransactionValidation';
import ProfileWalletTransactionInfo from './components/ProfileWalletTransactionInfo/ProfileWalletTransactionInfo';

const ProfileWalletTransaction = ({ accessToken, profile, networks, updateProfile }) => {
  const history = useHistory();

  const { profileId, tradeId } = useParams();

  const [operation, setOperation] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const currentNetwork = networks ? networks.find(network => network.chainId === profile.calculation.chainId) : undefined;
  const currentExchange = !!currentNetwork ? currentNetwork.exchanges.find(exchange => exchange.exchangeId === profile.calculation.exchangeId) : undefined;

  useEffect(() => {
    getTrade();
  }, [profileId, tradeId]);

  const getTrade = async () => {
    setLoading(true);

    const operationInfo = await getTradeAsync(accessToken || null, profileId, tradeId);
    if (operationInfo.errorMessage) {
      setErrorMessage(operationInfo.errorMessage);
      setLoading(false);
      return setOperation();
    }

    setLoading(false);
    return setOperation(operationInfo);
  }

  const onSaveTransaction = async (values) => {
    setLoading(true);
    if (!!values.hexTransaction) {
      let response = await saveTradeTxAsync(accessToken, profileId, tradeId, {
        hexTransaction: values.hexTransaction,
        address: values.address,
      });

      if (response.errorMessage) {
        message.error(response.errorMessage, 5);
        setLoading(false);
        return;
      }

      message.success("Transaction saved successfully", 5);
      await getTrade(values);
    }

    await updateProfile();

    setLoading(false);
    return
  }

  // const validate = async (hexTransaction) => {
  //   let validation = await validateLpTxAsync(profile.uuid, { hexTransaction });
  //   return setLpValidation(validation);
  // }

  // if (!operation) {
  //   return (
  //     <div className={"profile-wallet-transaction"}>
  //       {
  //         !!errorMessage &&
  //         <Alert message={errorMessage} type="error" showIcon />
  //       }
  //     </div>
  //   )
  // }

  return (
    <div className={"profile-wallet-transaction"}>
      <Row gutter={8}>
        <Col span={18}>
          <h3>Trade transaction</h3>
          <Spin spinning={loading}>
            <ProfileWalletTransactionInfo
              parsedTransaction={operation && operation.validation && operation.validation.info && operation.validation.info.parsedResult}
              currentNetwork={currentNetwork}
            />
          </Spin>
          <Row gutter={8}>
            <Col span={24}>
              <h3>Add/Update transaction</h3>
            </Col>
            <Col span={24}>
              <div className={"add-wallet-transaction"}>
                <SignTradeTxByMetamask
                  trade={operation && operation.trade}
                  required={operation && operation.required}
                  calculation={profile.calculation}
                  currentNetwork={currentNetwork}
                  currentExchange={currentExchange}
                  onSaveTransaction={onSaveTransaction}
                  customRouter={profile.customRouter}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Spin spinning={loading}>
            <ProfileWalletTransactionValidation
              title={"Validation"}
              validation={operation && operation.validation}
            />
          </Spin>
        </Col>
      </Row>
      <div className={"btn-group"}>
        <Button type={"default"} onClick={() => history.push(`/profiles/${profile.uuid}/multipleWallets`)}>Back</Button>
        <Button type={"default"} onClick={() => getTrade()}><SyncOutlined spin={loading} />Validate</Button>
      </div>
    </div>
  );
};

export default ProfileWalletTransaction;