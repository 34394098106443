import React, { useState, useEffect } from 'react';
import { Modal, Spin, Empty } from 'antd';
import { LoadingOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import './RunResultModal.less';

const RunResultModal = ({ profile, networks, transactions, update, visible, onCancel }) => {
  const [lpTransaction, setLpTransaction] = useState(false);
  const [lpTransactionWithLowGas, setLpTransactionWithLowGas] = useState(false);

  useEffect(() => {
    if (!transactions || transactions.length === 0) {
      return;
    }
    let lpTx = transactions.find((tx) => tx.type == 'lpTransaction');
    setLpTransaction(lpTx);

    let lpTxLowGas = transactions.find((tx) => tx.type == 'lpTransactionWithLowGasPrice');
    setLpTransactionWithLowGas(lpTxLowGas);

    if (!lpTx || lpTx.status !== null && lpTx.status !== undefined && lpTx.receipt) {
      return;
    }

    let timer = setTimeout(() => {
      update();
    }, 1000 * 15); // 15sec
    return () => {
      return clearTimeout(timer)
    }
  })

  if (!lpTransaction || !transactions || transactions.length === 0) {
    return null;
  }

  const currentNetwork = networks ? networks.find(network => network.chainId === profile.calculation.chainId) : undefined;
  const currentExchange = !!currentNetwork ? currentNetwork.exchanges.find(exchange => exchange.exchangeId === profile.calculation.exchangeId) : undefined;

  let executeTransactions = transactions
    .filter((tx) => tx.type.includes('executeTransaction') && tx.status === 1 && !!tx.receipt && tx.receipt.logs && tx.receipt.logs.length > 1)
    .sort((a, b) => a.receipt.transactionIndex > b.receipt.transactionIndex ? 1 : -1);

  const getStatusIcon = (tx) => {
    if (tx.status == 1) {
      return <CheckCircleTwoTone twoToneColor="#52c41a" />
    } else if ((tx.status === null || tx.status === undefined) && !tx.receipt) {
      return <LoadingOutlined />
    } else {
      return <CloseCircleTwoTone twoToneColor="#eb2f96" />
    }
  }

  return (
    <Modal
      className={"run-result-modal"}
      visible={visible}
      title={<h2>Sniping result</h2>}
      width={"500px"}
      onOk={onCancel}
      onCancel={onCancel}
      footer={false}
    >
      {
        !!lpTransactionWithLowGas &&
        <>
          <h3>LP transaction with low gas price</h3>
          <div className={'lp-tx'}>
            <div><a href={currentNetwork.explorer + "tx/" + lpTransactionWithLowGas.hash} target={"_blank"} rel="noreferrer">{lpTransactionWithLowGas.hash.substr(0, 24) + "..." + lpTransactionWithLowGas.hash.substr(-24)}</a> {getStatusIcon(lpTransactionWithLowGas)}</div>
            <div><i>Broadcast timing: {lpTransactionWithLowGas.executorBroadcastTimeMs} ms</i></div>
            {
              !!lpTransactionWithLowGas.receipt &&
              <div><i>Block number: {lpTransactionWithLowGas.receipt.blockNumber}, index: {lpTransactionWithLowGas.receipt.transactionIndex}</i></div>
            }
          </div>
        </>
      }

      {
        profile.calculation.exchangeId === "uniswapV2openTrading"
          ? <h3>OpenTrading transaction</h3>
          : <h3>LP transaction</h3>
      }=
      <div className={'lp-tx'}>
        <div><a href={currentNetwork.explorer + "tx/" + lpTransaction.hash} target={"_blank"} rel="noreferrer">{lpTransaction.hash.substr(0, 24) + "..." + lpTransaction.hash.substr(-24)}</a> {getStatusIcon(lpTransaction)}</div>
        <div><i>Broadcast timing: {lpTransaction.executorBroadcastTimeMs} ms</i></div>
        {
          !!lpTransaction.receipt &&
          <div><i>Block number: {lpTransaction.receipt.blockNumber}, index: {lpTransaction.receipt.transactionIndex}</i></div>
        }
      </div>
      <h3>Pair address</h3>
      <div className={'pair-address'}>
        <a href={currentExchange.pairExplorer + lpTransaction.pairAddress} target={"_blank"} rel="noreferrer">{lpTransaction.pairAddress}</a>
      </div>
      <h3>Execute transactions</h3>
      <div className={'executor-txs'}>
        {
          executeTransactions.length !== 0
            ? executeTransactions.map((tx, i) => {
              return <div key={i} className={'executor-tx'}>
                <div><a href={currentNetwork.explorer + "tx/" + tx.hash} target={"_blank"} rel="noreferrer">{tx.hash.substr(0, 24) + "..." + tx.hash.substr(-24)}</a></div>
                <div><i>Broadcast timing: {tx.executorBroadcastTimeMs} ms, executor number: {tx.executorBroadcastPosition}</i></div>
                {
                  !!tx.receipt &&
                  <div><i>Block number: {tx.receipt.blockNumber}, index: {tx.receipt.transactionIndex}</i></div>
                }
              </div>
            })
            : lpTransaction.status !== 1
              ? <Spin size="large" />
              : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
      </div>
    </Modal>
  );
};

export default RunResultModal;