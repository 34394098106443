import { observable, action, makeObservable } from "mobx";

class AuthStore {
    accessToken = null;

    constructor() {
        makeObservable(this, {
            accessToken: observable,

            getAccessToken: action,
            setAccessToken: action
        });
    };

    getAccessToken = () => this.accessToken;
    setAccessToken = (accessToken) => {
        this.accessToken = accessToken;
    };
};

export default new AuthStore();
