import { createContext, useContext } from 'react'

import AppStore from "./AppStore";
import AuthStore from "./AuthStore";
import WalletStore from "./WalletStore";
import ProfileStore from "./ProfileStore";

const storeContext = createContext({ AppStore, AuthStore, WalletStore, ProfileStore })

export function useStores () {
    return useContext(storeContext)
};