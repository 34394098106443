import React from 'react';
import { Table, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import './ProfileLpValidation.less';
import { getStatusIcon } from '../../../../services/utils';

const columns = [
  {
    key: '1',
    dataIndex: '1',
  },
  {
    dataIndex: '2',
    key: '2',
    align: "left"
  },
];

const ProfileLpValidation = ({ title, validation }) => {
  const validationInfo = (validation) => {
    if (!validation || !validation.hasOwnProperty('isValid')) {
      return (
        <div className={"validation-status"}>
          {getStatusIcon(false)}
          <Tooltip placement="left" title={"no data"}>
            <QuestionCircleOutlined style={{ marginLeft: "3px" }} />
          </Tooltip>
        </div>
      )
    }

    let message = [];
    if (validation.hasOwnProperty('actual')) {
      message.push(<div key={1}>actual: {validation.actual}</div>)
    }
    if (validation.hasOwnProperty('expected')) {
      message.push(<div key={2}>expected: {validation.expected}</div>)
    }
    if (validation.hasOwnProperty('gasUsage')) {
      message.push(<div key={3}>gas usage: {validation.gasUsage}</div>)
    }

    return (
      <div className={"validation-status"}>
        {getStatusIcon(validation.hasOwnProperty('isValid') ? validation.isValid : false)}
        <Tooltip placement="left" title={message}>
          <QuestionCircleOutlined style={{ marginLeft: "3px" }} />
        </Tooltip>
      </div>
    )
  }

  let hasResult = !!validation && Object.keys(validation).length && validation.hasOwnProperty("validationDetails");

  let data = [
    {
      key: "0",
      "1": <h3>{title || "Validation"}</h3>,
      "2": title ? "" : <b style={{ fontSize: "20px" }}>{getStatusIcon(hasResult ? validation.validationStatus : false)}</b>
    },
    {
      key: "1",
      "1": <b>Chain data</b>,
      colSpan: 2
    },
    {
      key: "2",
      "1": "Base token approval",
      "2": validationInfo(hasResult ? validation.validationDetails.chainDataValidation.baseTokenApprovalCheck : {}),
    },
    {
      key: "3",
      "1": "Base token balance",
      "2": validationInfo(hasResult ? validation.validationDetails.chainDataValidation.baseTokenBalanceCheck : {})
    },
    {
      key: "4",
      "1": "Quote token approval",
      "2": validationInfo(hasResult ? validation.validationDetails.chainDataValidation.quoteTokenApprovalCheck : {})
    },
    {
      key: "5",
      "1": "Quote token balance",
      "2": validationInfo(hasResult ? validation.validationDetails.chainDataValidation.quoteTokenBalanceCheck : {})
    },
    hasResult && !!validation.validationDetails.chainDataValidation.pairCheck
      ? {
        key: "5-1",
        "1": "Pair creation",
        "2": validationInfo(validation.validationDetails.chainDataValidation.pairCheck)
      }
      : null,
    {
      key: "6",
      "1": <b>Contract data</b>,
      colSpan: 2
    },
    {
      key: "7",
      "1": "Base token address",
      "2": validationInfo(hasResult ? validation.validationDetails.contractDataValidation.baseCurrencyAddressCheck : {})
    },
    {
      key: "8",
      "1": "Base token amount",
      "2": validationInfo(hasResult ? validation.validationDetails.contractDataValidation.baseCurrencyDesiredAmountCheck : {})
    },
    {
      key: "9",
      "1": "Base token min amount",
      "2": validationInfo(hasResult ? validation.validationDetails.contractDataValidation.baseCurrencyMinAmountCheck : {})
    },
    {
      key: "10",
      "1": "Quote token address",
      "2": validationInfo(hasResult ? validation.validationDetails.contractDataValidation.quoteCurrencyAddressCheck : {})
    },
    {
      key: "11",
      "1": "Quote token amount",
      "2": validationInfo(hasResult ? validation.validationDetails.contractDataValidation.quoteCurrencyDesiredAmountCheck : {})
    },
    {
      key: "12",
      "1": "Quote token min amount",
      "2": validationInfo(hasResult ? validation.validationDetails.contractDataValidation.quoteCurrencyMinAmountCheck : {})
    },
    {
      key: "13",
      "1": "LP receiver address",
      "2": validationInfo(hasResult ? validation.validationDetails.contractDataValidation.lpReceiverAddressCheck : {})
    },
    {
      key: "14",
      "1": "Deadline",
      "2": validationInfo(hasResult ? validation.validationDetails.contractDataValidation.deadLineCheck : {})
    },
    {
      key: "15",
      "1": <b>Transaction data</b>,
      colSpan: 2
    },
    {
      key: "16",
      "1": "Chain",
      "2": validationInfo(hasResult ? validation.validationDetails.transactionDataValidation.chainIdCheck : {})
    },
    {
      key: "17",
      "1": "Gas limit",
      "2": validationInfo(hasResult ? validation.validationDetails.transactionDataValidation.gasLimitCheck : {})
    },
    {
      key: "18",
      "1": "Gas price",
      "2": validationInfo(hasResult ? validation.validationDetails.transactionDataValidation.gasPriceCheck : {})
    },
    {
      key: "19",
      "1": "Nonce",
      "2": validationInfo(hasResult ? validation.validationDetails.transactionDataValidation.nonceCheck : {})
    },
    {
      key: "20",
      "1": "Router address",
      "2": validationInfo(hasResult ? validation.validationDetails.transactionDataValidation.routerAddressCheck : {})
    },
    {
      key: "21",
      "1": "Simulation",
      "2": validationInfo(hasResult ? validation.validationDetails.simulationValidation.simulationCheck : {})
    }
  ];
  data = data.filter(Boolean)

  return (
    <div className={"profile-lp-validation"}>
      <Table className={"profile-lp-validation-table"} pagination={false} showHeader={false} size={"small"} columns={columns} dataSource={data} />
    </div>
  );
};

export default ProfileLpValidation;