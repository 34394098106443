import React, { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import * as ethers from 'ethers';
import { observer } from 'mobx-react';
import erc20Abi from '../../abi/erc20Abi.json';
import { useStores } from '../../store/RootStore';
import './ApproveButton.less';
import { convertMevChains } from '../../services/utils';

const ApproveButton = observer(({ tokenName, tokenContract, tokenDecimals, tokenAmount, currentNetwork, currentExchange, disabled, onLoading, onReady, customRouter }) => {
  const { WalletStore } = useStores();
  const [loading, setLoading] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState();

  if (!WalletStore.walletAddress || !tokenContract) {
    return null
  }

  useEffect(async () => {
    if (loading || approvalStatus !== undefined) {
      return;
    }
    await updateStatus();
    return;
  });

  const updateStatus = async () => {
    setLoading(true);
    setApprovalStatus(await getTokenApproveStatus());
    setLoading(false);
  }

  const getTokenApproveStatus = async () => {
    onReady(false);
    if (!WalletStore.walletAddress || !WalletStore.provider) {
      return false;
    }

    if (tokenContract.toLowerCase() === currentNetwork.networkCurrency.address.toLowerCase()) {
      onReady(true);
      return true;
    }

    try {
      const provider = new ethers.providers.Web3Provider(WalletStore.provider);
      let contract = new ethers.Contract(ethers.utils.getAddress(tokenContract), erc20Abi, provider);

      let currentAllowanceAmountBigNumber = await contract.allowance(ethers.utils.getAddress(WalletStore.walletAddress), customRouter || currentExchange.routerAddress);
      let status = +currentAllowanceAmountBigNumber.toString() >= (tokenAmount * (10 ** tokenDecimals));
      if (status) {
        onReady(true);
      }
      return status;
    } catch (e) {
      message.error(e.message)
    }

    return false
  }

  const approve = async () => {
    setLoading(true);
    onLoading(true);
    if (!WalletStore.walletAddress) {
      message.error("Please connect your wallet");
      loading(false);
      return false;
    }

    try {
      const provider = new ethers.providers.Web3Provider(WalletStore.provider);
      let contract = new ethers.Contract(ethers.utils.getAddress(tokenContract), erc20Abi, provider);
      let tx = await contract.populateTransaction.approve(
        customRouter || currentExchange.routerAddress,
        ethers.constants.MaxUint256
      );

      let nonce = await provider.getTransactionCount(WalletStore.walletAddress);
      let gasPrice = await provider.getGasPrice();

      let txHash = await WalletStore.provider.request({
        method: 'eth_sendTransaction',
        params: [{
          from: WalletStore.walletAddress,
          data: tx.data,
          to: tx.to,
          chainId: ethers.utils.hexlify(convertMevChains(currentNetwork.chainId)),
          value: ethers.utils.hexlify(ethers.BigNumber.from(0)),
          gasPrice: gasPrice._hex,
          gas: ethers.utils.hexlify(ethers.BigNumber.from(150000), { hexPad: "left" }),
          nonce: ethers.utils.hexlify(ethers.BigNumber.from(nonce), { hexPad: "left" })
        }]
      });

      if (txHash) {
        message.success('Transaction sent successfully. Waiting..', 3);
        let receipt = await provider.waitForTransaction(txHash);
        if (receipt && receipt.status === 1) {
          message.success(`Approved: ${tokenContract}`, 3);
        }
      } else {
        message.error(`Something went wrong`, 3);
      }
    } catch (e) {
      message.error(e.message)
    }
    await updateStatus();
    setLoading(false);
    onLoading(false);
  }

  return (
    <Button
      className={"approval-button"}
      loading={loading}
      disabled={disabled || loading || approvalStatus}
      type="primary"
      onClick={approve}
    >
      {
        !approvalStatus 
        ? <>Approve<br/>{tokenName}</>
        : <>{tokenName}<br/>approved</>
      }
    </Button>
  );
});

export default ApproveButton;