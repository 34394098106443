import React, { useState, useEffect } from 'react';
import { Alert, Button, Table, Popover, Popconfirm, message, Row, Col } from 'antd';
import { useHistory } from "react-router-dom";
import { SyncOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { finalCheckAsync, runAsync, getTransactionsAsync } from "../../../../services/apiRequests";
import { observer } from 'mobx-react';
import { useStores } from '../../../../store/RootStore';
import { getStatusIcon } from '../../../../services/utils';
import './ProfileRun.less';

import PriceChangeSimulation from "./components/PriceChangeSimulation/PriceChangeSimulation";
import MultiExecutorValidation from "../MultiExecutor/components/MultiExecutorValidation/MultiExecutorValidation";
import ExecutorBatchesValidation from "../MultiExecutor/components/ExecutorBatchesValidation/ExecutorBatchesValidation";
import ProfileOpenTradingValidation from "../ProfileOpenTradingValidation/ProfileOpenTradingValidation";
import ProfileLpValidation from "../ProfileLpValidation/ProfileLpValidation";
import RunResultModal from "./components/RunResultModal/RunResultModal";
import HashesModal from "./components/HashesModal/HashesModal";
import SetLaunchUtcTimeButton from "./components/SetLaunchUtcTimeButton/SetLaunchUtcTimeButton";
import SendLpTransactionPubliclyButton from "./components/SendLpTransactionPubliclyButton/SendLpTransactionPubliclyButton";
import TradeValidation from './components/TradeValidation/TradeValidation';

const ProfileRun = observer(({ loading, setLoading }) => {
  const history = useHistory();
  const {
    ProfileStore,
    AppStore: { networks },
    AuthStore: { accessToken }
  } = useStores();
  const [running, setRunning] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showHashes, setShowHashes] = useState(false);
  const [transactions, setTransactions] = useState();
  const [finalCheck, setFinalCheck] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const { profile, multiExecutorState, executorsState, updateProfile } = ProfileStore;

  useEffect(async () => {
    if (loading || !!finalCheck || finalCheck === false || !accessToken) {
      return;
    }
    await updateFinalCheck();
    return;
  });

  const updateFinalCheck = async () => {
    setLoading(true);
    await updateProfile(accessToken, profile.uuid);
    let response = await finalCheckAsync(accessToken, profile.uuid);
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
      setFinalCheck(false);
    } else {
      setFinalCheck(response);

      if (!!response.validationProcess.multiExecutorValidation) {
        ProfileStore.setMultiExecutorState(response.validationProcess.multiExecutorValidation.state);
      }

      ProfileStore.setExecutorsState({});
    }
    await updateTransactions();

    setLoading(false)
    return;
  }

  const updateTransactions = async () => {
    let response = await getTransactionsAsync(accessToken || null, profile.uuid);
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
      setTransactions(false);
      return;
    }
    setTransactions(response);
    return;
  }

  const onRun = async () => {
    setRunning(true)
    setErrorMessage();
    let response = await runAsync(accessToken || null, profile.uuid);
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      message.success('Transactions sent successfully');
      await updateTransactions();
      onShowResult();
    }

    setRunning(false)
    return
  }

  const simulationInfo = (validation) => {
    let error = [];
    if (!!validation && !validation.validationStatus) {
      if (validation.parsedLogs) {
        for (let i in validation.parsedLogs) {
          let log = validation.parsedLogs[i];
          if (!!log.isOk) {
            continue;
          }
          if (!log.errorMessage) {
            continue;
          }
          error.push(<div key={i}><b>{i == 0 ? "LP tx: " : `Executor ${i} tx: `}</b> {log.errorMessage}</div>)
        }
      } else if (validation.failedChecks) {
        for (let i in validation.failedChecks) {
          error.push(<div key={i}>{validation.failedChecks[i]}</div>)
        }
      }
    }

    return <>
      {getStatusIcon(!!validation ? validation.validationStatus : false)}
      {
        error.length > 0 &&
        <Popover placement="top" width={"400px"} title={false} content={<div className={"error-msg"}>{error}</div>}>
          <QuestionCircleOutlined style={{ marginLeft: "3px" }} />
        </Popover>
      }
    </>
  }

  const onShowResult = async () => {
    setRunning(true)
    await updateTransactions();
    setShowResult(true);
    setRunning(false)
  }

  const columns = [
    {
      key: '1',
      dataIndex: '1',
    },
    {
      dataIndex: '2',
      key: '2',
      align: "left"
    },
  ];

  let hasResult = !!finalCheck && Object.keys(finalCheck).length;

  let allBatchesAreValidated = false;

  const data = [];
  if (profile.calculation.mode === 'multipleWallets') {
    allBatchesAreValidated = !!hasResult && !!finalCheck.validationProcess.tradesValidation;

    data.push({
      key: "executors",
      "1": 'Executor validation',
      "2": getStatusIcon(!!hasResult ? finalCheck.validationProcess.tradesValidation.validationStatus : false)
    });

  } else if (profile.calculation.mode === 'multiExecutor') {
    allBatchesAreValidated = !!multiExecutorState
      && !!multiExecutorState.executors
      && !!multiExecutorState.executors.batches
      && Object.keys(multiExecutorState.executors.batches).length > 0
      && !!executorsState
      && Object.keys(executorsState).length > 0
      && Object.keys(multiExecutorState.executors.batches).length === Object.keys(executorsState).length

    data.push({
      key: "multiExecutor",
      "1": 'MultiExecutor validation',
      "2": getStatusIcon(!!hasResult ? finalCheck.validationProcess.multiExecutorValidation.validationStatus : false)
    });

    const allBatchesAreValid = allBatchesAreValidated
      && Object.keys(executorsState)
        .map(key => {
          return executorsState[key].allExecutorsHasEnoughBalanceCheck.isValid
            && executorsState[key].allExecutorsAreAllowedCheck.isValid
            && executorsState[key].allExecutorsSignedTransactionsCheck.isValid
            && executorsState[key].allExecutorsSignedTransactionsNonceCheck.isValid
            && executorsState[key].allExecutorsSignedTransactionsGasCheck.isValid;
        })
        .every(status => status === true);

    data.push({
      key: "executors",
      "1": 'Executors validation',
      "2": getStatusIcon(allBatchesAreValidated ? allBatchesAreValid : null)
    });
  }

  data.push({
    key: "3",
    "1": profile.calculation.exchangeId === 'uniswapV2openTrading' ? 'Open Trading tx validation' : 'LP transaction validation',
    "2": getStatusIcon(hasResult ? finalCheck.validationProcess.lpValidation.validationStatus : false)
  });

  data.push({
    key: "4",
    "1": 'Gas check',
    "2": getStatusIcon(hasResult ? finalCheck.validationProcess.finalGasCheck.validationStatus : false)
  });

  data.push({
    key: "5",
    "1": 'Broadcast provider check',
    "2": getStatusIcon(hasResult ? finalCheck.validationProcess.broadcastProviderLivenessValidation.validationStatus : false)
  });

  if (!!profile.lpTransactionWithLowGas) {
    data.push({
      key: "6",
      "1": 'LP tx with low gas price signed',
      "2": getStatusIcon(hasResult ? finalCheck.validationProcess.lpTransactionWithLowGasPriceSigned.validationStatus : false)
    })
    data.push({
      key: "7",
      "1": 'LP tx with low gas price nonce check',
      "2": getStatusIcon(hasResult ? finalCheck.validationProcess.lpTransactionWithLowGasNonceCheck.validationStatus : false)
    })
  }

  if (hasResult && finalCheck.validationProcess.flashbotsBundleSimulation) {
    data.push({
      key: "8",
      "1": 'Flashbots bundle simulation',
      "2": simulationInfo(finalCheck.validationProcess.flashbotsBundleSimulation)
    })
  }

  data.push({
    key: "9",
    "1": 'Final simulation',
    "2": simulationInfo(hasResult ? finalCheck.validationProcess.finalSimulationValidation : false)
  })
  data.push({
    key: "10",
    "1": 'Ready',
    "2": getStatusIcon(
      !allBatchesAreValidated
        ? null
        : hasResult
          ? finalCheck.validationStatus
          : false
    )
  })

  let lpTransaction = null;
  if (!!transactions) {
    lpTransaction = transactions.find(tx => tx.type === 'lpTransaction');
  }

  let executeTransactions = [];
  if (profile.calculation.mode === 'multipleWallets') {
    const txs = [];
    profile.calculation.trades.forEach(trade => {
      if (trade.tradeTransaction && trade.tradeTransaction.hexTransaction) {
        txs.push(trade.tradeTransaction.hexTransaction);
      }
    })
    if (txs.length) {
      executeTransactions.push(txs);
    }
  } else if (profile.calculation.mode === 'multiExecutor') {
    if (!!profile.profileExecutors) {
      executeTransactions = profile.profileExecutors
        .filter(tx => tx.isMultiExecutorOwner === false && !!tx.transactions)
        .map(tx => JSON.parse(tx.transactions));
    }
  }

  let networkInfo = networks.find(network => network.chainId === Number(profile.calculation.chainId));

  let jsonName = (profile.name.replace(/[^0-9a-zA-ZА-Яа-яЁё_\-]/g, "")) + ".json";
  let jsonHref = `data:text/json;charset=utf-8,${encodeURIComponent(
    JSON.stringify({
      profileName: profile.name,
      profileCreationDate: profile.createdAt,
      exchangeId: profile.calculation.exchangeId,
      chainId: profile.calculation.chainId,
      mode: profile.calculation.mode,
      aheadExecutorCount: profile.calculation.aheadExecutorCount,
      initialBroadcastLatencyMs: networkInfo.modes['multiExecutor'].initialBroadcastLatencyMs,
      secondaryBroadcastLatencyMs: profile.calculation.secondaryBroadcastLatencyMs,
      lpTransaction: !!profile.lpTransaction ? profile.lpTransaction.hexTransaction : null,
      lpTransactionWithLowGas: profile.lpTransactionWithLowGas ? profile.lpTransactionWithLowGas.hexTransaction : null,
      lpTransactionWithLowGasDelayMs: profile.lpTransactionWithLowGas ? profile.lpTransactionWithLowGas.delayMs : null,
      executeTransactions: executeTransactions
    }, null, "\t")
  )}`;

  return (
    <div className={"profile-run"}>
      {
        !!errorMessage &&
        <Alert message={errorMessage} type="error" showIcon />
      }
      <Row gutter={8}>
        <Col span={7}>
          {
            profile.calculation.mode === 'multipleWallets' &&
            <>
              <TradeValidation
                title={"Executors"}
                trades={!!finalCheck ? finalCheck.validationProcess.tradesValidation.state : []}
              />
            </>
          }
          {
            profile.calculation.mode === 'multiExecutor' &&
            <>
              <MultiExecutorValidation
                title={"MultiExecutor"}
                validation={!!finalCheck ? finalCheck.validationProcess.multiExecutorValidation.state.multiExecutor : undefined}
              />
              <ExecutorBatchesValidation />
            </>
          }
        </Col>
        <Col span={10}>
          <h3 className={"profile-run-title"}>Final Validation</h3>
          <Table className={"profile-run-table"} pagination={false} showHeader={false} size={"small"} style={{ width: data.length === 1 ? "150px" : undefined }} columns={columns} dataSource={data} />
          <PriceChangeSimulation
            parsedLogs={!!finalCheck ? finalCheck.info.parsedLogs : []}
          />
          <div className={"btn-group"}>
            <Button type={"default"} onClick={() => history.push(`/profiles/${profile.uuid}/lpTransaction`)}>Back</Button>
            <Button type={"default"} download={jsonName} href={jsonHref}>Download config</Button>
            <Button type={"default"} onClick={() => updateFinalCheck()}><SyncOutlined spin={loading} />Validate</Button>
            {
              !loading &&
              <div>
                {
                  !!lpTransaction
                    ? <Button type={"primary"} onClick={onShowResult} loading={running} disabled={running}>Result</Button>
                    : <>
                      <SetLaunchUtcTimeButton />
                      <div>
                        <Popconfirm
                          title={<>Are you sure you want to launch immediately?</>}
                          onConfirm={onRun}
                          okText="Yes"
                          cancelText="No"
                          disabled={loading || running || !finalCheck || !finalCheck.validationStatus || !allBatchesAreValidated}
                        >
                          <Button
                            type={"primary"}
                            loading={running}
                            disabled={loading || running || !finalCheck || !finalCheck.validationStatus || !allBatchesAreValidated}
                          >
                            Launch immediately
                          </Button>
                        </Popconfirm>
                      </div>
                      <SendLpTransactionPubliclyButton
                        lpTransaction={!!profile.lpTransaction ? profile.lpTransaction.hexTransaction : null}
                        update={updateTransactions}
                      />
                    </>
                }
              </div>
            }
          </div>
        </Col>
        <Col span={7}>
          {
            profile.calculation.exchangeId === 'uniswapV2openTrading'
              ? <ProfileOpenTradingValidation
                title={"OpenTrading Tx"}
                validation={!!finalCheck ? finalCheck.validationProcess.lpValidation : undefined}
              />
              : <ProfileLpValidation
                title={"LP transaction"}
                validation={!!finalCheck ? finalCheck.validationProcess.lpValidation : undefined}
              />
          }
        </Col>
      </Row>
      <RunResultModal
        profile={profile}
        visible={showResult}
        onCancel={() => setShowResult(false)}
        transactions={transactions}
        update={updateTransactions}
        networks={networks}
      />
      <HashesModal
        lpTransaction={!!profile.lpTransaction ? profile.lpTransaction.hexTransaction : null}
        executeTransactions={executeTransactions}
        visible={showHashes}
        onCancel={() => setShowHashes(false)}
      />
    </div>
  );
});

export default ProfileRun;